import {DROPDOWN_TYPE, TEXT_TYPE, PROJECT_ID, UNFORMATTED_FIELDS, MHE_COLUMNS, FIXED_TABLE_COLUMNS, SITE_COLUMN_NAME, PROJECTS_TABLE_DEFAULT_FILTER, CHGHISTORY_DATE_COLUMN_NAME, CAPACITY_YEAR_COLUMN_NAME, HOMEPAGE_TABLE_COLUMNS, FILTER_PRIORITY_COLUMNS} from "../../constants";
import uniq from "lodash/uniq";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import toString from "lodash/toString";
import XLSX from "xlsx";

/*
@input: activeSitesData[]
@output: flattenedActiveSites[]
*/
export function processActiveSitesData(activeSitesData): Array<object> {
  // flatten activeSitesData
  let flattenedActiveSites: Array<object> = [];
  activeSitesData.forEach((activeSiteData) => {
    /*
    flattenedActiveSiteData = {
      "Site": {
        value: BFI5,
        type: text,
        isDeletable: false,
      }
    }
    */
    let flattenedActiveSiteData = {};
    Object.keys(activeSiteData)
        .filter(key => key != PROJECT_ID)
        .forEach((key) => {
      let groupedActiveSiteData = activeSiteData[key];
      groupedActiveSiteData.forEach((siteData) => {
        let { fieldName, value, type, isDeletable, options } = siteData;
        flattenedActiveSiteData[fieldName] = {
          value,
          type,
          isDeletable,
          options
        };
      });
    });
    flattenedActiveSiteData[PROJECT_ID] = activeSiteData[PROJECT_ID]
    flattenedActiveSites.push(flattenedActiveSiteData);
  });

  return flattenedActiveSites;
}

export function getSideNavValues(activeSitesData){
  let columnValues = {}
  Object.keys(activeSitesData[0]).forEach(key=>{
    if(key=== PROJECT_ID) return;
      columnValues[key]=[];
      activeSitesData[0][key].forEach(field => {
        columnValues[key].push(field.fieldName);
      });
      
  })
  return columnValues;
}

export function isMultiSelectDropdown(fieldType) {
  return fieldType === TEXT_TYPE || fieldType === DROPDOWN_TYPE;
}

export function getDropdownOptions(fieldName, projectList) {
  const fieldType = projectList[0][fieldName].type;
  if (fieldType === DROPDOWN_TYPE) {
    return projectList[0][fieldName].options;
  } else {  // TEXT_TYPE need to scan the whole list
    let fieldValueOptions = projectList.map((project) => project[fieldName].value);
    return uniq(fieldValueOptions);
  }
}

export function getInitialUserFilters(projectList) {
  if (projectList.length) {
    let userFilters = {};
    Object.keys(projectList[0]).forEach((fieldName) => {
      userFilters[fieldName] = isMultiSelectDropdown(projectList[0][fieldName].type) ? [] : "";
    });
    return userFilters;
  } else {
    return {};
  }
}

export function isNumeric(value) {
  return !isNaN(parseFloat(value.replaceAll(",", ""))) && isFinite(value.replaceAll(",", ""));
}

export function formatProjectFields(value, fieldName) {
  if (value ===undefined || UNFORMATTED_FIELDS.includes(fieldName) || !isNumeric(value)) {
    return value;
  } else {
    return Math.round(Number(value.replaceAll(",", ""))).toLocaleString();
  }
}

export function getCalculatedValue(data, fieldName) {
  if(data[fieldName] === undefined){
    return "";
  }
  let value = data[fieldName].value;
  if (fieldName === 'MHE' && MHE_COLUMNS.every(column=> Object.keys(data).includes(column))) {
    value = "";
    MHE_COLUMNS.forEach(column => {
      let tempValue = data[column] ? data[column].value : "0";
      if (/^\+?(0|[1-9]\d*)$/.test(tempValue) && !tempValue.includes("0")) {
        value += `(${tempValue}) ${column} + `
      }
    });
    value = value.slice(0, -3);
  }
  return value;
}


/**
 * @summary generate a excel file with the data present in the table
 * * @input: array of filtered data from table component
 */
export function generateHomeExcelData(input) {
  let data: any[] = [];
  input
    .map(site => {
      let siteData = {};
      Object.keys(site).forEach(key => {
        if(HOMEPAGE_TABLE_COLUMNS.includes(key))
          siteData[key] = formatProjectFields(site[key].value, key)
      })
      data.push(siteData);
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, `POR-Project-Data-${Date.now()}`);
  XLSX.writeFile(workbook, `PORProjectData${Date.now()}.xlsx`);
  }

/*
* Util to export ChangeHistoryTable view to excel
* @input: array of projectDetails
* @input: object of columnFilters
* @input: object of userFilterGlobal
*/
export function generateChangeHistoryExcelData(changeHistoryData, columnFilters, userFilterGlobal) {
  let changeHistoryListCopy = new Array();
  changeHistoryData.forEach(function (changeHistory) {
    let valid = true;
    Object.keys(columnFilters).forEach(field => {
      if (!valid) return;
      if (typeof (columnFilters[field]) === typeof ([])) {
        if(field == CHGHISTORY_DATE_COLUMN_NAME){
          if (columnFilters[field].length !== 0 && !columnFilters[field].map(String).includes(changeHistory[field].split(" ")[0])) {
            valid = false;
          }
        }
        else if(columnFilters[field].length !== 0 && !columnFilters[field].map(String).includes(String(changeHistory[field]))) {
          valid = false;
        }
      }
      if (typeof (columnFilters[field]) === typeof ("")) {
        if (columnFilters[field] && !String(columnFilters[field]).includes(String(changeHistory[field]))) {
          valid = false;
        }
      }
    });
    valid ? changeHistory.changes.forEach(change => {
      let chDownloadLine = {
        ...changeHistory,
        newValue: change.newValue,
        previousValue: change.previousValue,
        category: change.category,
        fieldName: change.fieldName,
        approvalLink: String(changeHistory.approvalLinks)
      }
      let {projectId, changes, approvalLinks, ...line} = chDownloadLine;
      changeHistoryListCopy.push(line);
    }) : null;
  });
  
  changeHistoryListCopy = changeHistoryListCopy.filter(changeHistory => {
    if (!userFilterGlobal) return true;
    let changeHistoryValues = Object.values(changeHistory);
    let filteredChangeHistoryValues = changeHistoryValues.filter((changeHistory: any) =>{
      return changeHistory !== null && changeHistory.toString().toLowerCase().includes(userFilterGlobal.toLowerCase())
    });
    return filteredChangeHistoryValues.length > 0;
  })
    
  const worksheet = XLSX.utils.json_to_sheet(changeHistoryListCopy);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, `POR-Change-History-Data`);
  XLSX.writeFile(workbook, `PORChangeHistorytData${Date.now()}.xlsx`);
}


/*
* Util to export ProjectsTable view to excel
* @input: array of projectDetails
* @input: object of columnFilters
* @input: object of userFilterGlobal
*/
export function generateProjectsExcelData(projectList, columnFilters, searchProjectsFilter, selectedColumns) {
  const totalFilteredProjects = projectList
    .filter(userFilterGlobalMethod(searchProjectsFilter))
    .filter(userInputFilterMethod(columnFilters));
  const downloadingData = totalFilteredProjects.map((projectData) => {
    let siteData = {};
    selectedColumns.forEach((columnName) => siteData[columnName] = getCalculatedValue(projectData, columnName));
    return siteData;
  });
  const worksheet = XLSX.utils.json_to_sheet(downloadingData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, `POR-Project-Data-${Date.now()}`);
  XLSX.writeFile(workbook, `PORProjectData${Date.now()}.xlsx`);
}

export function getDefaultSortedColumnHeaders(projectData) {
  const restColumnHeaders = Object.keys(projectData).filter(
    (fieldName) =>
      !FIXED_TABLE_COLUMNS.includes(fieldName) &&
      fieldName !== PROJECT_ID
  );
  const dynamicPriorityColumns = FIXED_TABLE_COLUMNS.filter((priorityColumn) => Object.keys(projectData).includes(priorityColumn));  // filter out the fields that's not returned from projectData
  return [...dynamicPriorityColumns, ...restColumnHeaders];
}

/**
 * @summary flatten the columnFilters object to build filter tag text for all filters
 * @param {object<string, array>} [columnFilters] columnFilters object<string, array>
 * @return {array<string>} array of filter tag text [filterKey: filterValue]
 * **/
export function getAllFilterTagsText(columnFilters) {
  if (isEmpty(columnFilters)) {
    return [];
  }
  const totalFilterGroup = Object.keys(columnFilters).map((eachColumnFilter) => {
    const columnFilterValueList = columnFilters[eachColumnFilter];
    return columnFilterValueList.map((eachColumnFilterValue) => (`${eachColumnFilter}: ${eachColumnFilterValue}`));
  });
  return flatten(totalFilterGroup);
}

/**
 * @summary the compare function to sort projects according each sortColumn
 * **/
export function compareProjectsFn (event) {
  return (a, b) => {
    const aValue = a[event.sortColumn].value;
    const bValue = b[event.sortColumn].value;
    return event.sortDirection === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  }
}

/**
 * @summary process a single PaginatedSiteData response, and create a map for fieldNames to groupNames
 * **/
export function getProjectFieldsReverseMap(activeSiteData): object {
  let projectFieldsReverseMap: object = {};

  Object.keys(activeSiteData).filter(groupFieldName => groupFieldName != PROJECT_ID)
    .forEach((groupFieldName) => {
      activeSiteData[groupFieldName].forEach((groupedSiteData) => {
        const { fieldName } = groupedSiteData;
        projectFieldsReverseMap[fieldName] = groupFieldName;
      });
    });

  return projectFieldsReverseMap;
}

/**
 * @summary helper function to convert totalFilteredProjects to object<string, array> to compute compact view
 * @param {Array} [totalFilteredProjects] local variable of totalFilteredProjects
 * @return {object} {siteCode: projectData}
 * **/
export const getCompactViewProjectMapBySite = (totalFilteredProjects) => {
  const compactViewProjectMapBySite: object = totalFilteredProjects.reduce(
    (prevProjectMapBySite, currentProjectData) => {
      const siteCode = currentProjectData[SITE_COLUMN_NAME].value;
      if (prevProjectMapBySite.hasOwnProperty(siteCode)) {
        prevProjectMapBySite[siteCode].push(currentProjectData);
      } else {
        prevProjectMapBySite[siteCode] = [currentProjectData];
      }
      return prevProjectMapBySite;
    },
    {}
  );
  return compactViewProjectMapBySite;
};

/**
 * @summary helper function to filter projects by default
 * @param {Array} [projectListPerSite] array of ProjectData of one site
 * @return {Array} filtered version of array of ProjectData
 * **/
export const filterProjectsByDefault = (projectListPerSite) => {
  return projectListPerSite.filter((projectDataPerSite) => {
    for (let defaultFilterColumn of Object.keys(PROJECTS_TABLE_DEFAULT_FILTER)) {
      if (toString(PROJECTS_TABLE_DEFAULT_FILTER[defaultFilterColumn][0]) !== toString(projectDataPerSite[defaultFilterColumn].value)) {
        return false;
      }
    }
    return true;
  });
}

/**
 * @summary helper function to quickly sort projects data using capacity year column DESC, compact view only
 * @param {object} [projectDataPerSiteA] projectData
 * @param {object} [projectDataPerSiteB] projectData
 * @return {number} compare result
 * **/
export const compareByCapacityYear = (projectDataPerSiteA, projectDataPerSiteB) =>
projectDataPerSiteB[CAPACITY_YEAR_COLUMN_NAME].value - projectDataPerSiteA[CAPACITY_YEAR_COLUMN_NAME].value;

/**
   * @summary filter method for Search Project input - Higher order to pass searchProjectsFilter from project context
   * @param {string} [searchProjectsFilter] single string to global search
   * @param {object} [projectData] single project data
   * **/
export const userFilterGlobalMethod = (searchProjectsFilter) => (projectData) => {
  if (isEmpty(searchProjectsFilter)) {
    return true;
  }
  let projectDataValues = Object.values(projectData);
  let filteredActiveSiteValues = projectDataValues.filter(
    (activeSiteValue: any) =>
      activeSiteValue.value &&
      activeSiteValue.value
        .toLowerCase()
        .includes(searchProjectsFilter.toLowerCase())
  );
  return filteredActiveSiteValues.length > 0;
};

/**
 * @summary filter method for Filter by Column select dropdown - Higher order to pass columnFilters from project context
 * @param {object} [projectData] single project data
 * **/
export const userInputFilterMethod = (columnFilters) => (projectData) => {
  if (
    !Object.keys(columnFilters) ||
    Object.keys(columnFilters).length === 0
  ) {
    return true;
  }
  let filterResult = true;

  Object.keys(columnFilters) // only compare the ones are defined
    .filter(
      (filterKey) =>
        Array.isArray(columnFilters[filterKey]) &&
        columnFilters[filterKey].length > 0
    )
    .forEach((filterKey) => {
      filterResult =
        filterResult &&
        filterByMultiSelectTags(filterKey, columnFilters, projectData);
    });
  return filterResult;
};

/**
 * @summary filter method to check with single filtering constrain
 * @param {string} [filterKey] filter by field name
 * @param {object} [columnFilters] columnFilter state
 * @param {object} [activeSiteData] single project data
 * **/
export const filterByMultiSelectTags = (
  filterKey,
  columnFilters,
  activeSiteData
) => {
  if (columnFilters[filterKey].length === 0) {
    return true;
  }
  return columnFilters[filterKey].some(
    (filterValue) => filterValue === activeSiteData[filterKey].value
  );
};

/**
 * @summary get project filter ty column dropdown selections with sorted columns
 * @param {array} [filterByColumns] project columns to sort
 * @return {array} displayable filter by column options
 * **/
export const getProjectsFilterByColumns = (filterByColumns) => {
  const restOfFilterByColumns = filterByColumns.filter(
    (c) => !FILTER_PRIORITY_COLUMNS.includes(c)
  );
  return [...FILTER_PRIORITY_COLUMNS, ...restOfFilterByColumns];
};