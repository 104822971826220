import React, { useEffect, useState } from "react";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";
import { MAX_NUMBER_OF_FILTERS, NUMBER_OF_FILTER_TAG_ROWS } from "../../../constants";
import { getAllFilterTagsText } from "../ActiveProjectsUtils";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

export default function ProjectFilterTagsGroup({
  sourceDataFilters,  // object<string, array> - the sourceDataFilters used by parent component to control filtering logic
  setSourceDataFilters,  // function - setter for sourceDataFilters
  showTooManyFiltersWarning,  // boolean - toggle show/hide warning for max filters
  setShowTooManyFiltersWarning,  // function - setter for showTooManyFiltersWarning
}) {
  const [filterTagsSet, setFilterTagsSet] = useState(new Set<string>());
  const firstRowSize = MAX_NUMBER_OF_FILTERS / NUMBER_OF_FILTER_TAG_ROWS - 1;
  const shouldAddAnotherRow = filterTagsSet.size > firstRowSize;

  useEffect(() => {
    const totalFilterGroup = getAllFilterTagsText(sourceDataFilters);
    setFilterTagsSet(new Set(totalFilterGroup));
  }, [sourceDataFilters]);

  const closeFilterHandler = (eachColumnFilter, columnFilterValue) => () => {
    const nextColumnFilters = cloneDeep(sourceDataFilters);
    nextColumnFilters[eachColumnFilter] = nextColumnFilters[eachColumnFilter].filter(e => e !== (columnFilterValue).trim());
    setSourceDataFilters(nextColumnFilters);
    setShowTooManyFiltersWarning(false);
  }

  return (
    <React.Fragment>
      {showTooManyFiltersWarning && <Text color="error">{`Not able to create more than ${MAX_NUMBER_OF_FILTERS} filters.`}</Text> }
      <Row wrap="down">
        {
          [...filterTagsSet]
          .slice(0, shouldAddAnotherRow ? firstRowSize : filterTagsSet.size)
          .map((eachFilterTag) => (
            <Tag
              key={`${eachFilterTag}-tag`}
              onClose={closeFilterHandler(eachFilterTag.split(":", 2)[0], eachFilterTag.split(":", 2)[1])}
            >
              {`${eachFilterTag}`}
            </Tag>
          ))
        }
      </Row>
      {shouldAddAnotherRow &&
        <Row wrap="down">
          {
            [...filterTagsSet]
            .slice(firstRowSize, filterTagsSet.size)
            .map((eachFilterTag) => (
              <Tag
                key={`${eachFilterTag}-tag`}
                onClose={closeFilterHandler(eachFilterTag.split(":", 2)[0], eachFilterTag.split(":", 2)[1])}
              >
                {`${eachFilterTag}`}
              </Tag>
            ))
          }
        </Row>
      }
    </React.Fragment>
  )
}