import React from "react";
import Tooltip from "@amzn/meridian/tooltip";
import "../ActiveProjects.scss";

export default function TableCellWithLongValue({ fullContent }) {
  return <Tooltip position="end" title={fullContent}>
    <div className="wrapContent">
      {fullContent}
    </div>
  </Tooltip>
}