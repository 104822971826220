import React, { useCallback, useEffect, useState } from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Select, { SelectOption } from "@amzn/meridian/select";
import ProjectFilterTagsGroup from "./ProjectFilterTagsGroup";
import { MAX_NUMBER_OF_FILTERS } from "../../../constants";
import { getAllFilterTagsText } from "../ActiveProjectsUtils";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";

export default function ProjectFilterByColumnGroup({
  filteringSourceData, // array<object> - the source data this filter group ties to
  filterByColumns, // string - the filter by dropdown
  sourceDataFilters, // object<string, array> - the filter object that controls filtering source data
  setSourceDataFilters, // function - the setter of sourceDataFilters
  backgroundColor,
  hasBorder,
}) {
  // current local column filter name and values for user to select
  const [filterFieldName, setFilterFieldName] = useState("");
  const [filterFieldValue, setFilterFieldValue] = useState("");
  const [showTooManyFiltersWarning, setShowTooManyFiltersWarning] =
    useState(false);

  useEffect(() => {
    if (isEmpty(filterFieldName) || isEmpty(filterFieldValue)) {
      return;
    }
    if (
      getAllFilterTagsText(sourceDataFilters).length === MAX_NUMBER_OF_FILTERS
    ) {
      setShowTooManyFiltersWarning(true);
      return; // do not setSourceDataFilters state since already reach maximum
    } else {
      setShowTooManyFiltersWarning(false);
    }
    const nextColumnFilters = cloneDeep(sourceDataFilters);
    if (nextColumnFilters.hasOwnProperty(filterFieldName!)) {
      nextColumnFilters[filterFieldName] = uniq([
        ...nextColumnFilters[filterFieldName],
        filterFieldValue,
      ]);
    } else {
      nextColumnFilters[filterFieldName] = [filterFieldValue];
    }
    setSourceDataFilters(nextColumnFilters);
  }, [filterFieldValue]);

  useEffect(()=>{
    // clean up when component unmount
    return () => {
      clearFilterHandler();
    };
  }, [])

  const clearFilterHandler = () => {
    const emptyColumnFilter = {};
    setSourceDataFilters(emptyColumnFilter);
    setFilterFieldName("");
    setFilterFieldValue("");
  };

  const getFilterByColumnOptions = () => {
    return filterByColumns.map((eachColumn) => (
      <SelectOption
        key={`${eachColumn}-select-column-dropdown`}
        value={eachColumn}
        label={eachColumn}
      />
    ));
  };

  const getFilterValueAfterColumnIsSelected = useCallback(() => {
    if (isEmpty(filterFieldName)) {
      return <React.Fragment />;
    }
    const selectedFieldValues = filteringSourceData.map(
      (projectData) => filterFieldName && projectData[filterFieldName].value
    );
    return uniq(selectedFieldValues)
      .sort()
      .map((eachColumnValue) => (
        <SelectOption
          key={`${filterFieldName}-${eachColumnValue}-select-value-dropdown`}
          value={eachColumnValue}
          label={eachColumnValue}
        />
      ));
  }, [filterFieldName, filteringSourceData]);

  return (
    <Row
      backgroundColor={backgroundColor}
      type="outline"
      widths={["50%", "50%"]}
      spacingInset="200"
      className={!hasBorder ? "homeStyling" : ""}
    >
      <Column spacingInset="200" alignmentVertical="top">
        <Row
          alignmentVertical="bottom"
          width="100%"
          widths={["45%", "45%", "fill"]}
          spacing="300"
        >
          <Select
            label="Filter By Column"
            value={filterFieldName}
            onChange={setFilterFieldName}
            placeholder="Filter by"
          >
            {getFilterByColumnOptions()}
          </Select>
          <Select
            label=" "
            value={filterFieldValue}
            onChange={setFilterFieldValue}
            placeholder="Type to Search"
          >
            {getFilterValueAfterColumnIsSelected()}
          </Select>
          <Button type="secondary" onClick={clearFilterHandler}>
            Clear
          </Button>
        </Row>
      </Column>
      <Column alignmentVertical="top" spacingInset="200">
        <ProjectFilterTagsGroup
          sourceDataFilters={sourceDataFilters}
          setSourceDataFilters={setSourceDataFilters}
          showTooManyFiltersWarning={showTooManyFiltersWarning}
          setShowTooManyFiltersWarning={setShowTooManyFiltersWarning}
        />
      </Column>
    </Row>
  );
}
