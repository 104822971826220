import { PaginatedActiveSitesData } from '../por-api/generated-src';
import PorApiFactory from '../por-api/PlanOfRecordApiFactory';
import { Logger } from '@amzn/kepler-katal-logger';
import { DEFAULT_API_CALL_LIMIT, DEFAULT_ITEMS_PER_PAGE } from "../constants";
import { processActiveSitesData } from "../components/activeProjects/ActiveProjectsUtils";

/**
 * @Todo: Deprecate!
 * @summary makes getActiveSites call to fetch all projects data
 * @return processed activeSiteData array with flat projectData object
 * **/
export default async function GetProjectsApi() {
  const PorApi = PorApiFactory();

  try {
    let activeProjectsResult: Array<object> = [];
    const firstTimeActiveSiteResponse = (await PorApi.getActiveSites(DEFAULT_API_CALL_LIMIT)).data as PaginatedActiveSitesData;
    const { nextToken, data } = firstTimeActiveSiteResponse;
    let userNextToken = nextToken;
    if (data && data.length) {
      const firstTimeProcessedActiveSites = processActiveSitesData(data);
      activeProjectsResult = activeProjectsResult.concat(firstTimeProcessedActiveSites);
      while (userNextToken) {
        let activeProjectsResponse = (await PorApi.getActiveSites(DEFAULT_API_CALL_LIMIT, userNextToken)).data as PaginatedActiveSitesData;
        const { nextToken, data } = activeProjectsResponse;
        userNextToken = nextToken;
        if (data && data.length) {
          let processedActiveSiteList = processActiveSitesData(data);
          activeProjectsResult = activeProjectsResult.concat(processedActiveSiteList);
        }
      }
    }
    return activeProjectsResult;
  } catch (ex: any) {
    console.error(`Error on fetching active sites data`, ex.message);
    Logger.getInstance().logError(ex);
  }
}

/**
 * @summary makes getActiveSites call to fetch only one page of projects
 * @return NON-processed activeSiteData array with original projectData object
 * **/
export async function GetInitialProjectsApi() {
  const PorApi = PorApiFactory();

  try {
    const firstTimeActiveSiteResponse = (await PorApi.getActiveSites(DEFAULT_ITEMS_PER_PAGE)).data as PaginatedActiveSitesData;
    const { data } = firstTimeActiveSiteResponse;
    if (data && data.length) {
      return data;
    }
    return [];
  } catch (ex: any) {
    console.error(`Error on fetching active sites data`, ex.message);
    Logger.getInstance().logError(ex);
  }
}