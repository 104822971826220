import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import ProjectViewControlGroup from "./common/ProjectViewControlGroup";
import ProjectFilterByColumnGroup from "./common/ProjectFilterByColumnGroup";
import CustomProjectColumnsSheet from "./common/CustomProjectColumnsSheet";
import ProjectsTable from "./ProjectsTable";
import { convertCopyProjectsFieldsToColumnFilters } from "../copyProjects/CopyProjectsUtils";
import { getProjectsFilterByColumns } from "../activeProjects/ActiveProjectsUtils";
import {
  NEW_SITE_ID,
  PROJECTPAGE_TABLE_TYPE,
  DETAILED_VIEW,
} from "../../constants";
import { usePORContext } from "../../store/por-context";
import { useProjectsContext } from "../../store/project-store";
import { colorBlue50 } from "@amzn/meridian-tokens/base/color";

export default function ProjectsPage() {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { canEdit, projectTableData, defaultColumns } = usePORContext();
  const { columnFilters, setColumnFilters, setProjectsViewType } =
    useProjectsContext();
  const [showProjectsFilter, setShowProjectsFilter] = useState(true);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  useEffect(() => {
    if (locationState && locationState.copyProjectsFieldsFromRouting) {
      setColumnFilters(
        convertCopyProjectsFieldsToColumnFilters(
          locationState.copyProjectsFieldsFromRouting
        )
      );
    }

    return () => {
      setColumnFilters({});
      setProjectsViewType(DETAILED_VIEW);
    };
  }, [locationState]);

  const addProjectHandler = () => {
    navigate(`/active-projects/project-details/${NEW_SITE_ID}`);
  };

  const copyProjectsHandler = () => {
    navigate(`/active-projects/copy-projects/`);
  };

  return (
    <Column width="100%" spacingInset="400">
      <Row alignmentHorizontal="end">
        {canEdit && (
          <Button type="secondary" onClick={copyProjectsHandler}>
            Copy Projects
          </Button>
        )}
        {canEdit && (
          <Button type="primary" onClick={addProjectHandler}>
            Add Project
          </Button>
        )}
      </Row>
      <ProjectViewControlGroup
        showProjectsFilter={showProjectsFilter}
        setShowProjectsFilter={setShowProjectsFilter}
        setIsSideMenuOpen={setIsSideMenuOpen}
      />
      {showProjectsFilter && (
        <ProjectFilterByColumnGroup
          filteringSourceData={projectTableData}
          filterByColumns={getProjectsFilterByColumns(defaultColumns)}
          sourceDataFilters={columnFilters}
          setSourceDataFilters={setColumnFilters}
          backgroundColor={colorBlue50}
          hasBorder={true}
        />
      )}
      <ProjectsTable
        isProjectSelectable={canEdit}
        tableType={PROJECTPAGE_TABLE_TYPE}
      />
      <CustomProjectColumnsSheet
        isOpen={isSideMenuOpen}
        setIsOpen={setIsSideMenuOpen}
      />
    </Column>
  );
}
