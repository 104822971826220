import React, { createContext, useState } from "react";
import { BRSData } from "../por-api/generated-src";

interface BRSDetailsStateProps {
  BRSDetails: { [key: string]: BRSData; };
  highlightFields: Set<string>;
  setBRSDetails(BRSDetailData): any;
  addHighlightFields(fieldName): any;
  clearHighlightFields(): any;
}

const initialState: BRSDetailsStateProps = {
  BRSDetails: {},
  highlightFields: new Set(),
  setBRSDetails: () => { },
  addHighlightFields: () => { },
  clearHighlightFields: () => { }
};

const BRSDetailsContext = createContext(initialState);

export function BRSDetailsContextProvider({ children }) {
  const [userBRSDetails, setUserBRSDetails] = useState<{ [key: string]: BRSData; }>({});
  const [userHighlightFields, setUserHighlightFields] = useState<Set<string>>(initialState.highlightFields);

  function setBRSDetails(BRSDetailData) {
    setUserBRSDetails(BRSDetailData);
  }

  function addHighlightFields(fieldName) {
    setUserHighlightFields((prevUserHighlightFields) => prevUserHighlightFields.add(fieldName));
  }

  function clearHighlightFields() {
    setUserHighlightFields(new Set());
  }

  const context: BRSDetailsStateProps = {
    BRSDetails: userBRSDetails,
    highlightFields: userHighlightFields,
    setBRSDetails,
    addHighlightFields,
    clearHighlightFields
  };

  return (
    <BRSDetailsContext.Provider value={context}>{children}</BRSDetailsContext.Provider>
  );
}

export default BRSDetailsContext;