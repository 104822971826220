import React, { useState, useLayoutEffect } from "react";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import Loader from "@amzn/meridian/loader";
import {
  DOWNLOAD_PROJECT_FEATURE_NAME,
  DOWNLOAD_BRS_FEATURE_NAME,
  DOWNLOAD_MHE_TABLE_FEATURE_NAME,
  DOWNLOAD_CAPACITY_YEAR_FEATURE_NAME,
  DOWNLOAD_CHANGE_HISTORY_FEATURE_NAME
} from "../../constants";
import PorApiFactory from '../../por-api/PlanOfRecordApiFactory';
import { DownloadResponse } from "../../por-api/generated-src";

export default function DownloadIcon({ featureName }) {
  const [downloadLink, setDownloadLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const PorApi = PorApiFactory();

  useLayoutEffect(() => {
    let isMounted = true;               // note mutable flag
    (async () => {
      setIsLoading(true);
      try {
        let downloadLinkResponse: DownloadResponse = { downloadLink: "" };
        switch (featureName) {
          case DOWNLOAD_PROJECT_FEATURE_NAME:
            downloadLinkResponse = (await PorApi.downloadProjects()).data as DownloadResponse;
            break;
          case DOWNLOAD_BRS_FEATURE_NAME:
            downloadLinkResponse = (await PorApi.downloadBRS()).data as DownloadResponse;
            break;
          case DOWNLOAD_MHE_TABLE_FEATURE_NAME:
            downloadLinkResponse = (await PorApi.downloadMHEDashboard()).data as DownloadResponse;
            break;
          case DOWNLOAD_CAPACITY_YEAR_FEATURE_NAME:
            downloadLinkResponse = (await PorApi.downloadCapacityYearDashboard()).data as DownloadResponse;
            break;
          case DOWNLOAD_CHANGE_HISTORY_FEATURE_NAME:
            downloadLinkResponse = (await PorApi.downloadChangeHistory()).data as DownloadResponse;
        }
        if (isMounted) {
          setDownloadLink(downloadLinkResponse.downloadLink || "");
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
      setDownloadLink("");
      setIsLoading(false);
    }
  }, [featureName]);

  const clickDownloadHandler = () => {
      window.open(downloadLink, "_blank", "noopener,noreferrer");
  }

  return isLoading ? <Loader size="small" /> : <Button type="icon" onClick={clickDownloadHandler}>
    <Icon tokens={downloadLargeTokens}>Download</Icon>
  </Button>
}