import React, { useContext, useState } from "react";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import BRSFormulaIndicator from "./BRSFormulaIndicator";
import { DEFAULT_BRS_INPUT_WIDTH } from "../../constants";
import PORContext from "../../store/por-context";
import BRSDetailsContext from "../../store/brs-store";
import { formatTemplate } from "./BRSUtils";
import cloneDeep from "lodash/cloneDeep";
import { Parser } from "hot-formula-parser";
import { BRSData } from "../../por-api/generated-src";

export default function BRSInputWithFormula({ brsFieldName }) {
  const brsContext = useContext(BRSDetailsContext);
  const porContext = useContext(PORContext);
  const { BRSDetails, setBRSDetails, addHighlightFields, clearHighlightFields } = brsContext;
  const { canEdit } = porContext;
  const brsDataField: BRSData = BRSDetails[brsFieldName];
  const willAffectOtherFields = brsDataField &&
    brsDataField.formula &&
    brsDataField.formula.resultFields &&
    brsDataField.formula.resultFields?.length > 0;
  const [hasChanged, setHasChanged] = useState(false);
  let parser = new Parser();

  const changeBRSValueHandler = (brsFieldName) => (inputValue) => {
    let copyOfBRSDetails = cloneDeep(BRSDetails);
    copyOfBRSDetails[brsFieldName].value = inputValue;
    setBRSDetails(copyOfBRSDetails);
    setHasChanged(true);
  }

  /* return: {error: null | string, result: string} */
  const calculateFormula = (copyOfBRSDetails, template, params, useParser) => {
    let loadParamValues = params.map((param) => Number(copyOfBRSDetails[param].value));

    let formattedTemplate = formatTemplate(template, ...loadParamValues);
    if (!useParser) {
      return { error: null, result: formattedTemplate };
    }
    let formulaResult = parser.parse(formattedTemplate);
    return formulaResult;
  }

  const updateFormula = (brsFieldName) => () => {
    if (hasChanged) {
      let copyOfBRSDetails = cloneDeep(BRSDetails);
      const { resultFields } = copyOfBRSDetails[brsFieldName].formula;
      if (resultFields && resultFields.length) {
        clearHighlightFields();  // clean up previous highlighted fields
        resultFields.forEach((resultField) => {
          let { template, params, useParser } = copyOfBRSDetails[resultField].formula;
          let { error, result } = calculateFormula(copyOfBRSDetails, template, params, useParser);
          if (error) {
            console.log(`Error calculating ${resultField} with ${template}.`);
          } else {
            copyOfBRSDetails[resultField].value = result;
            addHighlightFields(resultField);
          }
        });
      }

      // after formula calculated
      setBRSDetails(copyOfBRSDetails);
      setHasChanged(false);
    }
  }

  const handleKeydown = (event) => {
    if (event.key === "Enter") {
      updateFormula(brsFieldName)();
    }
  }

  return <Row spacing="100">{willAffectOtherFields ?
    <BRSFormulaIndicator brsFieldName={brsFieldName} resultFields={BRSDetails[brsFieldName].formula?.resultFields} /> : null}
    <Input
      value={BRSDetails[brsFieldName].value}
      width={DEFAULT_BRS_INPUT_WIDTH}
      onChange={changeBRSValueHandler(brsFieldName)}
      onBlur={updateFormula(brsFieldName)}
      onKeyDown={handleKeydown}
      disabled={!canEdit}
    ></Input >
  </Row>
}