import { PermissionsResponse } from '../por-api/generated-src';
import PorApiFactory from '../por-api/PlanOfRecordApiFactory';
import { Logger } from '@amzn/kepler-katal-logger';

export default async function UserPermissionsApi() {
  const PorApi = PorApiFactory();

  try {
    // load permission api
    const userPermissionResponse = (await PorApi.getPermissions()).data as PermissionsResponse;
    const { userPermissionsLevel } = userPermissionResponse;
    return userPermissionsLevel;
  } catch (ex: any) {
    console.error(`Error on useUserPermissionsApiCall:`, ex.message);
    Logger.getInstance().logError(ex);
  }
  return "";
}