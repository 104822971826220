/* tslint:disable */
/* eslint-disable */
/**
 * Plan Of Record
 * Plan of Record server created by ATS Squad 
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: atssquad@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActiveSiteData
 */
export interface ActiveSiteData {
    /**
     * 
     * @type {string}
     * @memberof ActiveSiteData
     */
    'projectId'?: string;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof ActiveSiteData
     */
    'siteDetails'?: Array<FieldData>;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof ActiveSiteData
     */
    'equipmentDescription'?: Array<FieldData>;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof ActiveSiteData
     */
    'buildingAttributes'?: Array<FieldData>;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof ActiveSiteData
     */
    'productivity'?: Array<FieldData>;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof ActiveSiteData
     */
    'additionalDetails'?: Array<FieldData>;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof ActiveSiteData
     */
    'retrofitDetails'?: Array<FieldData>;
}
/**
 * 
 * @export
 * @interface ActiveSiteRequestSchema
 */
export interface ActiveSiteRequestSchema {
    /**
     * 
     * @type {ChangeRequestDetails}
     * @memberof ActiveSiteRequestSchema
     */
    'changeReason'?: ChangeRequestDetails;
    /**
     * 
     * @type {ActiveSiteValues}
     * @memberof ActiveSiteRequestSchema
     */
    'changeBody'?: ActiveSiteValues;
}
/**
 * 
 * @export
 * @interface ActiveSiteValues
 */
export interface ActiveSiteValues {
    /**
     * 
     * @type {string}
     * @memberof ActiveSiteValues
     */
    'projectId'?: string;
    /**
     * 
     * @type {object}
     * @memberof ActiveSiteValues
     */
    'siteDetails'?: object;
    /**
     * 
     * @type {object}
     * @memberof ActiveSiteValues
     */
    'equipmentDescription'?: object;
    /**
     * 
     * @type {object}
     * @memberof ActiveSiteValues
     */
    'buildingAttributes'?: object;
    /**
     * 
     * @type {object}
     * @memberof ActiveSiteValues
     */
    'productivity'?: object;
    /**
     * 
     * @type {object}
     * @memberof ActiveSiteValues
     */
    'additionalDetails'?: object;
    /**
     * 
     * @type {object}
     * @memberof ActiveSiteValues
     */
    'retrofitDetails'?: object;
}
/**
 * 
 * @export
 * @interface ActiveSitesBulkRequestSchema
 */
export interface ActiveSitesBulkRequestSchema {
    /**
     * 
     * @type {ChangeRequestDetails}
     * @memberof ActiveSitesBulkRequestSchema
     */
    'changeReason'?: ChangeRequestDetails;
    /**
     * 
     * @type {Array<ActiveSiteValues>}
     * @memberof ActiveSitesBulkRequestSchema
     */
    'changeBody'?: Array<ActiveSiteValues>;
}
/**
 * 
 * @export
 * @interface AddProjectsFieldRequestSchema
 */
export interface AddProjectsFieldRequestSchema {
    /**
     * 
     * @type {ChangeRequestDetails}
     * @memberof AddProjectsFieldRequestSchema
     */
    'changeReason'?: ChangeRequestDetails;
    /**
     * 
     * @type {NewFieldMetadata}
     * @memberof AddProjectsFieldRequestSchema
     */
    'changeBody'?: NewFieldMetadata;
}
/**
 * 
 * @export
 * @interface ApprovalDetails
 */
export interface ApprovalDetails {
    /**
     * 
     * @type {string}
     * @memberof ApprovalDetails
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApprovalDetails
     */
    'approved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApprovalDetails
     */
    'approvalReason'?: string;
}
/**
 * 
 * @export
 * @interface BRSData
 */
export interface BRSData {
    /**
     * 
     * @type {string}
     * @memberof BRSData
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof BRSData
     */
    'actualValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BRSData
     */
    'isCapacityASK'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BRSData
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {FormulaData}
     * @memberof BRSData
     */
    'formula'?: FormulaData;
}
/**
 * 
 * @export
 * @interface BRSSiteRequestBodySchema
 */
export interface BRSSiteRequestBodySchema {
    /**
     * 
     * @type {ChangeRequestDetails}
     * @memberof BRSSiteRequestBodySchema
     */
    'changeReason'?: ChangeRequestDetails;
    /**
     * 
     * @type {{ [key: string]: BRSData; }}
     * @memberof BRSSiteRequestBodySchema
     */
    'changeBody'?: { [key: string]: BRSData; };
}
/**
 * 
 * @export
 * @interface BRSTableEntry
 */
export interface BRSTableEntry {
    /**
     * 
     * @type {string}
     * @memberof BRSTableEntry
     */
    'site'?: string;
}
/**
 * 
 * @export
 * @interface ChangeHistoryCountResponse
 */
export interface ChangeHistoryCountResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryCountResponse
     */
    'changeHistoryCount'?: string;
}
/**
 * 
 * @export
 * @interface ChangeHistoryDetails
 */
export interface ChangeHistoryDetails {
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'decisionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'featureType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'affectSite'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'changeField'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'projectId'?: string;
    /**
     * 
     * @type {Array<ChangeHistoryFieldDiff>}
     * @memberof ChangeHistoryDetails
     */
    'changes'?: Array<ChangeHistoryFieldDiff>;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'reasonForChange'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'team'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'additionalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'reasonForDecision'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeHistoryDetails
     */
    'approvalLinks'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'approvedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryDetails
     */
    'quarter'?: string;
}
/**
 * 
 * @export
 * @interface ChangeHistoryFieldDiff
 */
export interface ChangeHistoryFieldDiff {
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryFieldDiff
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryFieldDiff
     */
    'previousValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryFieldDiff
     */
    'newValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeHistoryFieldDiff
     */
    'category'?: string;
}
/**
 * 
 * @export
 * @interface ChangeRequestDetails
 */
export interface ChangeRequestDetails {
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDetails
     */
    'reasonForChange'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDetails
     */
    'team'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestDetails
     */
    'approvalLinks'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestDetails
     */
    'isSuperAdmin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDetails
     */
    'additionalNotes'?: string;
}
/**
 * 
 * @export
 * @interface CountryCountData
 */
export interface CountryCountData {
    /**
     * 
     * @type {{ [key: string]: YearCount; }}
     * @memberof CountryCountData
     */
    'US'?: { [key: string]: YearCount; };
    /**
     * 
     * @type {{ [key: string]: YearCount; }}
     * @memberof CountryCountData
     */
    'Canada'?: { [key: string]: YearCount; };
}
/**
 * 
 * @export
 * @interface CurrentAndFutureSitesData
 */
export interface CurrentAndFutureSitesData {
    /**
     * 
     * @type {{ [key: string]: CountryCountData; }}
     * @memberof CurrentAndFutureSitesData
     */
    'Expansion'?: { [key: string]: CountryCountData; };
    /**
     * 
     * @type {{ [key: string]: CountryCountData; }}
     * @memberof CurrentAndFutureSitesData
     */
    'Retrofit'?: { [key: string]: CountryCountData; };
    /**
     * 
     * @type {{ [key: string]: CountryCountData; }}
     * @memberof CurrentAndFutureSitesData
     */
    'Launch'?: { [key: string]: CountryCountData; };
    /**
     * 
     * @type {{ [key: string]: CountryCountData; }}
     * @memberof CurrentAndFutureSitesData
     */
    'ExistingSite'?: { [key: string]: CountryCountData; };
}
/**
 * 
 * @export
 * @interface DashboardData
 */
export interface DashboardData {
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'year'?: number;
    /**
     * 
     * @type {Array<DashboardProjectDetails>}
     * @memberof DashboardData
     */
    'launch'?: Array<DashboardProjectDetails>;
    /**
     * 
     * @type {Array<DashboardProjectDetails>}
     * @memberof DashboardData
     */
    'expansion'?: Array<DashboardProjectDetails>;
    /**
     * 
     * @type {Array<DashboardProjectDetails>}
     * @memberof DashboardData
     */
    'retrofits'?: Array<DashboardProjectDetails>;
    /**
     * 
     * @type {Array<DashboardProjectDetails>}
     * @memberof DashboardData
     */
    'launchLTL'?: Array<DashboardProjectDetails>;
    /**
     * 
     * @type {Array<DashboardProjectDetails>}
     * @memberof DashboardData
     */
    'existingSite'?: Array<DashboardProjectDetails>;
    /**
     * 
     * @type {Array<DashboardProjectDetails>}
     * @memberof DashboardData
     */
    'buildingConstructionOnly'?: Array<DashboardProjectDetails>;
}
/**
 * 
 * @export
 * @interface DashboardProjectDetails
 */
export interface DashboardProjectDetails {
    /**
     * 
     * @type {string}
     * @memberof DashboardProjectDetails
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardProjectDetails
     */
    'mheType'?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardProjectDetails
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardProjectDetails
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface DownloadResponse
 */
export interface DownloadResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadResponse
     */
    'downloadLink'?: string;
}
/**
 * 
 * @export
 * @interface ExitCountCountryData
 */
export interface ExitCountCountryData {
    /**
     * 
     * @type {number}
     * @memberof ExitCountCountryData
     */
    'siteCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExitCountCountryData
     */
    'mheCapacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExitCountCountryData
     */
    'xdCapacity'?: number;
}
/**
 * 
 * @export
 * @interface ExitCountPartyData
 */
export interface ExitCountPartyData {
    /**
     * 
     * @type {ExitCountCountryData}
     * @memberof ExitCountPartyData
     */
    'US'?: ExitCountCountryData;
    /**
     * 
     * @type {ExitCountCountryData}
     * @memberof ExitCountPartyData
     */
    'Canada'?: ExitCountCountryData;
}
/**
 * 
 * @export
 * @interface ExitCountYearData
 */
export interface ExitCountYearData {
    /**
     * 
     * @type {ExitCountPartyData}
     * @memberof ExitCountYearData
     */
    '1P'?: ExitCountPartyData;
    /**
     * 
     * @type {ExitCountPartyData}
     * @memberof ExitCountYearData
     */
    '3P'?: ExitCountPartyData;
}
/**
 * 
 * @export
 * @interface FieldData
 */
export interface FieldData {
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FieldData
     */
    'required'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldData
     */
    'options'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FormulaData
 */
export interface FormulaData {
    /**
     * 
     * @type {string}
     * @memberof FormulaData
     */
    'template'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormulaData
     */
    'resultFields'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormulaData
     */
    'params'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FormulaData
     */
    'useParser'?: boolean;
}
/**
 * 
 * @export
 * @interface MapDetail
 */
export interface MapDetail {
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'site'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'scType'?: string;
    /**
     * 
     * @type {number}
     * @memberof MapDetail
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapDetail
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'mhe'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof MapDetail
     */
    'originalLaunchYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'siteCluster'?: string;
    /**
     * 
     * @type {number}
     * @memberof MapDetail
     */
    'dailyOpsRatedCapacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapDetail
     */
    'dailyXDOpsRatedCapacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'lmsc'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapDetail
     */
    'opsRegion'?: string;
}
/**
 * 
 * @export
 * @interface NewFieldMetadata
 */
export interface NewFieldMetadata {
    /**
     * 
     * @type {string}
     * @memberof NewFieldMetadata
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFieldMetadata
     */
    'defaultValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFieldMetadata
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFieldMetadata
     */
    'category'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewFieldMetadata
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewFieldMetadata
     */
    'approvalLinks'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PaginatedActiveSitesData
 */
export interface PaginatedActiveSitesData {
    /**
     * 
     * @type {string}
     * @memberof PaginatedActiveSitesData
     */
    'nextToken'?: string;
    /**
     * 
     * @type {Array<ActiveSiteData>}
     * @memberof PaginatedActiveSitesData
     */
    'data'?: Array<ActiveSiteData>;
}
/**
 * 
 * @export
 * @interface PermissionsResponse
 */
export interface PermissionsResponse {
    /**
     * 
     * @type {string}
     * @memberof PermissionsResponse
     */
    'userPermissionsLevel'?: string;
}
/**
 * 
 * @export
 * @interface UpdateActiveSitesRequest
 */
export interface UpdateActiveSitesRequest {
    /**
     * 
     * @type {ChangeRequestDetails}
     * @memberof UpdateActiveSitesRequest
     */
    'changeReason'?: ChangeRequestDetails;
    /**
     * 
     * @type {Array<ActiveSiteValues>}
     * @memberof UpdateActiveSitesRequest
     */
    'changeBody'?: Array<ActiveSiteValues>;
}
/**
 * 
 * @export
 * @interface UserMetaDataResponse
 */
export interface UserMetaDataResponse {
    /**
     * 
     * @type {string}
     * @memberof UserMetaDataResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserMetaDataResponse
     */
    'columnNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface YearCount
 */
export interface YearCount {
    /**
     * 
     * @type {string}
     * @memberof YearCount
     */
    'count'?: string;
}

/**
 * PorApi - axios parameter creator
 * @export
 */
export const PorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new field for Projects page
         * @param {AddProjectsFieldRequestSchema} addProjectsFieldRequestSchema Metadata for new field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectsField: async (addProjectsFieldRequestSchema: AddProjectsFieldRequestSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addProjectsFieldRequestSchema' is not null or undefined
            assertParamExists('addProjectsField', 'addProjectsFieldRequestSchema', addProjectsFieldRequestSchema)
            const localVarPath = `/activeSites/field`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProjectsFieldRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approves or denies the change
         * @param {string} changeId id of change
         * @param {ApprovalDetails} approvalDetails Change approval request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApprovalStatus: async (changeId: string, approvalDetails: ApprovalDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeId' is not null or undefined
            assertParamExists('changeApprovalStatus', 'changeId', changeId)
            // verify required parameter 'approvalDetails' is not null or undefined
            assertParamExists('changeApprovalStatus', 'approvalDetails', approvalDetails)
            const localVarPath = `/changeHistory/{changeId}`
                .replace(`{${"changeId"}}`, encodeURIComponent(String(changeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveSite: async (siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('createActiveSite', 'siteId', siteId)
            // verify required parameter 'activeSiteRequestSchema' is not null or undefined
            assertParamExists('createActiveSite', 'activeSiteRequestSchema', activeSiteRequestSchema)
            const localVarPath = `/activeSites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeSiteRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create list of new projects
         * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveSites: async (updateActiveSitesRequest: UpdateActiveSitesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateActiveSitesRequest' is not null or undefined
            assertParamExists('createActiveSites', 'updateActiveSitesRequest', updateActiveSitesRequest)
            const localVarPath = `/activeSites/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActiveSitesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActiveSite: async (siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteActiveSite', 'siteId', siteId)
            // verify required parameter 'activeSiteRequestSchema' is not null or undefined
            assertParamExists('deleteActiveSite', 'activeSiteRequestSchema', activeSiteRequestSchema)
            const localVarPath = `/activeSites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeSiteRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets download link for BRS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBRS: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brs/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets download link for Capacity Year Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCapacityYearDashboard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/capacityYear/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets download link for change history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadChangeHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/changeHistory/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets download link for MHE Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMHEDashboard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/mhe/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets download link for Projects page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activeSites/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single active site
         * @param {string} siteId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSite: async (siteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getActiveSite', 'siteId', siteId)
            const localVarPath = `/activeSites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all active sites
         * @param {number} [limit] 
         * @param {string} [nextToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSites: async (limit?: number, nextToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activeSites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all BRS entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBRS: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Current and Future Projects by Year
         * @param {string} [startDate] Start date for query (optional, required if end date is present)
         * @param {string} [endDate] End date for query (optional, required if start date is present)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsByYear: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets number of change history entries that need approval.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeHistoryCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/changeHistory/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the change history details
         * @param {string} changeId id of change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeHistoryDetails: async (changeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeId' is not null or undefined
            assertParamExists('getChangeHistoryDetails', 'changeId', changeId)
            const localVarPath = `/changeHistory/{changeId}`
                .replace(`{${"changeId"}}`, encodeURIComponent(String(changeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the change history table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeHistoryTable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/changeHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get API info to load map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user permission level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single BRS related to a site
         * @param {string} siteId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteBRS: async (siteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteBRS', 'siteId', siteId)
            const localVarPath = `/brs/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get API to load user meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMetaData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/userMetaData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save column names to DynamoDB
         * @param {UserMetaDataResponse} userMetaDataResponse Data for user metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserMetaData: async (userMetaDataResponse: UserMetaDataResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userMetaDataResponse' is not null or undefined
            assertParamExists('postUserMetaData', 'userMetaDataResponse', userMetaDataResponse)
            const localVarPath = `/userMetaData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMetaDataResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveSite: async (siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateActiveSite', 'siteId', siteId)
            // verify required parameter 'activeSiteRequestSchema' is not null or undefined
            assertParamExists('updateActiveSite', 'activeSiteRequestSchema', activeSiteRequestSchema)
            const localVarPath = `/activeSites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeSiteRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update list of projects
         * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveSites: async (updateActiveSitesRequest: UpdateActiveSitesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateActiveSitesRequest' is not null or undefined
            assertParamExists('updateActiveSites', 'updateActiveSitesRequest', updateActiveSitesRequest)
            const localVarPath = `/activeSites/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActiveSitesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates/updates BRS entry
         * @param {string} siteId id of sort center
         * @param {BRSSiteRequestBodySchema} bRSSiteRequestBodySchema Data for BRS entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteBRS: async (siteId: string, bRSSiteRequestBodySchema: BRSSiteRequestBodySchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('updateSiteBRS', 'siteId', siteId)
            // verify required parameter 'bRSSiteRequestBodySchema' is not null or undefined
            assertParamExists('updateSiteBRS', 'bRSSiteRequestBodySchema', bRSSiteRequestBodySchema)
            const localVarPath = `/brs/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bRSSiteRequestBodySchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PorApi - functional programming interface
 * @export
 */
export const PorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new field for Projects page
         * @param {AddProjectsFieldRequestSchema} addProjectsFieldRequestSchema Metadata for new field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectsField(addProjectsFieldRequestSchema: AddProjectsFieldRequestSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectsField(addProjectsFieldRequestSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approves or denies the change
         * @param {string} changeId id of change
         * @param {ApprovalDetails} approvalDetails Change approval request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeApprovalStatus(changeId: string, approvalDetails: ApprovalDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeApprovalStatus(changeId, approvalDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActiveSite(siteId, activeSiteRequestSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create list of new projects
         * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActiveSites(updateActiveSitesRequest: UpdateActiveSitesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActiveSites(updateActiveSitesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActiveSite(siteId, activeSiteRequestSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets download link for BRS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBRS(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadBRS(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets download link for Capacity Year Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCapacityYearDashboard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCapacityYearDashboard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets download link for change history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadChangeHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadChangeHistory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets download link for MHE Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMHEDashboard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMHEDashboard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets download link for Projects page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single active site
         * @param {string} siteId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveSite(siteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveSiteData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveSite(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all active sites
         * @param {number} [limit] 
         * @param {string} [nextToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveSites(limit?: number, nextToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedActiveSitesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveSites(limit, nextToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all BRS entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBRS(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BRSTableEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBRS(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Current and Future Projects by Year
         * @param {string} [startDate] Start date for query (optional, required if end date is present)
         * @param {string} [endDate] End date for query (optional, required if start date is present)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectsByYear(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectsByYear(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets number of change history entries that need approval.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeHistoryCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeHistoryCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeHistoryCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the change history details
         * @param {string} changeId id of change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeHistoryDetails(changeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeHistoryDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeHistoryDetails(changeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the change history table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeHistoryTable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChangeHistoryDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeHistoryTable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get API info to load map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MapDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user permission level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single BRS related to a site
         * @param {string} siteId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteBRS(siteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: BRSData; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteBRS(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get API to load user meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMetaData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMetaDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMetaData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save column names to DynamoDB
         * @param {UserMetaDataResponse} userMetaDataResponse Data for user metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserMetaData(userMetaDataResponse: UserMetaDataResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserMetaData(userMetaDataResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActiveSite(siteId, activeSiteRequestSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update list of projects
         * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActiveSites(updateActiveSitesRequest: UpdateActiveSitesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActiveSites(updateActiveSitesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates/updates BRS entry
         * @param {string} siteId id of sort center
         * @param {BRSSiteRequestBodySchema} bRSSiteRequestBodySchema Data for BRS entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSiteBRS(siteId: string, bRSSiteRequestBodySchema: BRSSiteRequestBodySchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSiteBRS(siteId, bRSSiteRequestBodySchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PorApi - factory interface
 * @export
 */
export const PorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PorApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new field for Projects page
         * @param {AddProjectsFieldRequestSchema} addProjectsFieldRequestSchema Metadata for new field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectsField(addProjectsFieldRequestSchema: AddProjectsFieldRequestSchema, options?: any): AxiosPromise<void> {
            return localVarFp.addProjectsField(addProjectsFieldRequestSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approves or denies the change
         * @param {string} changeId id of change
         * @param {ApprovalDetails} approvalDetails Change approval request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApprovalStatus(changeId: string, approvalDetails: ApprovalDetails, options?: any): AxiosPromise<void> {
            return localVarFp.changeApprovalStatus(changeId, approvalDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: any): AxiosPromise<void> {
            return localVarFp.createActiveSite(siteId, activeSiteRequestSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create list of new projects
         * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveSites(updateActiveSitesRequest: UpdateActiveSitesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createActiveSites(updateActiveSitesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: any): AxiosPromise<void> {
            return localVarFp.deleteActiveSite(siteId, activeSiteRequestSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets download link for BRS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBRS(options?: any): AxiosPromise<DownloadResponse> {
            return localVarFp.downloadBRS(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets download link for Capacity Year Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCapacityYearDashboard(options?: any): AxiosPromise<DownloadResponse> {
            return localVarFp.downloadCapacityYearDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets download link for change history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadChangeHistory(options?: any): AxiosPromise<DownloadResponse> {
            return localVarFp.downloadChangeHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets download link for MHE Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMHEDashboard(options?: any): AxiosPromise<DownloadResponse> {
            return localVarFp.downloadMHEDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets download link for Projects page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProjects(options?: any): AxiosPromise<DownloadResponse> {
            return localVarFp.downloadProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single active site
         * @param {string} siteId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSite(siteId: string, options?: any): AxiosPromise<ActiveSiteData> {
            return localVarFp.getActiveSite(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all active sites
         * @param {number} [limit] 
         * @param {string} [nextToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSites(limit?: number, nextToken?: string, options?: any): AxiosPromise<PaginatedActiveSitesData> {
            return localVarFp.getActiveSites(limit, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all BRS entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBRS(options?: any): AxiosPromise<Array<BRSTableEntry>> {
            return localVarFp.getAllBRS(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Current and Future Projects by Year
         * @param {string} [startDate] Start date for query (optional, required if end date is present)
         * @param {string} [endDate] End date for query (optional, required if start date is present)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsByYear(startDate?: string, endDate?: string, options?: any): AxiosPromise<DashboardData> {
            return localVarFp.getAllProjectsByYear(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets number of change history entries that need approval.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeHistoryCount(options?: any): AxiosPromise<ChangeHistoryCountResponse> {
            return localVarFp.getChangeHistoryCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the change history details
         * @param {string} changeId id of change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeHistoryDetails(changeId: string, options?: any): AxiosPromise<ChangeHistoryDetails> {
            return localVarFp.getChangeHistoryDetails(changeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the change history table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeHistoryTable(options?: any): AxiosPromise<Array<ChangeHistoryDetails>> {
            return localVarFp.getChangeHistoryTable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get API info to load map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapDetails(options?: any): AxiosPromise<Array<MapDetail>> {
            return localVarFp.getMapDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user permission level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(options?: any): AxiosPromise<PermissionsResponse> {
            return localVarFp.getPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single BRS related to a site
         * @param {string} siteId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteBRS(siteId: string, options?: any): AxiosPromise<{ [key: string]: BRSData; }> {
            return localVarFp.getSiteBRS(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get API to load user meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMetaData(options?: any): AxiosPromise<UserMetaDataResponse> {
            return localVarFp.getUserMetaData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save column names to DynamoDB
         * @param {UserMetaDataResponse} userMetaDataResponse Data for user metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserMetaData(userMetaDataResponse: UserMetaDataResponse, options?: any): AxiosPromise<void> {
            return localVarFp.postUserMetaData(userMetaDataResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update active site
         * @param {string} siteId id of sort center
         * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: any): AxiosPromise<void> {
            return localVarFp.updateActiveSite(siteId, activeSiteRequestSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update list of projects
         * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveSites(updateActiveSitesRequest: UpdateActiveSitesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateActiveSites(updateActiveSitesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates/updates BRS entry
         * @param {string} siteId id of sort center
         * @param {BRSSiteRequestBodySchema} bRSSiteRequestBodySchema Data for BRS entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteBRS(siteId: string, bRSSiteRequestBodySchema: BRSSiteRequestBodySchema, options?: any): AxiosPromise<void> {
            return localVarFp.updateSiteBRS(siteId, bRSSiteRequestBodySchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PorApi - object-oriented interface
 * @export
 * @class PorApi
 * @extends {BaseAPI}
 */
export class PorApi extends BaseAPI {
    /**
     * 
     * @summary Create new field for Projects page
     * @param {AddProjectsFieldRequestSchema} addProjectsFieldRequestSchema Metadata for new field
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public addProjectsField(addProjectsFieldRequestSchema: AddProjectsFieldRequestSchema, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).addProjectsField(addProjectsFieldRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approves or denies the change
     * @param {string} changeId id of change
     * @param {ApprovalDetails} approvalDetails Change approval request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public changeApprovalStatus(changeId: string, approvalDetails: ApprovalDetails, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).changeApprovalStatus(changeId, approvalDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create active site
     * @param {string} siteId id of sort center
     * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public createActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).createActiveSite(siteId, activeSiteRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create list of new projects
     * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public createActiveSites(updateActiveSitesRequest: UpdateActiveSitesRequest, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).createActiveSites(updateActiveSitesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete active site
     * @param {string} siteId id of sort center
     * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public deleteActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).deleteActiveSite(siteId, activeSiteRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets download link for BRS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public downloadBRS(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).downloadBRS(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets download link for Capacity Year Dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public downloadCapacityYearDashboard(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).downloadCapacityYearDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets download link for change history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public downloadChangeHistory(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).downloadChangeHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets download link for MHE Dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public downloadMHEDashboard(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).downloadMHEDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets download link for Projects page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public downloadProjects(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).downloadProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single active site
     * @param {string} siteId id of sort center
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getActiveSite(siteId: string, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getActiveSite(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all active sites
     * @param {number} [limit] 
     * @param {string} [nextToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getActiveSites(limit?: number, nextToken?: string, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getActiveSites(limit, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all BRS entries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getAllBRS(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getAllBRS(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Current and Future Projects by Year
     * @param {string} [startDate] Start date for query (optional, required if end date is present)
     * @param {string} [endDate] End date for query (optional, required if start date is present)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getAllProjectsByYear(startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getAllProjectsByYear(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets number of change history entries that need approval.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getChangeHistoryCount(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getChangeHistoryCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the change history details
     * @param {string} changeId id of change
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getChangeHistoryDetails(changeId: string, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getChangeHistoryDetails(changeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the change history table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getChangeHistoryTable(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getChangeHistoryTable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get API info to load map
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getMapDetails(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getMapDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user permission level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getPermissions(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single BRS related to a site
     * @param {string} siteId id of sort center
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getSiteBRS(siteId: string, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getSiteBRS(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get API to load user meta data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public getUserMetaData(options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).getUserMetaData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save column names to DynamoDB
     * @param {UserMetaDataResponse} userMetaDataResponse Data for user metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public postUserMetaData(userMetaDataResponse: UserMetaDataResponse, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).postUserMetaData(userMetaDataResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update active site
     * @param {string} siteId id of sort center
     * @param {ActiveSiteRequestSchema} activeSiteRequestSchema Data for active site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public updateActiveSite(siteId: string, activeSiteRequestSchema: ActiveSiteRequestSchema, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).updateActiveSite(siteId, activeSiteRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update list of projects
     * @param {UpdateActiveSitesRequest} updateActiveSitesRequest Data for bulk active site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public updateActiveSites(updateActiveSitesRequest: UpdateActiveSitesRequest, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).updateActiveSites(updateActiveSitesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates/updates BRS entry
     * @param {string} siteId id of sort center
     * @param {BRSSiteRequestBodySchema} bRSSiteRequestBodySchema Data for BRS entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PorApi
     */
    public updateSiteBRS(siteId: string, bRSSiteRequestBodySchema: BRSSiteRequestBodySchema, options?: AxiosRequestConfig) {
        return PorApiFp(this.configuration).updateSiteBRS(siteId, bRSSiteRequestBodySchema, options).then((request) => request(this.axios, this.basePath));
    }
}


