import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import Link from '@amzn/meridian/link';
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small";
import Icon from '@amzn/meridian/icon';
import PORContext from "../../store/por-context";
import routes from "../routes/Routes";
import { POR_WIKI_LINK, OPEN_ISSUE_URL, FEATURE_REQUEST_URL, SIDE_MENU_HYPERLINKS } from "../../constants";
import "./planOfRecordMenuStyles.scss";

export default function PlanOfRecordSideMenu() {
  const [hash, setHash] = useState("/");
  let navigate = useNavigate();
  let location = useLocation();
  const pathName = location.pathname;
  const porContext = useContext(PORContext);
  const { isSideMenuOpen } = porContext;

  useEffect(() => {
    if (hash !== pathName) {
      setHash(pathName);
    }
  }, [pathName]);

  return (
    <SideMenu open={isSideMenuOpen}>
      {routes.map(({ path, name, showInSideMenu }, key) => (
        showInSideMenu && <SideMenuLink
          key={key}
          href={path}
          selected={hash === path}
          onClick={(hashValue) => {
            setHash(hashValue);
            navigate(path);
          }}
          data-testid="sideMenuTestId"
        >
          {name}
        </SideMenuLink>
      ))}
      {
       Object.keys(SIDE_MENU_HYPERLINKS).map((key) =>{
          return <SideMenuLink key={key}>
            <Link 
            href={SIDE_MENU_HYPERLINKS[key]} 
            target="_blank" 
            rel="noopener noreferrer">
              {key}
              <Icon tokens={exportSmallTokens} />
            </Link>
          </SideMenuLink>
        })
      }

      <div>
        <div>
          <a href={OPEN_ISSUE_URL} type="secondary" target="_blank" rel="noopener noreferrer" className="footerMenu">
            <span className="footerFontStyle">Open Issue</span>
          </a>
        </div>
        <div>
          <a href={FEATURE_REQUEST_URL} target="_blank" type="secondary" rel="noopener noreferrer" className="footerMenu">
            <span className="footerFontStyle">Feature Request</span>
          </a>
        </div>
        <div>
          <a href={POR_WIKI_LINK} type="secondary" target="_blank" rel="noopener noreferrer" className="footerMenu">
            <span className="footerFontStyle">Wiki</span>
          </a>
        </div>
      </div>

    </SideMenu>
  )
}