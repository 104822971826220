import React, { createContext, useCallback, useState } from "react";
import { DETAILED_VIEW } from "../constants";

interface ChangeHistoryStateProps {
  columnFilters: object;  // used by Filter By Column Group, object key is [string], value is [array]
  searchChangeHistoryFilter: string;  // used by View Control Group
  ChangeHistoryViewType: string;  // used by View Control Group
  selectedColumns: object;  // used by Side Column Menu
  selectedChangeHistory: Set<string>;  // used by ChangeHistory Table check boxes
  showFilter : boolean;
  setColumnFilters(columnFilters): void;
  setSearchChangeHistoryFilter(searchChangeHistoryFilter: string): void;
  setChangeHistoryViewType(ChangeHistoryViewType: string): void;
  setSelectedColumns(selectedColumns: object): void;
  setSelectedChangeHistory(selectedChangeHistory: Set<string>): void;
  setShowFilter(showFilter): void;
}

const initialState: ChangeHistoryStateProps = {
  columnFilters: { },
  searchChangeHistoryFilter: "",
  ChangeHistoryViewType: DETAILED_VIEW,
  selectedColumns: { },
  selectedChangeHistory: new Set(),
  showFilter: true,
  setColumnFilters: () => { },
  setSearchChangeHistoryFilter: () => { },
  setChangeHistoryViewType: () => { },
  setSelectedColumns: () => { },
  setSelectedChangeHistory: () => { },
  setShowFilter: () => { }
};

const ChangeHistoryContext = createContext(initialState);

export function ChangeHistoryContextProvider({ children }) {
  const [userColumnFilters, setUserColumnFilters] = useState(initialState.columnFilters);
  const [userSearchChangeHistoryFilter, setUserSearchChangeHistoryFilter] = useState(initialState.searchChangeHistoryFilter);
  const [userChangeHistoryViewType, setUserChangeHistoryViewType] = useState(initialState.ChangeHistoryViewType);
  const [userSelectedColumns, setUserSelectedColumns] = useState(initialState.selectedColumns);
  const [userSelectedChangeHistory, setUserSelectedChangeHistory] = useState(initialState.selectedChangeHistory);
  const [userShowFilter, setUserShowFilter] = useState(initialState.showFilter);

  function setColumnFilters(columnFilters) {
    setUserColumnFilters(columnFilters);
  }

  function setSearchChangeHistoryFilter(searchChangeHistoryFilter) {
    setUserSearchChangeHistoryFilter(searchChangeHistoryFilter);
  }

  function setChangeHistoryViewType(ChangeHistoryViewType) {
    setUserChangeHistoryViewType(ChangeHistoryViewType);
  }

  function setSelectedColumns(selectedColumns) {
    setUserSelectedColumns(selectedColumns);
  }

  function setSelectedChangeHistory(selectedChangeHistory) {
    setUserSelectedChangeHistory(selectedChangeHistory);
  }

  function setShowFilter(showFilter) {
    setUserShowFilter(showFilter);
  }

  const context: ChangeHistoryStateProps = {
    columnFilters: userColumnFilters,
    searchChangeHistoryFilter: userSearchChangeHistoryFilter,
    ChangeHistoryViewType: userChangeHistoryViewType,
    selectedColumns: userSelectedColumns,
    selectedChangeHistory: userSelectedChangeHistory,
    showFilter: userShowFilter,
    setColumnFilters,
    setSearchChangeHistoryFilter,
    setChangeHistoryViewType,
    setSelectedColumns,
    setSelectedChangeHistory,
    setShowFilter,
  }

  return (
    <ChangeHistoryContext.Provider value={context}>{children}</ChangeHistoryContext.Provider>
  );
}

export const useChangeHistoryContext = () => React.useContext(ChangeHistoryContext);

export default ChangeHistoryContext;