import React, { useState, useEffect, useContext } from "react";
import Column from "@amzn/meridian/column";
import PorApiFactory from "../../por-api/PlanOfRecordApiFactory";
import PORContext from "../../store/por-context";
import { Logger } from "@amzn/kepler-katal-logger";
import { CHGHISTORY_TABLE_TITLE_MAP } from "../../constants/ChangeHistoryLayout";
import ChangeHistoryTable from "./ChangeHistoryTable";
import { useChangeHistoryContext } from "../../store/change-history-store";
import ChangeHistoryViewControlGroup from "./ChangeHistoryViewControlGroup";
import ChangeHistoryFilterByColumnGroup from "./ChangeHistoryFilterByColumnGroup";
import isEmpty from "lodash/isEmpty";

export default function ChangeHistoryPage() {
  const porContext = useContext(PORContext);
  const {
    changeHistoryData: changeHistoryList,
    setChangeHistoryData: setChangeHistoryList,
    setIsProjectsLoading,
  } = porContext;
  const { columnFilters, showFilter, setColumnFilters } =
    useChangeHistoryContext();

  const PorApi = PorApiFactory();

  useEffect(() => {
    if (isEmpty(changeHistoryList)) {
      (async () => {
        setIsProjectsLoading(true);
        try {
          const getChangeHistoryResponse = (await PorApi.getChangeHistoryTable())
              .data;
          setChangeHistoryList(getChangeHistoryResponse);
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
        } finally {
          setIsProjectsLoading(false);
        }
      })();
    }
  }, []);

  return (
    <Column spacingInset="400" width="100%">
      <ChangeHistoryViewControlGroup />
      {showFilter && (
        <ChangeHistoryFilterByColumnGroup
          filteringSourceData={changeHistoryList}
          filterByColumns={Object.keys(CHGHISTORY_TABLE_TITLE_MAP).filter(
            (value) => value != "id"
          )}
          sourceDataFilters={columnFilters}
          setSourceDataFilters={setColumnFilters}
        />
      )}
      <ChangeHistoryTable />
    </Column>
  );
}
