import { useState } from "react";
import { Map } from "react-map-gl";
import DeckGL from "@deck.gl/react";
import { MapView } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import IconClusterLayer from "./icon-cluster-layer";
import LocationIconMapping from "./location-icon-mapping.json";
import LocationIconAtlas from "../../../assets/circle.png";
import LocationIconAtlasLMSC from "../../../assets/triangle.png";
import "../styles.scss";
import { MAP_COLOR_DATA } from "../../../constants";
import Legend, { LegendProvider } from "@amzn/meridian/legend";
import MapPopupDetails from "./MapPopupDetails";
import isEmpty from "lodash/isEmpty";
import { useProjectsContext } from "../../../store/project-store";

const MAP_STYLE =
  "https://5.visualization.resources.maps.a2z.com/styles/amazon-light-grey/AmazonLightGrey1.14.0-03022021-0738.json";
const iconMapping = LocationIconMapping;
const iconAtlas = LocationIconAtlas;
const iconAtlasLMSC = LocationIconAtlasLMSC;
const showCluster = false;
const MAP_VIEW = new MapView({ repeat: true });
const INITIAL_VIEW_STATE = {
  longitude: -96.41669,
  latitude: 37.7853,
  zoom: 3.9,
  maxZoom: 20,
  pitch: 0,
  bearing: 0,
};

export default function MapHome({ mapDetails }) {
  const [SCNodeInfo, setSCNodeInfo] = useState({});
  const { columnFilters, searchProjectsFilter } = useProjectsContext();

  const renderTooltip = (info) => {
    const { object, objects } = info;
    if (objects) {
      let cluster_count = object.point_count;
      return (
        <div className="tooltip interactive">
          {objects.map((obj) => {
            {
              cluster_count = cluster_count - 1;
            }
            return (
              <MapPopupDetails
                mapDetails={obj}
                clusterCount={cluster_count}
                closePopupHandler={hideTooltip}
              />
            );
          })}
        </div>
      );
    }

    if (object) {
      return (
        <div className="tooltip interactive">
          <MapPopupDetails
            mapDetails={object}
            clusterCount={0}
            closePopupHandler={hideTooltip}
          />
        </div>
      );
    }

    if (!object) {
      return null;
    }
  };

  const hideTooltip = () => {
    setSCNodeInfo({});
  };
  const expandTooltip = (info) => {
    if (info.picked) {
      setSCNodeInfo(info);
    } else {
      setSCNodeInfo({});
    }
  };

  /**
   * @summary filter method for Search Project input
   * @param {object} [projectData] single project data
   * **/
  const userFilterGlobalMethod = (projectData) => {
    if (isEmpty(searchProjectsFilter)) {
      return true;
    }
    return projectData.site
      .toUpperCase()
      .startsWith(searchProjectsFilter.toUpperCase());
  };

  /**
   * @summary filter method for Filter by Column select dropdown
   * @param {object} [projectData] single project data
   * **/
  const userInputFilterMethod = (projectData) => {
    if (
      !Object.keys(columnFilters) ||
      Object.keys(columnFilters).length === 0
    ) {
      return true;
    }
    let filterResult = true;

    Object.keys(columnFilters) // only compare the ones are defined
      .filter(
        (filterKey) =>
          Array.isArray(columnFilters[filterKey]) &&
          columnFilters[filterKey].length > 0
      )
      .forEach((filterKey) => {
        filterResult =
          filterResult &&
          filterByMultiSelectTags(filterKey, columnFilters, projectData);
      });
    // return filterResult;

    return filterResult;
  };

  /**
   * @summary filter method to check with single filtering constrain
   * @param {string} [filterKey] filter by field name
   * @param {object} [columnFilters] columnFilter state
   * @param {object} [activeSiteData] single project data
   * **/
  const filterByMultiSelectTags = (
    filterKey,
    columnFilters,
    activeSiteData
  ) => {
    const columnMap = {
      "LMSC/SC-SC": "lmsc",
      Status: "status",
      "SC Type": "scType",
      Region: "region",
      Cluster: "siteCluster",
      "Ops Region": "opsRegion",
    };
    if (columnFilters[filterKey].length === 0) {
      return true;
    }
    return columnFilters[filterKey].some(
      (filterValue) => filterValue === activeSiteData[columnMap[filterKey]]
    );
  };

  const displayMapDetails = mapDetails
    .filter(userFilterGlobalMethod)
    .filter(userInputFilterMethod);

  const layerProps = {
    data: displayMapDetails.filter((obj) => {
      return obj.lmsc !== "LMSC";
    }),
    pickable: true,
    getPosition: (d) => [d.longitude, d.latitude],
    iconAtlas: iconAtlas,
    iconMapping: iconMapping,
    onClick: !SCNodeInfo && setSCNodeInfo,
  };

  const layer = showCluster
    ? new IconClusterLayer({
        ...layerProps,
        id: "icon-cluster",
        sizeScale: 40,
      })
    : new IconLayer({
        ...layerProps,
        id: "icon",
        getIcon: (d) => "marker",
        getColor: (d) =>
          MAP_COLOR_DATA.find((a) => a.label == d.region)?.colorArray || [
            0, 0, 0,
          ],
        sizeUnits: "meters",
        sizeScale: 200,
        sizeMinPixels: 40,
      });

  const layerLMSCProps = {
    data: displayMapDetails.filter((obj) => {
      return obj.lmsc == "LMSC";
    }),
    pickable: true,
    getPosition: (d) => [d.longitude, d.latitude],
    iconAtlas: iconAtlasLMSC,
    iconMapping: iconMapping,
    onClick: !SCNodeInfo && setSCNodeInfo,
  };

  const layerLMSC = showCluster
    ? new IconClusterLayer({
        ...layerLMSCProps,
        id: "icon-cluster-lmsc",
        sizeScale: 40,
      })
    : new IconLayer({
        ...layerLMSCProps,
        id: "icon-lmsc",
        getIcon: (d) => "marker",
        getColor: (d) =>
          MAP_COLOR_DATA.find((a) => a.label == d.region)?.colorArray || [
            0, 0, 0,
          ],
        sizeUnits: "meters",
        sizeScale: 200,
        sizeMinPixels: 30,
      });

  return (
    <div className="mapStyling">
      <DeckGL
        layers={[layerLMSC, layer]}
        views={MAP_VIEW}
        initialViewState={INITIAL_VIEW_STATE}
        controller={{ dragRotate: false }}
        onViewStateChange={hideTooltip}
        onClick={expandTooltip}
      >
        <Map reuseMaps mapStyle={MAP_STYLE} />

        <div style={{ position: "sticky", top: 1000, left: 1000 }}>
          <LegendProvider data={MAP_COLOR_DATA}>
            <Legend direction="horizontal" />
          </LegendProvider>
        </div>
        <div style={{ position: "absolute", left: "50%" }}>
          <LegendProvider
            data={[
              { label: "SC-SC", symbol: "circle", key: "1", color: "black" },
              { label: "LMSC", symbol: "triangleUp", key: "2", color: "black" },
            ]}
          >
            <Legend direction="horizontal" />
          </LegendProvider>
        </div>
      </DeckGL>
      {renderTooltip(SCNodeInfo)}
    </div>
  );
}
