import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Text from "@amzn/meridian/text";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import DatePicker from "@amzn/meridian/date-picker";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import Link from "@amzn/meridian/link";
import XLSX from "xlsx";
import {
  DEFAULT_END_YEAR_LAP,
  COUNTRY_US,
  COUNTRY_CANADA,
  COUNTRY_FIELD_TO_TITLE,
  EXISTING_SITE_KEY,
  CAPACITY_YEAR_COLUMN_NAME,
  COUNTRY_COLUMN_NAME,
  MHE_TYPE_COLUMN_NAME,
  STATUS_COLUMN_NAME,
  LAUNCHES_COLUMN_NAME
} from "../../constants";
import cloneDeep from "lodash/cloneDeep";

import { capitalizeFirstLetter, formatDateMonth, navToProjectDetails, addGrandTotaltoRow, addCountryTotals, getYearsinData } from "./DashboardUtils";
import PorApiFactory from '../../por-api/PlanOfRecordApiFactory';
import Alert from "@amzn/meridian/alert";
import PORContext from "../../store/por-context";
import { Logger } from '@amzn/kepler-katal-logger';

export default function ActiveByMHETable() {
  // Default date range is currentYear to currentYear + 3
  const today = new Date();
  const table_headers = [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA], "Grand Total"]
  const navigate = useNavigate();
  const endDateYear = today.getFullYear() - 1 + DEFAULT_END_YEAR_LAP;
  const startDateYear = today.getFullYear();
  const dayOfToday = today.getDate();
  const monthOfToday = today.getMonth() + 1;
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(`${startDateYear}-${formatDateMonth(monthOfToday)}-${formatDateMonth(dayOfToday)}`);
  const [endDate, setEndDate] = useState(`${endDateYear}-${formatDateMonth(monthOfToday)}-${formatDateMonth(dayOfToday)}`);
  const [shouldFetchUpcomingProjectsData, setShouldFetchUpcomingProjectsData] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const porContext = useContext(PORContext);
  const { dashboardObject, setDashboardObject } = porContext;
  const [filteredMHEData, setFilteredMHEData] = useState(new Array());
  const [mheTypeList, setMheTypeList] = useState(new Array());

  let usYearList = new Array();
  filteredMHEData.forEach(element => {
    usYearList.push(element['year'])
  });
  let usYearLap = usYearList.sort().length + 1;

  let canadaYearList = usYearList;
  let canadaYearLap = canadaYearList.length + 1;

  const PorApi = PorApiFactory();

  useEffect(() => {
    if (shouldFetchUpcomingProjectsData || Object.keys(dashboardObject).length == 0) {
      (async () => {
        setIsLoading(true);
        try {
          const upcomingProjectsTableResponse = (await PorApi.getAllProjectsByYear(startDate, endDate)).data;
          setDashboardObject(upcomingProjectsTableResponse);
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
          setHasError(true);
          setErrorMessage(ex.message);
        } finally {
          setIsLoading(false);
          setShouldFetchUpcomingProjectsData(false);
        }
      })();
    }

  }, [shouldFetchUpcomingProjectsData]);

  useEffect(() => {
    let filteredCopy = cloneDeep(dashboardObject);
    filteredCopy.forEach(project => {
      project[EXISTING_SITE_KEY] = project[EXISTING_SITE_KEY].filter((countryData: Object) => (["Upcoming", "Active"].includes(countryData['Status'])));
    });
    setFilteredMHEData(filteredCopy);
  }, dashboardObject)

  useEffect(() => {
    let mhe_types_set = new Set();
    filteredMHEData.forEach(project => {
      project[EXISTING_SITE_KEY].forEach(countryData => {
        mhe_types_set.add(countryData["MHE Type"]);
      });
    });
    setMheTypeList([...mhe_types_set].sort());
  }, [filteredMHEData])

  const fetchUpcomingDashboardDataFromDate = () => {
    setShouldFetchUpcomingProjectsData(true);
  }

  const addGrandTotalRow = () => {
    let rowData = new Array();
    filteredMHEData.forEach(project => {
      [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
        usYearList.forEach(year => {
          if (project.year === year) {
            let count = 0;
            project[EXISTING_SITE_KEY].forEach(countryData => {
              if (countryData.Country === country) {
                count += 1;
              }
            });
            rowData.push({
              "year": project.year,
              "Country": country,
              "count": count
            })
          }
        })
      });
    });
    addCountryTotals(rowData);
    addGrandTotaltoRow(rowData, usYearList);
    const cells = table_headers.map(country => {
      return [...usYearList, "Total"].map(year => {
        return rowData.filter(data => (data.year === year && data.Country == country)).map(cell => {
          return (
            <TableCell
              key={`${country}-${year}-count`}
              backgroundColor={year === "Total" ? "secondary" : "primary"}
            >
              <Link onClick={navToProjectDetails(navigate, { [CAPACITY_YEAR_COLUMN_NAME]: cell.year === "Total" ? getYearsinData(dashboardObject) : [cell.year], [COUNTRY_COLUMN_NAME]: cell.Country === "Grand Total"? ["US", "Canada"] : cell.Country, [MHE_TYPE_COLUMN_NAME]: mheTypeList, [STATUS_COLUMN_NAME]: ["Active", "Upcoming"], [LAUNCHES_COLUMN_NAME]: ["Existing Site"] })}>{Number(cell.count) || ""}</Link>
            </TableCell>
          )
        });
      })
    });
    return (
      <TableRow key={`grand-total-row`} backgroundColor="#DAF0FF">
        <TableCell key={`grand-total-row`}>{capitalizeFirstLetter("grand Total")}</TableCell>
        {cells}
      </TableRow>
    )

  }

  const generateRowsByMHE = (mhe) => {
    let rowData = new Array();
    filteredMHEData.forEach(project => {
      [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
        rowData.push({
          "year": project.year,
          "Country": country,
          "count": getCountByMHE(country, mhe, project)
        });
      })
    });
    addCountryTotals(rowData);
    addGrandTotaltoRow(rowData, usYearList);

    const cells = table_headers.map(country => {
      return [...usYearList, "Total"].map(year => {
        return rowData.filter(data => (data.year === year && data.Country == country)).map(cell => {
          return (
            <TableCell
              key={`${mhe}-${country}-${year}-count`}
              backgroundColor={year === "Total" ? "secondary" : "primary"}
            >
              <Link onClick={navToProjectDetails(navigate, { [CAPACITY_YEAR_COLUMN_NAME]: cell.year === "Total" ? getYearsinData(dashboardObject) : [cell.year], [COUNTRY_COLUMN_NAME]: cell.Country === "Grand Total"? ["US", "Canada"] : cell.Country, [MHE_TYPE_COLUMN_NAME]: [mhe], [STATUS_COLUMN_NAME]: ["Active", "Upcoming"], [LAUNCHES_COLUMN_NAME]: ["Existing Site"] })}>{Number(cell.count) || ""}</Link>
            </TableCell>
          )
        });
      })
    });

    return (
      <TableRow key={`${mhe}-row`}>
        <TableCell key={`${mhe}-row`}>{capitalizeFirstLetter(mhe)}</TableCell>
        {cells}
      </TableRow>
    )
  }

  const getCountByMHE = (country, mhe, project) => {
    let count = 0;
    project[EXISTING_SITE_KEY].forEach(launchTypeData => {
      if (launchTypeData.Country === country && launchTypeData['MHE Type'] === mhe)
        count++;
    });
    return count;
  }

  const generateExcelData = () => {
    let data = {
      "US": [],
      "Canada": []
    };


    [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
      mheTypeList.forEach(mhe => {
        let mheData = {}
        usYearList.forEach(year => {
          let count = 0;
          filteredMHEData.forEach(project => {
            if (project.year === year) {
              [EXISTING_SITE_KEY].forEach(launchType => {
                project[launchType].forEach(countryData => {
                  if (countryData.Country === country && countryData["MHE Type"] === mhe) {
                    count += 1;
                  }
                });
              });
            }
          })
          mheData[year] = count;
          mheData["MHE Type"] = mhe;
        });
        data[country].push(mheData);
      });
    });
    const workbook = XLSX.utils.book_new();
    Object.keys(data).forEach(key => {
      let worksheet = XLSX.utils.json_to_sheet(data[key], { header: ["MHE Type", ...usYearList.map(String)] });
      XLSX.utils.book_append_sheet(workbook, worksheet, key);
    })
    XLSX.writeFile(workbook, `active_launch_expansions_mhe_${Date.now()}.xlsx`);

  }

  return isLoading ? <Column spacingInset="400"><Loader /></Column> :
    <Column
      spacingInset="none 400"
      width="70%"
      backgroundColor="primary"
    >
      <Text
        type="h500"
      >Active Launch/Expansion Projects by MHE Template</Text>
      {hasError && <Alert size="medium" type="error">{errorMessage}</Alert>}
      <Table
        headerRows={2}
        showDividers
      >
        <TableActionBar widths={["10vw", "10vw", "fill"]}>
          <DatePicker value={startDate} locale="en-CA" onChange={setStartDate} />
          <DatePicker value={endDate} locale="en-CA" onChange={setEndDate} />
          <div />
          <Button type="icon" onClick={generateExcelData}>
            <Icon tokens={downloadLargeTokens}>Download</Icon>
          </Button>
          <Button onClick={fetchUpcomingDashboardDataFromDate} >
            Submit
          </Button>
        </TableActionBar>
        <TableRow>
          <TableCell></TableCell>
          <TableCell columnSpan={usYearLap}>{COUNTRY_FIELD_TO_TITLE[COUNTRY_US]}</TableCell>
          <TableCell columnSpan={canadaYearLap}>{COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]}</TableCell>
          <TableCell columnSpan={canadaYearLap}>{"Grand Total"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          {[...usYearList, "Total"].map(usYear => <TableCell key={`${COUNTRY_US}-${usYear}-header`} backgroundColor={usYear === "Total" ? "secondary" : "primary"}>{usYear}</TableCell>)}
          {[...canadaYearList, "Total"].map((canadaYear) => <TableCell key={`${COUNTRY_CANADA}-${canadaYear}-header`} backgroundColor={canadaYear === "Total" ? "secondary" : "primary"}>{canadaYear}</TableCell>)}
          {[...canadaYearList, "Total"].map((canadaYear) => <TableCell key={`grand-total-${canadaYear}-header`} backgroundColor={canadaYear === "Total" ? "secondary" : "primary"}>{canadaYear}</TableCell>)}
        </TableRow>
        {mheTypeList.map(mhe => { return generateRowsByMHE(mhe) })}
        {addGrandTotalRow()}
      </Table>
    </Column>
}