import React from "react";
import Homepage from "../home";
import DashboardPage from "../dashboard/DashboardPage";
import ProjectsPage from "../activeProjects/ProjectsPage";
import CopyProjectsPage from "../copyProjects/CopyProjectsPage";
import SaveMultipleProjectsPage from "../saveMultipleProjects/SaveMultipleProjectsPage";
import ProjectDetailsPage from "../projectDetails/ProjectDetailsPage";
import BRSPage from "../BRS/BRSPage";
import BRSDetailsPage from "../BRS/BRSDetailsPage";
import ChangeHistoryPage from "../changeHistory/ChangeHistoryPage";
import ChangeHistoryDetailsPage from "../changeHistory/changeHistoryDetails/ChangeHistoryDetailsPage";
import TemplatesPage from "../templates/TemplatesPage";
import {
  HOME_SIDE_MENU_LABEL,
  DASHBOARD_SIDE_MENU_LABEL,
  PROJECTS_SIDE_MENU_LABEL,
  BRS_SIDE_MENU_LABEL,
  CHANGE_HISTORY_TAB_NAME,
} from "../../constants";
import { ProjectContextProvider } from "../../store/project-store";
import { ProjectDetailsContextProvider } from "../../store/project-details-store";
import { ChangeHistoryContextProvider } from "../../store/change-history-store";
import { BRSDetailsContextProvider } from "../../store/brs-store";
export interface RouteType {
  path: string;
  name: string;
  Component: any;
  showInSideMenu: boolean;
  ContextProvider: any;
}

const routes: Array<RouteType> = [
  {
    path: "/",
    name: HOME_SIDE_MENU_LABEL,
    showInSideMenu: true,
    Component: Homepage,
    ContextProvider: ProjectContextProvider,
  },
  {
    path: "/dashboard",
    name: DASHBOARD_SIDE_MENU_LABEL,
    showInSideMenu: true,
    Component: DashboardPage,
    ContextProvider: null,
  },
  {
    path: "/active-projects",
    name: PROJECTS_SIDE_MENU_LABEL,
    showInSideMenu: true,
    Component: ProjectsPage,
    ContextProvider: ProjectContextProvider,
  },
  {
    path: "/active-projects/copy-projects/",
    name: "Copy Projects",
    showInSideMenu: false,
    Component: CopyProjectsPage,
    ContextProvider: ProjectContextProvider,
  },
  {
    path: "/active-projects/save-multiple-projects/",
    name: "Save To Multiple Projects",
    showInSideMenu: false,
    Component: SaveMultipleProjectsPage,
    ContextProvider: ProjectContextProvider,
  },
  {
    path: "/active-projects/project-details/:siteId",
    name: "Project Details",
    showInSideMenu: false,
    Component: ProjectDetailsPage,
    ContextProvider: ProjectDetailsContextProvider,
  },
  {
    path: "/BRS",
    name: BRS_SIDE_MENU_LABEL,
    showInSideMenu: true,
    Component: BRSPage,
    ContextProvider: null,
  },
  {
    path: "/BRS/:siteId",
    name: "BRS Details",
    showInSideMenu: false,
    Component: BRSDetailsPage,
    ContextProvider: BRSDetailsContextProvider,
  },
  {
    path: "/change-history",
    name: CHANGE_HISTORY_TAB_NAME,
    showInSideMenu: true,
    Component: ChangeHistoryPage,
    ContextProvider: ChangeHistoryContextProvider,
  },
  {
    path: "/change-history/:changeHistoryId",
    name: "Change History Details",
    showInSideMenu: false,
    Component: ChangeHistoryDetailsPage,
    ContextProvider: null,
  },
  /*{ path: "/templates", name: "Templates", showInSideMenu: true, Component: TemplatesPage, ContextProvider: null }*/
];

export default routes;
