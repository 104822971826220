import React, { useContext, useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import ProjectFilterByColumnGroup from "../activeProjects/common/ProjectFilterByColumnGroup";
import ProjectsTable from "../activeProjects/ProjectsTable";
import {
  STATUS_COLUMN_NAME,
  SC_TYPE_COLUMN_NAME,
  REGION_COLUMN_NAME,
  CLUSTER_COLUMN_NAME,
  LMSC_COLUMN_NAME,
  OPS_REGION,
  HOMEPAGE_TABLE_TYPE,
  POR_SUPER_ADMIN,
} from "../../constants";
import PORContext, { usePORContext } from "../../store/por-context";
import { useProjectsContext } from "../../store/project-store";
import Box from "@amzn/meridian/box";
import Input from "@amzn/meridian/input";
import searchTokens from "@amzn/meridian-tokens/base/icon/search";
import { colorGray50 } from "@amzn/meridian-tokens/base/color";
import MapHome from "./map/MapHome";
import PorApiFactory from "../../por-api/PlanOfRecordApiFactory";
import Loader from "@amzn/meridian/loader";
import { Logger } from "@amzn/kepler-katal-logger";
import InfoToaster from "../toaster/InfoToaster";

export default function Homepage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeHistoryCountLoading, setIsChangeHistoryCountLoading] =
    useState(false);
  const [toasterMessage, setToasterMessage] = useState("");

  const { projectTableData, user, onOpenToastWithoutTimeout } = usePORContext();
  const {
    columnFilters,
    setColumnFilters,
    searchProjectsFilter,
    setSearchProjectsFilter,
  } = useProjectsContext();
  const mapContext = useContext(PORContext);
  const { mapDetails, setMapDetails } = mapContext;
  const PorApi = PorApiFactory();

  // Load Map data
  useEffect(() => {
    // Only load Map if it  is not already defined
    if (mapDetails.length == 0) {
      setIsLoading(true);
      (async () => {
        try {
          let mapAPIResponse = (await PorApi.getMapDetails()).data;
          mapAPIResponse = mapAPIResponse.filter(
            (site) => site["longitude"] != 0
          );
          if (mapAPIResponse && mapAPIResponse.length) {
            setMapDetails(mapAPIResponse);
          }
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
        } finally {
          setIsLoading(false);
        }
      })();
    }

    // clean up states when Home page unmount
    return () => {
      setIsLoading(false);
      setColumnFilters({});
      setSearchProjectsFilter("");
    };
  }, []);

  // fetch data for change history status=pending counts
  useEffect(() => {
    if (user === POR_SUPER_ADMIN) {
      (async () => {
        setIsChangeHistoryCountLoading(true);
        try {
          let changeHistoryCountResponse = (
            await PorApi.getChangeHistoryCount()
          ).data;
          if (
            parseInt(changeHistoryCountResponse.changeHistoryCount as string) >
            0
          ) {
            onOpenToastWithoutTimeout();
            setToasterMessage(`You have ${changeHistoryCountResponse.changeHistoryCount} pending approval(s) on
            the Change History page. Please review and take appropriate action.`);
          }
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
        } finally {
          setIsChangeHistoryCountLoading(false);
        }
      })();
    }
  }, [user]);

  return (
    <Box width="100%">
      <Column width="100%" spacingInset="400" className="homeStyling">
        <Row width="100%" widths={["70%", "30%"]} alignmentVertical="bottom">
          <InfoToaster
            message={toasterMessage}
            isToasterInfoLoading={isChangeHistoryCountLoading}
          />
          <ProjectFilterByColumnGroup
            filteringSourceData={projectTableData}
            filterByColumns={[
              STATUS_COLUMN_NAME,
              SC_TYPE_COLUMN_NAME,
              REGION_COLUMN_NAME,
              CLUSTER_COLUMN_NAME,
              LMSC_COLUMN_NAME,
              OPS_REGION,
            ]}
            sourceDataFilters={columnFilters}
            setSourceDataFilters={setColumnFilters}
            backgroundColor={colorGray50}
            hasBorder={false}
          />
          <Input
            type="search"
            size="medium"
            placeholder="Search By Name"
            value={searchProjectsFilter}
            onChange={setSearchProjectsFilter}
            suffixIconTokens={searchTokens}
          />
        </Row>
        <Row width="100%" widths={["100%"]}>
          {isLoading && <Loader type="linear" />}
        </Row>
        <Row width="100%">
          <br />
          <Box type="outline" width="100%">
            <MapHome mapDetails={mapDetails} />
          </Box>
        </Row>
      </Column>
      <Box
        type="outline"
        overflowX="scroll"
        className="homeTableStyling"
        spacingInset="400"
      >
        <ProjectsTable
          isProjectSelectable={false}
          tableType={HOMEPAGE_TABLE_TYPE}
        />
      </Box>
    </Box>
  );
}
