// Generic
export const SUCCESS_RESPONSE_STATUS = "Success";
export const POR_CUSTOMER = "Customer";
export const POR_ADMIN = "Admin";
export const POR_SUPER_ADMIN = "Super Admin";
export const HOME_SIDE_MENU_LABEL = "Home";
export const DASHBOARD_SIDE_MENU_LABEL = "Dashboard";
export const PROJECTS_SIDE_MENU_LABEL = "Projects";
export const BRS_SIDE_MENU_LABEL = "BRS";
export const DOWNLOAD_PROJECT_FEATURE_NAME = "downloadProjects";
export const DOWNLOAD_BRS_FEATURE_NAME = "downloadBrs";
export const DOWNLOAD_MHE_TABLE_FEATURE_NAME = "downloadMhe";
export const DOWNLOAD_CAPACITY_YEAR_FEATURE_NAME = "downloadCapacityYear";
export const DOWNLOAD_CHANGE_HISTORY_FEATURE_NAME = "downloadChangeHistory";
export const USER_PERMISSION_ERROR_MESSAGE = "Error trying to get user permission info.";
export const DEFAULT_TOASTER_TIMEOUT = 3000;
export const ALERT_AUTHENTICATION_MESSAGE =
  "You are not authorized to perform this operation. Please request access.";
export const COMPACT_VIEW = "compactView";
export const DETAILED_VIEW = "detailedView";
export const PROJECT_ID = "projectId";

// Home Page
export const POR_WIKI_LINK = "https://w.amazon.com/bin/view/NASC/ATSquad/Application/Automated_POR/";
export const OPEN_ISSUE_URL = "https://issues.amazon.com/issues/create?assignedFolder=eb4c19bc-ef39-42a1-a95d-31217408c38b";
export const FEATURE_REQUEST_URL = "https://form.asana.com/?k=WMbLtMLiv02N9OlbAxL0MA&d=8442528107068&rr=761987";

// Dashboard Page
export const UPCOMING_TAB_NAME = "Upcoming Projects";
export const UPCOMING_TAB_ID = "upcomingProjects";
export const UPCOMING_REGION_TAB_NAME = "Upcoming by Region";
export const UPCOMING_REGION_TAB_ID = "upcomingRegionProjects";
export const UPCOMING_MHE_TAB_NAME = "Upcoming by MHE Template";
export const UPCOMING_MHE_TAB_ID = "upcomingMHEProjects";
export const ACTIVE_REGION_TAB_NAME = "Active by Region";
export const ACTIVE_REGION_TAB_ID = "activeRegionProjects";
export const ACTIVE_MHE_TAB_NAME = "Active by MHE Template";
export const ACTIVE_MHE_TAB_ID = "activeMHEProjects";
export const CAPACITY_YEAR_TAB_NAME = "Exit Count by Capacity Year";
export const CAPACITY_YEAR_TAB_ID = "capacityYear";
export const CURRENT_ACTIVE_SITES_TAB_NAME = "Current Active Sites";
export const CURRENT_ACTIVE_SITES_TAB_ID = "currentActiveSites";
export const QUICK_SIGHT_TAB_NAME = "QuickSight";
export const QUICK_SIGHT_TAB_ID = "quickSight";
export const DEFAULT_END_YEAR_LAP = 3;
export const EXPANSION = "expansion";
export const RETROFIT = "retrofits";
export const LAUNCH = "launch";
export const LAUNCH_LTL = "launch - LTL";
export const BUILDING_CONSTRUCTION = "Building Construction Only";
export const LAUNCH_TYPES_LIST = ["expansion", "retrofits", "launchLTL", "launch","buildingConstructionOnly" ];
export const LAUNCH_TYPES_LIST_FILTER = ["Expansion", "Retrofits", "Launch - LTL", "Launch", "Building Construction Only"];
export const EXISTING_SITE_KEY = "existingSite";
export const EXISTING_SITE_HEADER = "Existing Site";
export const COUNTRY_US = "us";
export const COUNTRY_CANADA = "canada";
export const COUNTRY_TOTAL = "Total";
export const CAPACITY_YEAR_COUNTRY_TITLES = [COUNTRY_US, COUNTRY_CANADA, COUNTRY_TOTAL];
export const CAPACITY_YEAR_SITE_COUNT = "Site Count";
export const CAPACITY_YEAR_MHE_CAPACITY = "MHE Capacity";
export const CAPACITY_YEAR_XD_CAPACITY = "XD Capacity";
export const CAPACITY_YEAR_COUNTRY_NESTED_TITLES = [CAPACITY_YEAR_SITE_COUNT, CAPACITY_YEAR_MHE_CAPACITY, CAPACITY_YEAR_XD_CAPACITY];
export const LAUNCH_MAPPING = {
  "launch": "launch",
  "expansion": "expansion",
  "retrofits": "retrofits",
  "launch - LTL": "launchLTL",
  "Building Construction Only": "buildingConstructionOnly"
};
export const CAPACITY_YEAR_1P = "1P";
export const CAPACITY_YEAR_3P = "3P";
export const CAPACITY_YEAR_NESTED_TITLE_TO_FIELDNAME = {
  "Site Count": "siteCount",
  "MHE Capacity": "mheCapacity",
  "XD Capacity": "xdCapacity"
};
export const COUNTRY_FIELD_TO_TITLE = {
  [COUNTRY_US]: "US",
  [COUNTRY_CANADA]: "Canada",
  [COUNTRY_TOTAL]: "Total"
};
export const QUICK_SIGHT_LINK = "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/5817c1df-b3ff-455c-afa9-3812a6a657a9/sheets/5817c1df-b3ff-455c-afa9-3812a6a657a9_e736d42a-5f22-4edb-a62e-823ad71c588c";

// Active Projects Page
export const UNFORMATTED_FIELDS = ["Latitude", "Longitude", "Ops Sort Length", "Original Launch Year", "Capacity Year"];
export const DEFAULT_ITEMS_PER_PAGE = 20;
export const MAX_CONTENT_LENGTH = 20;
export const SITE_COLUMN_NAME = "Site";
export const POR_CODE_COLUMN_NAME = "POR Code";
export const MHE_TYPE_COLUMN_NAME = "MHE Type";
export const MHE_COLUMN_NAME = "MHE";
export const STATUS_COLUMN_NAME = "Status";
export const PROGRAM_TYPE_COLUMN_NAME = "Program Type";
export const PROGRAM_COLUMN_NAME = "Program";
export const CAPACITY_YEAR_COLUMN_NAME = "Capacity Year";
export const CHGHISTORY_DATE_COLUMN_NAME = "createDate";
export const LAUNCHES_COLUMN_NAME = "Launches/Retrofits";
export const COUNTRY_COLUMN_NAME = "Country";
export const STATUS_VALUE_LIST = [
  "Active",
  "Inactive",
  "Upcoming",
  "Winterbear"
];
export const STATUS_ALL = "All";
export const STATUS_FILTER_LIST = [STATUS_ALL, ...STATUS_VALUE_LIST];
export const SC_TYPE_VALUE_LIST = ["3P", "AR", "Blank", "Auto", "Manual"];
export const SC_TYPE_ALL = 'All';
export const SC_TYPE_COLUMN_NAME = "SC Type";
export const CLUSTER_COLUMN_NAME = "Cluster";
export const REGION_COLUMN_NAME = "Region";
export const LMSC_COLUMN_NAME= "LMSC/SC-SC";
export const TIMELINE_COLUMN_NAME= "Timeline";
export const OPS_REGION = "Ops Region";
export const SMALL_CELL_WIDTH = "8vw";
export const LARGE_CELL_WIDTH = "10vw";
export const DEFAULT_API_CALL_LIMIT = 200;
export const NEW_SITE_ID = "create";
export const STICKY_COLUMN_NAME = ["Site"];  // Todo: deprecating
export const PRIORITY_COLUMNS = ["Country", "Original Launch Year", CAPACITY_YEAR_COLUMN_NAME, REGION_COLUMN_NAME, "POR Code", CLUSTER_COLUMN_NAME, SC_TYPE_COLUMN_NAME, "MHE Type", "Planning Cycle", "MHE"];  // Todo: deprecating
export const FILTER_PRIORITY_COLUMNS = [CAPACITY_YEAR_COLUMN_NAME, STATUS_COLUMN_NAME, REGION_COLUMN_NAME, CLUSTER_COLUMN_NAME, LAUNCHES_COLUMN_NAME, PROGRAM_COLUMN_NAME, PROGRAM_TYPE_COLUMN_NAME, TIMELINE_COLUMN_NAME, LMSC_COLUMN_NAME];
export const FIXED_TABLE_COLUMNS = [SITE_COLUMN_NAME, REGION_COLUMN_NAME, CLUSTER_COLUMN_NAME, LMSC_COLUMN_NAME, "Original Launch Year", CAPACITY_YEAR_COLUMN_NAME, STATUS_COLUMN_NAME, SC_TYPE_COLUMN_NAME, PROGRAM_TYPE_COLUMN_NAME, TIMELINE_COLUMN_NAME];
export const HOMEPAGE_TABLE_COLUMNS = [SITE_COLUMN_NAME, STATUS_COLUMN_NAME, REGION_COLUMN_NAME, CLUSTER_COLUMN_NAME, 'Original Launch Year', SC_TYPE_COLUMN_NAME, 'MHE', 'Ops Rated Hourly Flow', 'Ops Sort Length', 'Daily Ops Rated Capacity', 'Daily Non-Con Capacity', 'Daily XD  Ops rated Capacity', 'Total Ops Rated Bldng Flow', 'Yard Total Flow', LMSC_COLUMN_NAME, PROJECT_ID];
export const PROJECTS_COLUMN_HEADER_TOOLTIP_MAP = {
  "First Possible EB CPT": "The time mentioned is in the local timezone"
};

// Table Types
export const HOMEPAGE_TABLE_TYPE = "HOMEPAGE";
export const PROJECTPAGE_TABLE_TYPE = "PROJECT";
export const SAVE_MULTIPLE_PROJECTS_TABLE_TYPE = "SAVE_MULTIPLE_PROJECTS_TABLE";
export const CHANGE_HISTORY_TABLE_TYPE = "CHANGE_HISTORY";

export const MAX_NUMBER_OF_FILTERS = 10;
export const NUMBER_OF_FILTER_TAG_ROWS = 2;
export const PROJECTS_TABLE_DEFAULT_FILTER = {
  [CAPACITY_YEAR_COLUMN_NAME]: [new Date().getFullYear().toString()],
  [STATUS_COLUMN_NAME]: ["Active"],
  [PROGRAM_TYPE_COLUMN_NAME]: ["Capacity"],
  [TIMELINE_COLUMN_NAME]: ["PEAK"],
};

// Project Details Page
export const PROJECT_DETAILS_TAB_NAME = "Project Details";
export const BRS_TAB_NAME = "BRS";
export const CHANGE_HISTORY_TAB_NAME = "Change History";
export const SITE_DETAILS_FIELD_NAME = "siteDetails";
export const SITE_DETAILS_GROUP = [SITE_DETAILS_FIELD_NAME, "retrofitDetails"];
export const EQUIPMENT_GROUP = ["equipmentDescription"];
export const ATTRIBUTES_GROUP = ["buildingAttributes", "productivity", "additionalDetails"];
export const FIELD_GROUP_NAME_TO_TITLE = {
  "siteDetails": "Site Details",
  "retrofitDetails": "Retrofit Details",
  "equipmentDescription": "Equipment Description",
  "buildingAttributes": "Building Attributes",
  "productivity": "Productivity",
  "additionalDetails": "Additional Details"
};
export const TEXT_TYPE = "text";
export const TEXT_AREA_TYPE = "text area";
export const NUMERIC_TYPE = "numeric";
export const LINK_TYPE = "link";
export const DROPDOWN_TYPE = "dropdown";
export const DATE_TYPE = "date";
export const INPUT_WIDTH = "10.5vw";
export const INPUT_SUFFIX_WIDTH = "1vw";
export const INPUT_LINK_WIDTH = "20.5vw";
export const INPUT_TEAM_WIDTH = "21.75vw";
export const MODAL_WIDTH = "67vh";
export const MAX_LINKS_LIST_LENGTH = 10;
export const MAX_OPTIONS_LIST_LENGTH = 10;
export const DECIMAL_TYPE = "decimal";
export const BOOLEAN_TYPE = "boolean";
export const PROJECT_QUICK_ACCESS_FIELDS = [SITE_COLUMN_NAME, CAPACITY_YEAR_COLUMN_NAME, STATUS_COLUMN_NAME, SC_TYPE_COLUMN_NAME, PROGRAM_TYPE_COLUMN_NAME, PROGRAM_COLUMN_NAME, REGION_COLUMN_NAME, CLUSTER_COLUMN_NAME, TIMELINE_COLUMN_NAME, LAUNCHES_COLUMN_NAME];
export const PROJECT_QUICK_ACCESS_INITIAL_STATE = PROJECT_QUICK_ACCESS_FIELDS.reduce((prev, curr) => {  // initial state for project quick access
  prev[curr] = "";
  return prev;
}, {});  // initial state for project quick access
export const OTHER = "Other";
export const TEAMS = [OTHER];
export const REASONS_FOR_CHANGE = ["Cancelled", "Deferred", "Addition", "Template Modification", "SC Capacity",
  "SC Attribute", "Yard Capacity", "Equipment Modification", OTHER];

// BRS
export const STR_FORMAT_PLACEHOLDER = "%s";
export const DEFAULT_BRS_INPUT_WIDTH = "5.5vw";
export const BRS_INFO_COLUMN_NUMBER = 0;
export const CAPACITY_ASK_COLUMN_NUMBER_1 = 1;
export const CAPACITY_ASK_COLUMN_NUMBER_2 = 2;
export const BRS_SITE_FIELD_NAME = "Site Code";
export const NOTES1_FIELD_NAME = "Notes1";
export const NOTES2_FIELD_NAME = "Notes2";
export const NOTES_FIELDS = [NOTES1_FIELD_NAME, NOTES2_FIELD_NAME];
export const DEFAULT_BRS_ITEMS_PER_PAGE = 9;
export const BRS_TABLE_TITLE_MAP = {
  "site": "Site"
};
export const ITEMS_PER_PAGE_LIST = [DEFAULT_BRS_ITEMS_PER_PAGE, 20, 30, 40];

export const SC_TYPES_LIST = [
  "3P",
  "AR",
  "Auto",
  "Blank",
  "Manual"
];

export const REGION_LIST = [
  "Canada",
  "GreatLakes",
  "MidAtlantic",
  "Midwest",
  "NorthEast",
  "NorthWest",
  "SouthEast",
  "SouthWest",
  "Texas"
];

export const CLUSTER_LIST = [
  "Akron, OH",
  "Albuquerque, NM",
  "Atlanta, GA",
  "Augusta, SC",
  "Boise, ID",
  "Boston, MA",
  "Boston, MA South",
  "Buffalo, NY",
  "Calgary, AB",
  "Charlotte, NC",
  "Chicago, IL",
  "Cincinnati, OH",
  "Cleveland, OH",
  "Colorado Springs, CO",
  "Columbus, OH",
  "Connecticut South",
  "Dallas, TX",
  "Denver, CO",
  "Des Moines, IA",
  "Detroit, MI",
  "Fort Meyers, FL",
  "Grand Rapids, MI",
  "Harrisburg, PA",
  "Houston, TX",
  "Indianapolis, IN",
  "Jacksonville, FL",
  "Kansas City, MO",
  "Las Vegas, NV",
  "Los Angeles, CA",
  "Louisville, KY",
  "Lubbock, TX",
  "MIA South",
  "Memphis, TN",
  "Miami, FL",
  "Milwaukee, WI",
  "Minneapolis, MN",
  "Montgomery, AL",
  "Montreal, QC",
  "Nashville, TN",
  "New York, NY",
  "Oklahoma City, OK",
  "Omaha, NE",
  "Philadelphia, PA",
  "Phoenix, AZ",
  "Pittsburgh, PA",
  "Portland, OR",
  "Raleigh, NC",
  "Salt Lake City, UT",
  "San Antonio, TX",
  "San Diego, CA",
  "San Francisco, CA",
  "Seattle, WA",
  "St. Louis, MO",
  "Tampa, FL",
  "Toronto, ON",
  "Tuscon, AZ",
  "Vancouver, BC",
  "Virginia Beach, VA",
  "Washington, DC",
  "Washington, DC West"
];

export const LMSC_LIST = [
  "LMSC",
  "SC-SC",
  "SC-SC Hybrid"
];

export const CARD_BUCKET_DELIMITER = "$";
export const MHE_COLUMNS = ["AR 18K", "Crossbelt 18K", "Shoe Sorter 15K", "Sort Slide 12K", "Mechlite 12K", "LS 7K", "Sort Slide 9K", "Shoe Sorter 9K", "Mechlite 9K", "Mechlite 6K", "Mechlite 4K", "Mechlite 3K", "Sort Slide 6K", "Smalls 6K", "Intralox", "Linear 6K","HIPPO 15K", "HIPPO 14K", "HIPPO 9K", "ADTZ", "Crossbelt 25K", "ADTZ 9K", "ADTZ 12K", "Non-Con Mechlite", "Non-Con Intralox", "Non-Con Geniflex"];

export const SIDE_MENU_HYPERLINKS = {
  "TESLA": "https://s3uploader.ats.amazon.dev/tesla",
  "Capacity Change Request": "https://approvals.amazon.com/Template/Details/99505"
}


export const MAP_COLOR_DATA =  [
  { label: "Texas", color: "#00A8E1", 'key': '1', colorArray:[0, 168, 225] },
  { label: "NorthWest", color: "#FF6138", 'key': '2', colorArray:[255, 97, 56] },
  { label: "SouthWest", color: "#697576", 'key': '3', colorArray:[105, 117, 118] },
  { label: "SouthEast", color: "#98C91E", 'key': '4', colorArray:[152, 201, 30] },
  { label: "GreatLakes", color: "#FAD000", 'key': '5', colorArray:[250, 208, 0] },
  { label: "Midwest", color: "#CC0C39", 'key': '6', colorArray:[204, 12, 57] },
  { label: "NorthEast", color: "#1B5F5B", 'key': '7', colorArray:[27, 95, 91] },
  { label: "MidAtlantic", color: "#EB64D7", 'key': '8', colorArray:[235, 100, 215] },
  { label: "Canada", color: "#A45C0A", 'key': '9', colorArray:[164, 92, 10] },
];

// Copy Projects Page
export const START_DATE_COLUMN_NAME = "Start Date";
export const END_DATE_COLUMN_NAME = "End Date";
export const INPUT_FIELDS_TO_COPY = [
  {
    fieldName: CAPACITY_YEAR_COLUMN_NAME,
    isRequired: true,
  },
  {
    fieldName: STATUS_COLUMN_NAME,
    isRequired: true,
  },
  {
    fieldName: TIMELINE_COLUMN_NAME,
    isRequired: true,
  },
  {
    fieldName: PROGRAM_TYPE_COLUMN_NAME,
    isRequired: true,
  },
  {
    fieldName: PROGRAM_COLUMN_NAME,
    isRequired: false,
  },
  {
    fieldName: START_DATE_COLUMN_NAME,
    isRequired: false,
  },
  {
    fieldName: END_DATE_COLUMN_NAME,
    isRequired: false,
  },
];
export const INPUT_FIELDS_TO_COPY_STATES = INPUT_FIELDS_TO_COPY.reduce((prev, curr) => {
  prev[curr.fieldName] = "";
  return prev;
}, {});  // initial state for copy projects change fields

