import React, {useState} from "react";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Textarea from "@amzn/meridian/textarea";
import Select, {SelectOption} from "@amzn/meridian/select";
import {
  FIELD_GROUP_NAME_TO_TITLE,
  MAX_LINKS_LIST_LENGTH,
  MAX_OPTIONS_LIST_LENGTH,
  INPUT_WIDTH,
  INPUT_LINK_WIDTH,
  MODAL_WIDTH,
  TEXT_TYPE,
  DECIMAL_TYPE,
  NUMERIC_TYPE,
  BOOLEAN_TYPE,
  DROPDOWN_TYPE,
  TEXT_AREA_TYPE, TEAMS, OTHER, REASONS_FOR_CHANGE
} from "../../constants";
import {prepareLinks} from "../modal/ModalUtils";

export default function AdditionalAttributesModal({ open, onClose, addAttributeHandler }) {
  const [categoryValue, setCategoryValue] = useState();
  const [attributeName, setAttributeName] = useState("");
  const [attributeType, setAttributeType] = useState("Numerical");
  const [defaultValue, setDefaultValue] = useState("");
  const [linksList, setLinksList] = useState(["",""]);
  const [optionsList, setOptionsList] = useState(["","",""]);
  const [reason, setReason] = useState("");
  const [hasReasonError, setHasReasonError] = useState(false);
  const [hasTeamError, setHasTeamError] = useState(false);
  const [reasonErrorMessage, setReasonErrorMessage] = useState("");
  const [teamErrorMessage, setTeamErrorMessage] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [otherTeam, setOtherTeam] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [team, setTeam] = useState("");

  const handleOptionChange = (value,index) => {
    const list = [...optionsList];
    list[index] = value;
    setOptionsList(list);
  };

  const addOptionHandler = () => {
    setOptionsList([...optionsList, ""]);
  }

  const removeOptionHandler = (index) => {
    const list = [...optionsList];
    list.splice(index, 1);
    setOptionsList(list);
  }

  const handleLinkChange = (value, index) => {
    const list = [...linksList];
    list[index] = value;
    setLinksList(list);
  };

  const addLinkHandler = () => {
    setLinksList([...linksList, ""]);
  }

  const removeLinkHandler = (index) => {
    const list = [...linksList];
    list.splice(index, 1);
    setLinksList(list);
  }

  const attemptSubmitSuccess = () => {
    if (!reason || (reason == OTHER && !otherReason)) {
      setHasReasonError(true);
      setReasonErrorMessage("Reason for change is required.");
    } else {
      setHasReasonError(false);
      setReasonErrorMessage("");
    }
    if (!team || (team == OTHER && !otherTeam)) {
      setHasTeamError(true);
      setTeamErrorMessage("Team field is required.");
    } else {
      setHasTeamError(false);
      setTeamErrorMessage("");
    }
    return ((team && (team != OTHER || otherTeam)) && (reason && (reason != OTHER || otherReason)))
  }

  const saveChangeHandler = () => {
    if (attemptSubmitSuccess()) {

      return addAttributeHandler(attributeName, defaultValue, attributeType, categoryValue, optionsList, prepareLinks(linksList),
          (reason == OTHER) ? otherReason : reason, (team == OTHER) ? otherTeam : team, additionalNotes);
    }
  }

  return (
    <Modal
      open={open}
      title=""
      onClose={onClose}
      scrollContainer="viewport"
      closeLabel="Cancel"
    >
      <Column spacing="100">
        <Heading level={3}>Additional Attributes</Heading>
        <Text>
          Enter attribute details and reasoning.
          <br/>Hit cancel or click outside this modal to cancel action.
        </Text>
        <Row widths={["50%","40%"]}>
          <Heading level={5}>Category</Heading>         
          <Select 
            size="small" 
            value={categoryValue}
            onChange={setCategoryValue}
            placeholder="Select or Type"
           
          >
            {Object.entries(FIELD_GROUP_NAME_TO_TITLE).map( ([fieldkey, fieldValue]) => (
            <SelectOption key={`${fieldValue}-option`} value={fieldkey} label={fieldValue} />)
            )}
          </Select>       
        </Row>
        <Row widths={["50%","40%"]}>
          <Heading level={5}>Attribute Name</Heading>
          <Input value={attributeName} placeholder="Office Sq Foot" size="small" onChange={setAttributeName}/>
        </Row>
        <Row widths={["50%","40%"]}>
          <Heading level={5}>Attribute Type</Heading>         
          <Select 
              size="small" 
              value={attributeType}
              onChange={setAttributeType}
              placeholder="numeric"
             
            >
              <SelectOption value={NUMERIC_TYPE} label={NUMERIC_TYPE} />
              <SelectOption value={TEXT_TYPE} label={TEXT_TYPE} />
              <SelectOption value={TEXT_AREA_TYPE} label={TEXT_AREA_TYPE} />
              <SelectOption value={DECIMAL_TYPE} label={DECIMAL_TYPE} />
              <SelectOption value={BOOLEAN_TYPE} label={BOOLEAN_TYPE} />
              <SelectOption value={DROPDOWN_TYPE} label={DROPDOWN_TYPE} />
            </Select>         
        </Row>
        {(() => {
          if(attributeType === TEXT_TYPE)
          {return(
            <Row widths={["50%","40%"]}>
                <Heading level={5}>Default Value</Heading>
                <Input value={defaultValue} size="small" placeholder="Text" onChange={setDefaultValue}/>
            </Row> 
          )
          }
          else if(attributeType === TEXT_AREA_TYPE)
          {return(
            <Row widths={["50%","40%"]}>
                <Heading level={5}>Default Value</Heading>
                <Textarea value={defaultValue} size="small" placeholder="Text Area" onChange={setDefaultValue}/>
            </Row> 
          )
          }
          else if(attributeType === DECIMAL_TYPE)
          {return(
            <Row widths={["50%","40%"]}>
                <Heading level={5}>Default Value</Heading>
                <Input value={defaultValue} size="small" type="number" placeholder="Decimal" onChange={setDefaultValue}/>
            </Row> 
          )
          }
          else if(attributeType === BOOLEAN_TYPE)
          {return(
            <Row widths={["50%","40%"]}>
              <Heading level={5}>Default Value</Heading>
              <Select 
                size="small" 
                value={defaultValue}
                onChange={setDefaultValue}
                placeholder="Select Yes/No"
              >
                <SelectOption value="Yes" label="Yes" />
                <SelectOption value="No" label="No" />
              </Select>
            </Row> 
          )
          }
          else if(attributeType === DROPDOWN_TYPE)
          {return(
            <Column spacing="small">
            <Heading level={5}>Dropdown Options</Heading>
            {optionsList.map((singleOption, index) => (
              <Row key={`${index}-options`} alignmentHorizontal="end">
                <Input size="small" name="option" value={singleOption} placeholder={"option " + (index+1)} onChange={value => handleOptionChange(value, index)}/>     
                {optionsList.length - 1 === index && optionsList.length < MAX_OPTIONS_LIST_LENGTH && (
                <Button size="small" onClick={addOptionHandler} type="secondary">+</Button>
                )}                
                {optionsList.length -1 !== index && (
                <Button size="small" onClick={() => removeOptionHandler(index)} type="secondary"><Text color="error">x</Text></Button>
                )}
              </Row>             
            ))}
            </Column>
            )
          }
          else
          {return(
            <Row widths={["50%","40%"]}>            
              <Heading level={5}>Default Value</Heading>
              <Input value={defaultValue} size="small" type="number" placeholder="2500" onChange={setDefaultValue}/>          
            </Row> 
          )
          }
        })()}
        <Row key="team" alignmentHorizontal="end">
          <Heading level={5}>Team</Heading>
          <Select
              size="small"
              placeholder="Select Team"
              onChange={setTeam}
              value={team}
              width={INPUT_LINK_WIDTH}
              error={hasTeamError}
              errorMessage={teamErrorMessage}
          >
            {TEAMS.map((team) => (
                <SelectOption
                    key={team}
                    value={team}
                    label={team}
                />
            ))}
          </Select>
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>
        {(team == OTHER) && <Row alignmentHorizontal="end">
          <Input
              size="small"
              name="other"
              type="text"
              id="other"
              width={INPUT_LINK_WIDTH}
              value={otherTeam}
              placeholder="Specify Team"
              onChange={setOtherTeam}
              error={hasTeamError}
              errorMessage={teamErrorMessage}
          />
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>}
        {linksList.map((singleLink, index) => (
        <Row key={`${index}-links`} alignmentHorizontal="end">
          {index === 0 && (
            <Heading level={5}>Approval Link</Heading>
          )}
          <Input size="small" name="links" type="text" id="links" value={singleLink} width={INPUT_LINK_WIDTH} placeholder="http://..." onChange={value => handleLinkChange(value, index)}/>
          {linksList.length - 1 === index && linksList.length < MAX_LINKS_LIST_LENGTH && (
          <Button size="small" onClick={addLinkHandler} type="secondary">+</Button>
          )}          
          {linksList.length -1 !== index && (
          <Button size="small" onClick={() => removeLinkHandler(index)} type="secondary"><Text color="error">x</Text></Button>
          )}
        </Row>
        ))}
        <Row key="reason" alignmentHorizontal="end">
          <Heading level={5}>Change Reason</Heading>
          <Select
              size="small"
              placeholder="Select Reason"
              onChange={setReason}
              value={reason}
              width={INPUT_LINK_WIDTH}
              error={hasReasonError}
              errorMessage={reasonErrorMessage}
          >
            {REASONS_FOR_CHANGE.map((reason) => (
                <SelectOption
                    key={reason}
                    value={reason}
                    label={reason}
                />
            ))}
          </Select>
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>
        {(reason == OTHER) && <Row alignmentHorizontal="end">
          <Input
              size="small"
              name="otheReason"
              type="text"
              id="otherReason"
              width={INPUT_LINK_WIDTH}
              value={otherReason}
              placeholder="Specify Reason"
              onChange={setOtherReason}
              error={hasReasonError}
              errorMessage={reasonErrorMessage}
          />
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>}
        <Textarea
            value={additionalNotes}
            onChange={setAdditionalNotes}
            label="Enter any additional notes"
        />
        <Text color="error">
            Notice: Adding fields or deleting fields will be a global change.
        </Text>
      </Column>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button
            type="secondary"
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => saveChangeHandler()}
          >
            Add Attribute
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}