import "../styles.scss";
import Card, { CardHeader } from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import { useNavigate } from "react-router-dom";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";

export default function MapPopupDetails({
  mapDetails,
  clusterCount,
  closePopupHandler,
}) {
  const navigate = useNavigate();

  const phoneTool = `https://phonetool.amazon.com/search?search_query[filter_type]=All+fields&search_query[query]=${mapDetails.site}&sort_type=Manager&sort_order=DESC`;
  const viewSiteHandler = () => {
    navigate(`/active-projects/project-details/${mapDetails.projectId}`);
  };

  if (clusterCount) {
    return (
      <Card>
        <br />
        <hr />
        <br />
      </Card>
    );
  }

  return (
    <Card spacingInset="400">
      <Row alignmentHorizontal="justify">
        <CardHeader>
          <Heading level={2}>{mapDetails.site}</Heading>
        </CardHeader>
        <Button type="icon" onClick={closePopupHandler}>
          <Icon tokens={closeLargeTokens}>Settings</Icon>
        </Button>
      </Row>
      <Column>
        <Column spacing="200">
          <Text>{mapDetails.status}</Text>
          <Text>{mapDetails.mhe}</Text>
        </Column>
        <Column spacing="200">
          <Text>{mapDetails.address}</Text>
        </Column>
        <Column spacing="200">
          <Row alignmentHorizontal="justify">
            <Heading level={6}>Daily Ops Rated Capacity</Heading>
            <Text>{mapDetails.dailyOpsRatedCapacity?.toLocaleString()}</Text>
          </Row>
          <Row alignmentHorizontal="justify">
            <Heading level={6}>Daily XD Ops Rated Capacity</Heading>
            <Text>{mapDetails.dailyXDOpsRatedCapacity?.toLocaleString()}</Text>
          </Row>
        </Column>
        <Row alignmentHorizontal="justify">
          <Link href={phoneTool} rel="noreferrer" target="_blank">
            Site Contacts
          </Link>
          <Button type="primary" onClick={viewSiteHandler}>
            View Site Details
          </Button>
        </Row>
      </Column>
    </Card>
  );
}
