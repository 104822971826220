import React from "react";
import AppLayout from "@amzn/meridian/app-layout";
import PlanOfRecordMasthead from "../planOfRecordMasthead/PlanOfRecordMasthead";
import PlanOfRecordSideMenu from "../planOfRecordSideMenu/PlanOfRecordSideMenu";
import PlanOfRecordRouter from "../routes/PlanOfRecordRouter";

export default function PlanOfRecordLayout() {
  return (
    <AppLayout
      headerComponent={PlanOfRecordMasthead}
      sidebarComponent={PlanOfRecordSideMenu}
      backgroundColor="alternateSecondary"
    >
      <PlanOfRecordMasthead />
      <PlanOfRecordSideMenu />
      <PlanOfRecordRouter />
    </AppLayout>
  )
}