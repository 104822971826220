import { PROJECT_ID } from "../../constants";
import { ActiveSiteValues } from "../../por-api/generated-src";


/**
 * @summary util to construct save multiple projects change body
 * @param {Array<object>} [saveMultipleProjectsCandidates] projects candidates that waiting to submitted to updateActiveSites call
 * @param {object} [changeFieldsMap] details of projects that user changed in previous page
 * @param {object} [projectFieldsReverseMap] a reverse map to convert flattened projects to group structured
 * @return {Array<ActiveSiteValues>} group structured project list
 * **/
export const constructSaveMultipleProjectsChangeBody = (saveMultipleProjectsCandidates, changeFieldsMap, projectFieldsReverseMap) => {
  let projectsToSave: Array<ActiveSiteValues> = [];
  for (let eachProjectCandidate of saveMultipleProjectsCandidates) {
    let saveProjectCandidate = convertFlatProjectDataToGrouped(eachProjectCandidate, changeFieldsMap, projectFieldsReverseMap);
    saveProjectCandidate[PROJECT_ID] = eachProjectCandidate[PROJECT_ID];
    projectsToSave.push(saveProjectCandidate);
  }
  return projectsToSave;
}

/***
 * @summary helper method to convert a single projectData to grouped projectData, as needed by API call
 * @param {object} [referenceProjectCandidate] details of projects that only serves as reference
 * @param {object} [changeFieldsMap] details of projects that user changed in previous page
 * @param {object} [projectFieldsReverseMap] a reverse map to convert flattened projects to group structured
 * 
*/
export const convertFlatProjectDataToGrouped = (referenceProjectCandidate, changeFieldsMap, projectFieldsReverseMap) => {
  let projectCandidateResult = {};
  Object.keys(referenceProjectCandidate).forEach((projectField) => {
    if (projectField === PROJECT_ID) return;
    
    const groupFieldName = projectFieldsReverseMap[projectField];
    let savingValue = referenceProjectCandidate[projectField].value;
    if (changeFieldsMap.hasOwnProperty(projectField)) {
      savingValue = changeFieldsMap[projectField];
    }
    if (projectCandidateResult.hasOwnProperty(groupFieldName)) {
      projectCandidateResult[groupFieldName][projectField] = savingValue;
    } else {
      projectCandidateResult[groupFieldName] = { [projectField]: savingValue };
    }
  });
  return projectCandidateResult;
}