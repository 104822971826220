import React, { useContext } from "react";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import PORContext from "../../store/por-context";

export default function ToasterPORResponse({ message }) {
  const porContext = useContext(PORContext);
  const { toasts, onCloseToast } = porContext;

  return <React.Fragment>
    <Toaster toasts={toasts} onCloseToast={onCloseToast}>
      {toast => (
        <Alert toast={true} onClose={toast.onClose}
          type="success"
        >
          {`Successfully saved ${message}!`}
        </Alert>
      )}
    </Toaster>
  </React.Fragment>
}