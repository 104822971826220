import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";
import Textarea from "@amzn/meridian/textarea";
import {
  APPROVED_BY_FIELD_NAME,
  APPROVED_AT_FIELD_NAME,
  APPROVED_REASON_FIELD_NAME,
  APPROVAL_LINKS_FIELD_NAME,
  CHANGE_HISTORY_DETAIL_TITLE_MAP,
  REASON_FOR_DECISION_FIELD_NAME,
  STATUS_FIELD_NAME,
  PENDING_STATUS,
} from "../../../constants/ChangeHistoryLayout";
import { usePORContext } from "../../../store/por-context";
import isEmpty from "lodash/isEmpty";

export default function ChangeHistoryApprovalsColumn({
  changeHistoryDetailsData,
  approvalReason,
  setApprovalReason,
}) {
  const { canDelete } = usePORContext();

  useEffect(() => {
    if (isEmpty(changeHistoryDetailsData)) {
      return;
    }
    setApprovalReason(changeHistoryDetailsData[REASON_FOR_DECISION_FIELD_NAME]);
  }, [changeHistoryDetailsData]);

  return (
    changeHistoryDetailsData && (
      <Column width="100%">
        <Row alignmentVertical="top">
          <Column>
            <Text type="h100">
              {CHANGE_HISTORY_DETAIL_TITLE_MAP[APPROVED_BY_FIELD_NAME]}
            </Text>
            <Text>
              {changeHistoryDetailsData[APPROVED_BY_FIELD_NAME] || ""}
            </Text>
          </Column>
          <Column>
            <Text type="h100">
              {CHANGE_HISTORY_DETAIL_TITLE_MAP[APPROVED_AT_FIELD_NAME]}
            </Text>
            <Text>
              {changeHistoryDetailsData[APPROVED_AT_FIELD_NAME] || ""}
            </Text>
          </Column>
        </Row>
        <Row width="100%">
          <Column width="100%">
            <Text type="h100">
              {CHANGE_HISTORY_DETAIL_TITLE_MAP[APPROVED_REASON_FIELD_NAME]}
            </Text>
            <Textarea
              width="100%"
              value={approvalReason}
              onChange={setApprovalReason}
              disabled={
                !canDelete ||
                changeHistoryDetailsData[STATUS_FIELD_NAME] !== PENDING_STATUS
              }
              error={isEmpty(approvalReason)}
              errorMessage={
                isEmpty(approvalReason) && "Approval Details must be entered"
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Text type="h100">
              {CHANGE_HISTORY_DETAIL_TITLE_MAP[APPROVAL_LINKS_FIELD_NAME]}
            </Text>
            <Column spacing="200">
              {(changeHistoryDetailsData[APPROVAL_LINKS_FIELD_NAME] &&
                changeHistoryDetailsData[APPROVAL_LINKS_FIELD_NAME].length &&
                changeHistoryDetailsData[APPROVAL_LINKS_FIELD_NAME].map(
                  (approvalLink) => (
                    <Link
                      key={`${approvalLink}-link`}
                      href={approvalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {approvalLink}
                    </Link>
                  )
                )) ||
                ""}
            </Column>
          </Column>
        </Row>
      </Column>
    )
  );
}
