import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import TableCellWithLongValue from "../activeProjects/common/TableCellWithLongValue";
import Select, { SelectOption } from "@amzn/meridian/select";
import {
  MAX_CONTENT_LENGTH,
  LARGE_CELL_WIDTH,
  SMALL_CELL_WIDTH,
  PROJECT_ID,
  SITE_COLUMN_NAME,
  DEFAULT_ITEMS_PER_PAGE,
} from "../../constants";
import { formatProjectFields, getCalculatedValue, compareProjectsFn }  from "../activeProjects/ActiveProjectsUtils";
import { usePORContext } from "../../store/por-context";
import cloneDeep from "lodash/cloneDeep";

export default function CopyProjectsTable({ projectsCandidatesList, setProjectsCandidatesList }) {
  const { sortedColumns } = usePORContext();

  const [sortColumn, setSortColumn] = useState(SITE_COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState<TableSortDirection>("ascending");
  const [isCopyProjectsTableLoading, setIsCopyProjectsTableLoading] = useState(true);

  // sometimes loading 100+ projects is slowing down browser, adding this to make UX more smooth
  useEffect(() => {
    setIsCopyProjectsTableLoading(false);

    return () => {
      setIsCopyProjectsTableLoading(true);
    }
  }, [projectsCandidatesList]);

  const sortProjectsHandler = (event) => {
    const nextProjectsList = cloneDeep(projectsCandidatesList);
    setProjectsCandidatesList(
      nextProjectsList.sort(compareProjectsFn(event))
    );
    setSortColumn(event.sortColumn);
    setSortDirection(event.sortDirection);
  }

  /**
   * @summary get table cell component
   * @param {object} [projectData] project detail data
   * @param {string} [currentColumnField] the data field name of this table cell
   * **/
  const getTableCellWithData = (projectData, currentColumnField) => {
    const calculatedValue: any = getCalculatedValue(projectData, currentColumnField);
    const contentLength = typeof calculatedValue === "string" ? calculatedValue.length : MAX_CONTENT_LENGTH - 1;
    const contentDiv = contentLength >= MAX_CONTENT_LENGTH ?
      <TableCellWithLongValue fullContent={formatProjectFields(calculatedValue, currentColumnField)} /> :
      <div style={{ whiteSpace: "nowrap" }}>{formatProjectFields(calculatedValue, currentColumnField)}</div>;
    return (
      <TableCell
        key={`${projectData[PROJECT_ID]}-${currentColumnField}-data`}
      >
        {contentDiv}
      </TableCell>
    )
  };
  
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const numberOfPages = Math.ceil(projectsCandidatesList.length / itemsPerPage);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;

  return (
    <Column width="95%">
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        {isCopyProjectsTableLoading && <Loader type="linear" />}
        < Table
          headerRows={1}
          showDividers
          stickyHeaderColumn
          stickyHeaderRow
          headerColumns={1}
          layout="fixed"
          spacing="small"
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={sortProjectsHandler}
        >
          <TableRow>
            {sortedColumns.map((columnHeader) => (
              <TableCell
                key={`${columnHeader}-header`}
                width={columnHeader.length > MAX_CONTENT_LENGTH ? LARGE_CELL_WIDTH : SMALL_CELL_WIDTH}
                sortColumn={columnHeader}
              >
                {columnHeader}
              </TableCell>
            ))}
          </TableRow>
          {
            projectsCandidatesList
            .slice(firstVisibleIndex, lastVisibleIndex)
            .map((projectData) => (
              <TableRow key={`${projectData[PROJECT_ID]}-row`}>
                {sortedColumns.map((currentColumn) => getTableCellWithData(projectData, currentColumn))}
              </TableRow>
            ))
          }
        </Table>
      </div>
      <Row widths={["30%", "15%", "10%", "fill"]}>
        <Text>{`Showing ${firstVisibleIndex + 1} to ${lastVisibleIndex} of ${projectsCandidatesList.length} entries`}</Text>
        <Select
          value={itemsPerPage}
          onChange={setItemsPerPage}
          size="small"
        >
          <SelectOption value={10} label="10" />
          <SelectOption value={DEFAULT_ITEMS_PER_PAGE} label={DEFAULT_ITEMS_PER_PAGE} />
          <SelectOption value={30} label="30" />
        </Select>
        <Text>per page</Text>
        <div />
        <Pagination
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Row>
    </Column>
  )
}
