import { CAPACITY_YEAR_COLUMN_NAME, CLUSTER_COLUMN_NAME, LAUNCHES_COLUMN_NAME, PROGRAM_COLUMN_NAME, PROGRAM_TYPE_COLUMN_NAME, REGION_COLUMN_NAME, SC_TYPE_COLUMN_NAME, SITE_COLUMN_NAME, STATUS_COLUMN_NAME, TIMELINE_COLUMN_NAME } from ".";
/*
Layout field to use for ChangeHistoryStatusColumn, fieldName is in
<ChangeHistoryDetails> from "../../../por-api/generated-src";
*/

import { ChangeHistoryDetails, ApprovalDetails, ChangeHistoryFieldDiff } from "../por-api/generated-src";

const nameOfChangeHistoryDetails = <ChangeHistoryDetails>(name: keyof ChangeHistoryDetails) => name;
const nameOfApprovalDetails = <ApprovalDetails>(name: keyof ApprovalDetails) => name;
const nameOfChangeFieldDiff = <ChangeHistoryFieldDiff>(name: keyof ChangeHistoryFieldDiff) => name;

export const CH_CHANGE_FIELD = "changeField";
export const CH_NEW_SITE = "New Site";
export const CH_DELETED_SITE = "Deleted Site";
export const PENDING_STATUS = "Pending";
export const CH_ALL_SITES = "All";

// ChangeHistoryDetails
export const ID_FIELD_NAME = nameOfChangeHistoryDetails("id");
export const CHANGES_FIELD_NAME = nameOfChangeHistoryDetails("changes");
export const STATUS_FIELD_NAME = nameOfChangeHistoryDetails("status");
export const LOGIN_FIELD_NAME = nameOfChangeHistoryDetails("login");
export const CREATE_DATE_FIELD_NAME = nameOfChangeHistoryDetails("createDate");
export const FEATURE_TYPE_FIELD_NAME = nameOfChangeHistoryDetails("featureType");
export const SITE_FIELD_NAME = nameOfChangeHistoryDetails("affectSite");
export const MONTH_FIELD_NAME = nameOfChangeHistoryDetails("month");
export const QUARTER_FIELD_NAME = nameOfChangeHistoryDetails("quarter");
export const REASON_FOR_CHANGE_FIELD_NAME = nameOfChangeHistoryDetails("reasonForChange");
export const APPROVED_BY_FIELD_NAME = nameOfChangeHistoryDetails("approvedBy");
export const APPROVED_AT_FIELD_NAME = nameOfChangeHistoryDetails("approvedAt");
export const APPROVAL_LINKS_FIELD_NAME = nameOfChangeHistoryDetails("approvalLinks");
export const REASON_FOR_DECISION_FIELD_NAME = nameOfChangeHistoryDetails("reasonForDecision");
export const TEAM_NAME = nameOfChangeHistoryDetails("team");
export const ADDITIONAL_NOTES = nameOfChangeHistoryDetails("additionalNotes");

// ApprovalDetails
export const APPROVED_REASON_FIELD_NAME = nameOfApprovalDetails("approvalReason");

// Changes Diff
export const CHD_FIELD_NAME = nameOfChangeFieldDiff("fieldName");
export const CHD_PREVIOUS_VALUE = nameOfChangeFieldDiff("previousValue");
export const CHD_NEW_VALUE = nameOfChangeFieldDiff("newValue");
export const CHD_DEFAULT_COLUMN_SPAN = 2;

export const CHANGE_HISTORY_STATUS_COLUMN_LAYOUT = [
  [
    STATUS_FIELD_NAME, 
    LOGIN_FIELD_NAME, 
    CREATE_DATE_FIELD_NAME,
  ],
  [
    FEATURE_TYPE_FIELD_NAME,
    SITE_FIELD_NAME,
    CHANGES_FIELD_NAME,
    MONTH_FIELD_NAME,
    QUARTER_FIELD_NAME,
  ],
  [
    REASON_FOR_CHANGE_FIELD_NAME,
    TEAM_NAME
  ],
  [ADDITIONAL_NOTES]
];

export const CHGHISTORY_TABLE_TITLE_MAP = {
  "id": "",
  "status": "Status",
  "createDate": "Date",
  "login": "Login",
  "featureType": "Page",
  "affectSite": "Affects",
  "changeField": "Change Type",
  "fieldName": "Field Name",
  "previousValue": "Previous Value",
  "newValue": "New Value",
  "team": "Team",
  "reasonForChange": "Reason"
};

export const CHANGE_HISTORY_DETAIL_TITLE_MAP = {
  [STATUS_FIELD_NAME]: "Status",
  [LOGIN_FIELD_NAME]: "Login",
  [CREATE_DATE_FIELD_NAME]: "Date",
  [FEATURE_TYPE_FIELD_NAME]: "Type",
  [SITE_FIELD_NAME]: "Affects",
  [CHANGES_FIELD_NAME]: "Changes",
  [MONTH_FIELD_NAME]: "Month",
  [QUARTER_FIELD_NAME]: "Quarter",
  [REASON_FOR_CHANGE_FIELD_NAME]: "Reason",
  [APPROVED_BY_FIELD_NAME]: "Approved By",
  [APPROVED_AT_FIELD_NAME]: "Approved At",
  [APPROVED_REASON_FIELD_NAME]: "Approval Details",
  [APPROVAL_LINKS_FIELD_NAME]: "Approval Links",
  [TEAM_NAME]: "Team",
  [ADDITIONAL_NOTES]: "Additional Notes"
};

// Project Details Table in Change history
export const PROJECT_DETAIL_TABLE_COLUMNS = [
  SITE_COLUMN_NAME,
  CAPACITY_YEAR_COLUMN_NAME,
  STATUS_COLUMN_NAME,
  LAUNCHES_COLUMN_NAME,
  SC_TYPE_COLUMN_NAME,
  REGION_COLUMN_NAME,
  CLUSTER_COLUMN_NAME,
  TIMELINE_COLUMN_NAME,
  PROGRAM_TYPE_COLUMN_NAME,
  PROGRAM_COLUMN_NAME,
];

export const CHANGE_FIELD_TABLE_COLUMNS = [
  CHD_FIELD_NAME,
  CHD_PREVIOUS_VALUE,
  CHD_NEW_VALUE,
];