import React, { useContext, useEffect, useState } from "react";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Input from "@amzn/meridian/input";
import Textarea from "@amzn/meridian/textarea";
import { colorBlue100 } from "@amzn/meridian-tokens/base/color";
import {
  DEFAULT_BRS_INPUT_WIDTH,
  BRS_INFO_COLUMN_NUMBER,
  CAPACITY_ASK_COLUMN_NUMBER_1,
  CAPACITY_ASK_COLUMN_NUMBER_2,
  NOTES_FIELDS
} from "../../constants";
import BRSInputWithFormula from "./BRSInputWithFormula";
import PORContext from "../../store/por-context";
import BRSDetailsContext from "../../store/brs-store";
import cloneDeep from "lodash/cloneDeep";

export default function BRSDetailsColumn({ columnNumber }) {
  const brsContext = useContext(BRSDetailsContext);
  const porContext = useContext(PORContext);
  const { BRSDetails, setBRSDetails, highlightFields } = brsContext;
  const { canEdit } = porContext;
  const isCapacityASK = columnNumber !== BRS_INFO_COLUMN_NUMBER;
  let sliceStart = 0;
  let sliceEnd = Object.keys(BRSDetails).length;

  if (columnNumber === CAPACITY_ASK_COLUMN_NUMBER_1) {
    sliceStart = 0;
    sliceEnd = Object.keys(BRSDetails)
      .filter((brsFieldName) => BRSDetails[brsFieldName].isCapacityASK)
      .filter((brsFieldName) => !BRSDetails[brsFieldName].hidden).length / 2;
  } else if (columnNumber === CAPACITY_ASK_COLUMN_NUMBER_2) {
    sliceStart = Object.keys(BRSDetails)
      .filter((brsFieldName) => BRSDetails[brsFieldName].isCapacityASK)
      .filter((brsFieldName) => !BRSDetails[brsFieldName].hidden).length / 2;
    sliceEnd = Object.keys(BRSDetails)
      .filter((brsFieldName) => BRSDetails[brsFieldName].isCapacityASK)
      .filter((brsFieldName) => !BRSDetails[brsFieldName].hidden).length
  } else {
    sliceStart = 0;
    sliceEnd = Object.keys(BRSDetails)
      .filter((brsFieldName) => !BRSDetails[brsFieldName].isCapacityASK)
      .filter((brsFieldName) => !BRSDetails[brsFieldName].hidden).length;
  }

  const changeActualValueHandler = (brsFieldName) => (inputValue) => {
    const copyOfBRSDetails = cloneDeep(BRSDetails);
    copyOfBRSDetails[brsFieldName].actualValue = inputValue;
    setBRSDetails(copyOfBRSDetails);
  }

  const changeNotesHandler = (notesFieldName) => (inputValue) => {
    const copyOfBRSDetails = cloneDeep(BRSDetails);
    copyOfBRSDetails[notesFieldName].value = inputValue;
    setBRSDetails(copyOfBRSDetails);
  }

  return <Column>
    <Card>
      {isCapacityASK && <Row
        widths={["10vw", "fill"]}
        alignmentHorizontal="justify"
        minWidth={400}
        spacingInset="400 200"
      >
        <Heading level={6}>
          Description
        </Heading>
        <Heading level={6}>
          Capacity ASK
        </Heading>
        <Heading level={6}>
          Capacity Actuals
        </Heading>
      </Row>}
      {
        Object.keys(BRSDetails).length &&
        Object.keys(BRSDetails)
          .filter((brsFieldName) => isCapacityASK ? BRSDetails[brsFieldName].isCapacityASK : !BRSDetails[brsFieldName].isCapacityASK)
          .filter((brsFieldName) => !BRSDetails[brsFieldName].hidden)
          .slice(sliceStart, sliceEnd)
          .map((brsFieldName: string) => (
            <Row
              alignmentHorizontal="justify"
              minWidth={400}
              key={`${brsFieldName}-Input`}
              spacingInset="100 none"
              backgroundColor={highlightFields.has(brsFieldName) ?
                colorBlue100 : "primary"}
            >
              <Text type="b300">{brsFieldName}</Text>
              {isCapacityASK ?
                <Row>
                  <BRSInputWithFormula brsFieldName={brsFieldName} />
                  <Input
                    value={BRSDetails[brsFieldName].actualValue}
                    width={DEFAULT_BRS_INPUT_WIDTH}
                    onChange={changeActualValueHandler(brsFieldName)}
                    disabled={!canEdit}
                  ></Input>
                </Row> :
                <BRSInputWithFormula brsFieldName={brsFieldName} />
              }
            </Row>
          ))
      }
    </Card>
    {
      columnNumber === CAPACITY_ASK_COLUMN_NUMBER_2 && <Card>
        {
          NOTES_FIELDS.map((notesFieldName) => (
            <Row
              key={`${notesFieldName}-row`}
              alignmentHorizontal="justify"
              alignmentVertical="top"
              minWidth={400}
              spacingInset="100 none"
            >
              <Text type="b300">{notesFieldName}</Text>
              <Textarea
                value={BRSDetails[notesFieldName] && BRSDetails[notesFieldName].value || ""}
                placeholder={`Place for ${notesFieldName}`}
                width="15vw"
                onChange={changeNotesHandler(notesFieldName)}
                disabled={!canEdit}
              />
            </Row>
          ))
        }
      </Card>
    }
  </Column>
}