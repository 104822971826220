import React, { useCallback, useEffect, useState } from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Select, { SelectOption } from "@amzn/meridian/select";
import ProjectFilterTagsGroup from "../activeProjects/common/ProjectFilterTagsGroup";
import {
  CHGHISTORY_DATE_COLUMN_NAME,
  MAX_NUMBER_OF_FILTERS,
} from "../../constants";
import {
  CHANGE_FIELD_TABLE_COLUMNS,
  CHANGES_FIELD_NAME,
  CHGHISTORY_TABLE_TITLE_MAP
} from "../../constants/ChangeHistoryLayout";
import { getAllFilterTagsText } from "../activeProjects/ActiveProjectsUtils";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import { colorBlue50 } from "@amzn/meridian-tokens/base/color";

export default function ChangeHistoryFilterByColumnGroup({
  filteringSourceData, // array<object> - the source data this filter group ties to
  filterByColumns, // string - the filter by dropdown
  sourceDataFilters, // object<string, array> - the filter object that controls filtering source data
  setSourceDataFilters, // function - the setter of sourceDataFilters
}) {
  // current local column filter name and values for user to select
  const [filterFieldName, setFilterFieldName] = useState("");
  const [filterFieldValue, setFilterFieldValue] = useState("");
  const [showTooManyFiltersWarning, setShowTooManyFiltersWarning] =
    useState(false);

  useEffect(() => {
    if (isEmpty(filterFieldName) || isEmpty(filterFieldValue)) {
      return;
    }
    if (
      getAllFilterTagsText(sourceDataFilters).length === MAX_NUMBER_OF_FILTERS
    ) {
      setShowTooManyFiltersWarning(true);
      return; // do not setSourceDataFilters state since already reach maximum
    } else {
      setShowTooManyFiltersWarning(false);
    }
    const nextColumnFilters = cloneDeep(sourceDataFilters);
    if (nextColumnFilters.hasOwnProperty(filterFieldName!)) {
      nextColumnFilters[filterFieldName] = uniq([
        ...nextColumnFilters[filterFieldName],
        filterFieldValue,
      ]);
    } else {
      nextColumnFilters[filterFieldName] = [filterFieldValue];
    }
    setSourceDataFilters(nextColumnFilters);
  }, [filterFieldValue]);

  const clearFilterHandler = () => {
    const emptyColumnFilter = {};
    setSourceDataFilters(emptyColumnFilter);
    setFilterFieldName("");
    setFilterFieldValue("");
  };

  const getFilterByColumnOptions = () => {
    return filterByColumns.map((eachColumn) => (
      <SelectOption
        key={`${eachColumn}-select-column-dropdown`}
        value={eachColumn}
        label={CHGHISTORY_TABLE_TITLE_MAP[eachColumn]}
      />
    ));
  };

  const getFilterValueAfterColumnIsSelected = useCallback(() => {
    if (isEmpty(filterFieldName)) {
      return <React.Fragment />;
    }
    let date_values = filteringSourceData.map(
      (entry) => entry[CHGHISTORY_DATE_COLUMN_NAME].split(" ")[0]
    );
    let selectedFieldValues = filteringSourceData.map(
      (projectData) => {
        if (filterFieldName) {
          if (filterFieldName === "fieldName" || filterFieldName === "previousValue" || filterFieldName === "newValue") {
            let values: any[] = []
            projectData[CHANGES_FIELD_NAME].forEach((change) => {
              values.push(change[filterFieldName])
            })
            return values;
          }
          return [projectData[filterFieldName]]
        }
      }
    );
    selectedFieldValues = selectedFieldValues.flat();
    if (filterFieldName === CHGHISTORY_DATE_COLUMN_NAME) {
      return uniq(date_values)
        .sort()
        .filter((val) => val != null)
        .reverse()
        .map((date_value) => (
          <SelectOption
            key={`createDate-${date_value}-select-value-dropdown`}
            value={date_value}
            label={date_value}
          />
        ));
    }
    return uniq(selectedFieldValues)
      .filter((val) => val != null)
      .sort()
      .map((eachColumnValue) => (
        <SelectOption
          key={`${filterFieldName}-${eachColumnValue}-select-value-dropdown`}
          value={eachColumnValue}
          label={eachColumnValue}
        />
      ));
  }, [filterFieldName, filteringSourceData]);

  return (
    <Row
      backgroundColor={colorBlue50}
      type="outline"
      widths={["50%", "40%", "fill"]}
      spacingInset="200"
    >
      <Column spacingInset="200">
        <Row
          alignmentVertical="bottom"
          width="100%"
          widths={["45%", "45%", "10%"]}
        >
          <Select
            label="Filter By Column"
            value={filterFieldName}
            onChange={setFilterFieldName}
            placeholder="Filter by"
          >
            {getFilterByColumnOptions()}
          </Select>
          <Select
            label=" "
            value={filterFieldValue}
            onChange={setFilterFieldValue}
            placeholder="Type to Search"
          >
            {getFilterValueAfterColumnIsSelected()}
          </Select>
          <Button type="secondary" onClick={clearFilterHandler}>
            Clear
          </Button>
        </Row>
      </Column>
      <Column alignmentVertical="top">
        <ProjectFilterTagsGroup
          sourceDataFilters={sourceDataFilters}
          setSourceDataFilters={setSourceDataFilters}
          showTooManyFiltersWarning={showTooManyFiltersWarning}
          setShowTooManyFiltersWarning={setShowTooManyFiltersWarning}
        />
      </Column>
    </Row>
  );
}
