import React, { useContext, useEffect, useState } from 'react';
import Column from "@amzn/meridian/column";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import Icon from '@amzn/meridian/icon';
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small";
import Link from '@amzn/meridian/link';
import {
  UPCOMING_TAB_ID,
  UPCOMING_TAB_NAME,
  UPCOMING_REGION_TAB_ID,
  UPCOMING_REGION_TAB_NAME,
  UPCOMING_MHE_TAB_ID,
  UPCOMING_MHE_TAB_NAME,
  ACTIVE_REGION_TAB_ID,
  ACTIVE_REGION_TAB_NAME,
  ACTIVE_MHE_TAB_ID,
  ACTIVE_MHE_TAB_NAME,
  QUICK_SIGHT_TAB_NAME,
  QUICK_SIGHT_TAB_ID,
  QUICK_SIGHT_LINK
} from "../../constants";
import UpcomingProjectsTable from "./UpcomingProjectsTable";
import UpcomingProjectsMHETable from './UpcomingProjectsMHETable';
import UpcomingProjectsRegionTable from './UpcomingProjectsRegionTable';
import ActiveByRegionTable from './ActiveByRegionTable';
import ActiveByMHETable from './ActiveByMHETable';

export default function DashboardPage() {
  const [currentTab, setCurrentTab] = useState(UPCOMING_TAB_ID);

  return (
    <Column
      spacingInset="100 400"
      width="100%"
      alignmentVertical="top"
    >
      <TabGroup value={currentTab} onChange={setCurrentTab}>
        <Tab value={UPCOMING_TAB_ID}>{UPCOMING_TAB_NAME}</Tab>
        <Tab value={UPCOMING_MHE_TAB_ID}>{UPCOMING_MHE_TAB_NAME}</Tab>
        <Tab value={UPCOMING_REGION_TAB_ID}>{UPCOMING_REGION_TAB_NAME}</Tab>
        <Tab value={ACTIVE_MHE_TAB_ID}>{ACTIVE_MHE_TAB_NAME}</Tab>
        <Tab value={ACTIVE_REGION_TAB_ID}>{ACTIVE_REGION_TAB_NAME}</Tab>
        <Tab value={QUICK_SIGHT_TAB_ID}>
          <Link type="secondary" href={QUICK_SIGHT_LINK} target="_blank" rel="noopener noreferrer">
            {QUICK_SIGHT_TAB_NAME}
            <Icon tokens={exportSmallTokens} />
          </Link>
        </Tab>
      </TabGroup>
      {currentTab === UPCOMING_TAB_ID && <UpcomingProjectsTable />}
      {currentTab === UPCOMING_MHE_TAB_ID && <UpcomingProjectsMHETable />}
      {currentTab === UPCOMING_REGION_TAB_ID && <UpcomingProjectsRegionTable />}
      {currentTab === ACTIVE_MHE_TAB_ID && <ActiveByMHETable />}
      {currentTab === ACTIVE_REGION_TAB_ID && <ActiveByRegionTable />}
    </Column>
  );
}