import React, { useState } from "react";
import Button from "@amzn/meridian/button";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group"
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import searchTokens from "@amzn/meridian-tokens/base/icon/search";
import filterTokens from "@amzn/meridian-tokens/base/icon/filter";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import {
  TableActionBar,
} from "@amzn/meridian/table";
import { COMPACT_VIEW, DETAILED_VIEW } from "../../constants";
import { useChangeHistoryContext } from "../../store/change-history-store";
import { usePORContext } from "../../store/por-context";
import { generateChangeHistoryExcelData } from "../activeProjects/ActiveProjectsUtils";

export default function ChangeHistoryViewControlGroup() {
  const {
    searchChangeHistoryFilter,
    setSearchChangeHistoryFilter,
    ChangeHistoryViewType,
    setChangeHistoryViewType,
    columnFilters,
    showFilter,
    setShowFilter
  } = useChangeHistoryContext();
  const { changeHistoryData } = usePORContext();

  const downloadHandler = () => {
    generateChangeHistoryExcelData(changeHistoryData, columnFilters, searchChangeHistoryFilter);
  }

  const toggleShowFilterHandler = () => {
    setShowFilter(!showFilter)
  }

  return (
  <TableActionBar widths={["30%", "4%", "fill", "5%"]}>
    <Input
      type="search"
      size="medium"
      placeholder="Search By Name"
      value={searchChangeHistoryFilter}
      onChange={setSearchChangeHistoryFilter}
      suffixIconTokens={searchTokens}
    />
    <Button type="icon" onClick={toggleShowFilterHandler}>
      <Icon tokens={filterTokens}>Projects Filters</Icon>
    </Button>
    {/* <ButtonGroup value={ChangeHistoryViewType} onChange={setChangeHistoryViewType}>
      <ButtonOption value={COMPACT_VIEW}>Compact View</ButtonOption>
      <ButtonOption value={DETAILED_VIEW}>Detailed View</ButtonOption>
    </ButtonGroup> */} {/* disabling view change table till Phase 3.2 release*/}
    <div />
    <Button type="icon" onClick={downloadHandler}>
      <Icon tokens={downloadLargeTokens}>Download</Icon>
    </Button>
  </TableActionBar>
  );
}