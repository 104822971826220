import React, { useEffect } from "react";
import Button from "@amzn/meridian/button";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import searchTokens from "@amzn/meridian-tokens/base/icon/search";
import filterTokens from "@amzn/meridian-tokens/base/icon/filter";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import editTokens from "@amzn/meridian-tokens/base/icon/edit";
import { TableActionBar } from "@amzn/meridian/table";
import Tooltip from "@amzn/meridian/tooltip";
import {
  PROJECTS_TABLE_DEFAULT_FILTER,
  COMPACT_VIEW,
  DETAILED_VIEW,
  FIXED_TABLE_COLUMNS,
} from "../../../constants";
import { useProjectsContext } from "../../../store/project-store";
import { usePORContext } from "../../../store/por-context";
import { generateProjectsExcelData } from "../ActiveProjectsUtils";

export default function ProjectViewControlGroup({
  showProjectsFilter,
  setShowProjectsFilter,
  setIsSideMenuOpen,
}) {
  const {
    searchProjectsFilter,
    setSearchProjectsFilter,
    projectsViewType,
    setProjectsViewType,
    columnFilters,
    setColumnFilters,
  } = useProjectsContext();
  const { projectTableData, sortedColumns, isProjectsLoading } = usePORContext();

  useEffect(() => {
    if (projectsViewType === DETAILED_VIEW) {
      setColumnFilters(PROJECTS_TABLE_DEFAULT_FILTER);
    } else {
      setColumnFilters({});
    }

    // clean up when ProjectViewControlGroup unmount
    return () => {
      setSearchProjectsFilter("");
    };
  }, [projectsViewType]);

  const customizedColumns =
    projectsViewType === COMPACT_VIEW ? FIXED_TABLE_COLUMNS : sortedColumns;
  const downloadHandler = () => {
    generateProjectsExcelData(
      projectTableData,
      columnFilters,
      searchProjectsFilter,
      customizedColumns
    );
  };

  const changeOpenColumnSideNav = () => {
    setIsSideMenuOpen(true);
  };

  const toggleShowFilterHandler = () => {
    setShowProjectsFilter(!showProjectsFilter);
  };

  const changeViewTypeHandler = (viewType) => {
    setProjectsViewType(viewType);
  };

  return (
    <TableActionBar widths={["30%", "4%", "15%", "fill"]}>
      <Input
        type="search"
        size="medium"
        placeholder="Search By Name"
        value={searchProjectsFilter}
        onChange={setSearchProjectsFilter}
        suffixIconTokens={searchTokens}
      />
      <Button type="icon" onClick={toggleShowFilterHandler}>
        <Icon tokens={filterTokens}>Projects Filters</Icon>
      </Button>
      <ButtonGroup value={projectsViewType} onChange={changeViewTypeHandler}>
        <ButtonOption value={DETAILED_VIEW}>Detailed View</ButtonOption>
        <ButtonOption value={COMPACT_VIEW}>Compact View</ButtonOption>
      </ButtonGroup>
      <div />
      {projectsViewType === DETAILED_VIEW && (
        <Tooltip
          position="top"
          title="Use this to customize columns for display"
          id="tooltip"
        >
          <Button type="icon" onClick={changeOpenColumnSideNav} label="column">
            <Icon tokens={editTokens}>edit</Icon>
          </Button>
        </Tooltip>
      )}
      <Button type="icon" onClick={downloadHandler} disabled={isProjectsLoading}>
        <Icon tokens={downloadLargeTokens}>Download</Icon>
      </Button>
    </TableActionBar>
  );
}
