import React, { useCallback, useRef, useState } from "react";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout";
import { colorBlue500 } from "@amzn/meridian-tokens/base/color";
import { css } from "emotion";
import Button from "@amzn/meridian/button";
import Popover, { PopoverHeader } from "@amzn/meridian/popover";
import Text from "@amzn/meridian/text";

const blueText = css({
  color: colorBlue500
})

export default function BRSFormulaIndicator({ brsFieldName, resultFields }) {
  const tooltipText = `If this field changes, it will affect:`;
  const buttonRef = useRef();
  const [open, setOpen] = useState(false)
  const onClickButton = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])

  return <React.Fragment>
    <Button type="icon" onClick={onClickButton} ref={buttonRef} size="small">
      <span className={blueText}>
        <Icon tokens={infoKnockoutTokens} />
      </span>
    </Button>
    <Popover
      anchorNode={buttonRef.current}
      open={open}
      onClose={onClose}
      position="bottom"
      minWidth="18vw"
    >
      <PopoverHeader closeLabel="Close">{tooltipText}</PopoverHeader>
      <Text tag="ul">
        {resultFields.map((resultField) => <li key={`${brsFieldName}-${resultField}`}>{resultField}</li>)}
      </Text>
    </Popover>
  </React.Fragment>
}