import React, { createContext, useState } from "react";
import { DETAILED_VIEW, PROJECTS_TABLE_DEFAULT_FILTER } from "../constants";

interface ProjectStateProps {
  columnFilters: object; // used by Filter By Column Group, object key is [string], value is [array]
  searchProjectsFilter: string; // used by View Control Group
  projectsViewType: string; // used by View Control Group
  selectedColumns: object; // used by Side Column Menu
  selectedProjects: Set<string>; // used by Projects Table check boxes
  setColumnFilters(columnFilters): void;
  setSearchProjectsFilter(searchProjectsFilter: string): void;
  setProjectsViewType(projectsViewType: string): void;
  setSelectedColumns(selectedColumns: object): void;
  setSelectedProjects(selectedProjects: Set<string>): void;
}

const initialState: ProjectStateProps = {
  columnFilters: {},
  searchProjectsFilter: "",
  projectsViewType: DETAILED_VIEW,
  selectedColumns: {},
  selectedProjects: new Set(),
  setColumnFilters: () => {},
  setSearchProjectsFilter: () => {},
  setProjectsViewType: () => {},
  setSelectedColumns: () => {},
  setSelectedProjects: () => {},
};

const ProjectContext = createContext(initialState);

export function ProjectContextProvider({ children }) {
  const [userColumnFilters, setUserColumnFilters] = useState(
    initialState.columnFilters
  );
  const [userSearchProjectsFilter, setUserSearchProjectsFilter] = useState(
    initialState.searchProjectsFilter
  );
  const [userProjectsViewType, setUserProjectsViewType] = useState(
    initialState.projectsViewType
  );
  const [userSelectedColumns, setUserSelectedColumns] = useState(
    initialState.selectedColumns
  );
  const [userSelectedProjects, setUserSelectedProjects] = useState(
    initialState.selectedProjects
  );

  function setColumnFilters(columnFilters) {
    setUserColumnFilters(columnFilters);
  }

  function setSearchProjectsFilter(searchProjectsFilter) {
    setUserSearchProjectsFilter(searchProjectsFilter);
  }

  function setProjectsViewType(projectsViewType) {
    setUserProjectsViewType(projectsViewType);
  }

  function setSelectedColumns(selectedColumns) {
    setUserSelectedColumns(selectedColumns);
  }

  function setSelectedProjects(selectedProjects) {
    setUserSelectedProjects(selectedProjects);
  }

  const context: ProjectStateProps = {
    columnFilters: userColumnFilters,
    searchProjectsFilter: userSearchProjectsFilter,
    projectsViewType: userProjectsViewType,
    selectedColumns: userSelectedColumns,
    selectedProjects: userSelectedProjects,
    setColumnFilters,
    setSearchProjectsFilter,
    setProjectsViewType,
    setSelectedColumns,
    setSelectedProjects,
  };

  return (
    <ProjectContext.Provider value={context}>
      {children}
    </ProjectContext.Provider>
  );
}

export const useProjectsContext = () => React.useContext(ProjectContext);

export default ProjectContext;
