import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Column from "@amzn/meridian/column";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";
import {
  PROJECT_DETAILS_TAB_NAME,
  BRS_TAB_NAME,
  CHANGE_HISTORY_TAB_NAME,
  SITE_DETAILS_GROUP,
  EQUIPMENT_GROUP,
  ATTRIBUTES_GROUP,
  POR_CODE_COLUMN_NAME,
  SITE_COLUMN_NAME,
  MHE_TYPE_COLUMN_NAME,
} from "../../constants";
import ProjectDetailsTab from "./ProjectDetailsTab";
import BRSDetailsPage from "../BRS/BRSDetailsPage";
import ProjectQuickAccessSheet from "./ProjectQuickAccessSheet";
import PorApiFactory from "../../por-api/PlanOfRecordApiFactory";
import { useProjectDetailsContext } from "../../store/project-details-store";
import { BRSDetailsContextProvider } from "../../store/brs-store";
import { ActiveSiteData } from "../../por-api/generated-src";
import { usePORContext } from "../../store/por-context";
import {getProjectFieldsReverseMap} from "../activeProjects/ActiveProjectsUtils";

export default function ProjectDetailsPage() {
  const { siteId } = useParams<{ siteId: string }>();
  const [currentTab, setCurrentTab] = useState(PROJECT_DETAILS_TAB_NAME);
  const [isLoading, setIsLoading] = useState(false);
  const [siteName, setSiteName] = useState(siteId);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isQuickAccessOpen, setIsQuickAccessOpen] = useState(false);
  const { canEdit, projectsData, setProjectFieldsReverseMap } = usePORContext();
  const { flipReloadFlag, setProjectDetails, showAdminView, setShowAdminView } =
    useProjectDetailsContext();
  const TOTAL_FIELD_GROUP =
    SITE_DETAILS_GROUP.concat(EQUIPMENT_GROUP).concat(ATTRIBUTES_GROUP);

  const PorApi = PorApiFactory();

  useEffect(() => {
    (async () => {
      let projectData = projectsData.filter(data => data.projectId === siteId);
      let projectDetails;
      if (projectData.length) {
        projectDetails = projectData[0];
      } else {
        setIsLoading(true);
        try {
          const projectDetailsResponse = (
              await PorApi.getActiveSite(siteId || "")
          ).data as ActiveSiteData;
          if (
              projectDetailsResponse &&
              TOTAL_FIELD_GROUP.every((fieldName) =>
                  Object.keys(projectDetailsResponse).includes(fieldName)
              )
          ) {
            projectDetails = projectDetailsResponse
          }
        } catch (ex: any) {
          console.error(ex);
          setShowAlert(true);
          setErrorMessage(ex.message);
        } finally {
          setProjectFieldsReverseMap(getProjectFieldsReverseMap(projectDetails));
          setIsLoading(false);
        }
      }
      setProjectDetails(projectDetails);
      const { siteDetails } = projectDetails;
      const siteNameField = siteDetails?.filter(
          (siteDetailField) => siteDetailField.fieldName === SITE_COLUMN_NAME
      );
      if (siteNameField && siteNameField.length) {
        setSiteName(siteNameField[0].value || siteId);
      }
    })();

    return () => {
      setProjectDetails({});
      setIsLoading(false);
      setShowAlert(false);
      setErrorMessage("");
    };
  }, [siteId, flipReloadFlag]);

  // update admin view after user type is fetched
  useEffect(() => {
    setShowAdminView(canEdit ? true : false);
  }, [canEdit]);

  const toggleAdminViewHandler = () => {
    setShowAdminView(!showAdminView);
  };

  const getShowAdminViewText = (showAdminView) => {
    let adminViewText = showAdminView ? "Standard" : "Admin";
    return <Text color="error">{`Show ${adminViewText} View`}</Text>;
  };

  const showProjectQuickAccessHandler = () => {
    setIsQuickAccessOpen(true);
  };

  return isLoading ? (
    <Column spacingInset="400">
      <Loader />
    </Column>
  ) : (
    <Column spacingInset="none 400">
      {showAlert && (
        <Alert size="medium" type="error">
          {errorMessage}
        </Alert>
      )}
      <Row alignmentHorizontal="justify" widths={["80%", "20%"]}>
        <TabGroup value={currentTab} onChange={setCurrentTab}>
          <Tab value={PROJECT_DETAILS_TAB_NAME}>{PROJECT_DETAILS_TAB_NAME}</Tab>
          <Tab value={BRS_TAB_NAME}>{BRS_TAB_NAME}</Tab>
        </TabGroup>
        <Column alignmentHorizontal="end">
          <Link onClick={showProjectQuickAccessHandler} type="secondary">
            Show Project Quick Access
          </Link>
          {canEdit && (
            <Link onClick={toggleAdminViewHandler} type="secondary">
              {getShowAdminViewText(showAdminView)}
            </Link>
          )}
        </Column>
      </Row>
      {currentTab === PROJECT_DETAILS_TAB_NAME && (
        <ProjectDetailsTab siteId={siteId} />
      )}
      {currentTab === BRS_TAB_NAME && (
        <BRSDetailsContextProvider>
          <BRSDetailsPage siteIdProp={siteName} />
        </BRSDetailsContextProvider>
      )}
      <ProjectQuickAccessSheet
        isOpen={isQuickAccessOpen}
        setIsOpen={setIsQuickAccessOpen}
      />
    </Column>
  );
}
