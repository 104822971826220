import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Loader from "@amzn/meridian/loader";
import Button from "@amzn/meridian/button";
import ChangeHistoryStatusColumn from "./ChangeHistoryStatusColumn";
import ChangeHistoryApprovalsColumn from "./ChangeHistoryApprovalsColumn";
import ChangeHistoryProjectDetailsTable from "./ChangeHistoryProjectDetailsTable";
import PorApiFactory from "../../../por-api/PlanOfRecordApiFactory";
import {
  ApprovalDetails,
  ChangeHistoryDetails,
} from "../../../por-api/generated-src";
import ToasterPORResponse from "../../toaster/ToasterPORResponse";
import {
  SUCCESS_RESPONSE_STATUS,
  ALERT_AUTHENTICATION_MESSAGE,
  PROJECT_ID,
} from "../../../constants";
import {
  PENDING_STATUS,
  STATUS_FIELD_NAME,
} from "../../../constants/ChangeHistoryLayout";
import { usePORContext } from "../../../store/por-context";
import { Logger } from "@amzn/kepler-katal-logger";
import isEmpty from "lodash/isEmpty";

export default function ChangeHistoryDetailsPage() {
  const { changeHistoryId = "" } = useParams<{ changeHistoryId: string }>();
  const { onOpenToast, canDelete, changeHistoryData } = usePORContext();
  const [changeHistoryDetailsData, setChangeHistoryDetailsData] =
    useState<ChangeHistoryDetails>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flipDetailsFlag, setFlipDetailsFlag] = useState(false);
  const [approvalReason, setApprovalReason] = useState("");

  const PorApi = PorApiFactory();

  // load change history details
  useEffect(() => {
    if (isEmpty(changeHistoryId)) {
      console.error("changeHistoryId is required.");
      setErrorMessage("Error loading change history. Please try again.");
      setShowAlert(true);
      return;
    }
    let changeHistoryEntry = changeHistoryData.filter(data => data.id === changeHistoryId);
    if (changeHistoryEntry.length) {
      setChangeHistoryDetailsData(changeHistoryEntry[0]);
    } else {
      (async () => {
        setIsLoading(true);
        try {
          const changeHistoryDetailsResponse = (
              await PorApi.getChangeHistoryDetails(changeHistoryId)
          ).data;
          setChangeHistoryDetailsData(changeHistoryDetailsResponse);
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
          setShowAlert(true);
          setErrorMessage(ex.message);
        } finally {
          setIsLoading(false);
        }
      })();
    }

    return () => {
      setChangeHistoryDetailsData({});
      setShowAlert(false);
      setErrorMessage("");
    };
  }, [changeHistoryId, flipDetailsFlag]);

  const changeApproveStatusHandler = (isApprove) => () => {
    (async () => {
      let approveDetails: ApprovalDetails = {
        id: changeHistoryId,
        approved: isApprove,
        approvalReason: approvalReason,
      };
      try {
        const submitApproveResponse = (
          await PorApi.changeApprovalStatus(
            changeHistoryId || "",
            approveDetails
          )
        ).data as any;
        if (SUCCESS_RESPONSE_STATUS === submitApproveResponse) {
          onOpenToast();
          setFlipDetailsFlag((prevIsFalse) => !prevIsFalse);
        }
      } catch (ex: any) {
        console.error(ex);
        Logger.getInstance().logError(ex);
        setShowAlert(true);
        if (ex.response && ex.response.status === 403) {
          setErrorMessage(ALERT_AUTHENTICATION_MESSAGE);
          console.error("403 error");
        } else {
          setErrorMessage(ex.message);
        }
      }
    })();
  };

  return isLoading ? (
    <Column spacingInset="400">
      <Loader />
    </Column>
  ) : (
    <Column spacingInset="400" width="100%">
      <ToasterPORResponse message="Approval Details" />
      {showAlert && (
        <Alert size="medium" type="error">
          {errorMessage}
        </Alert>
      )}
      <Row
        alignmentVertical="top"
        width="100%"
        spacing="500"
        widths={["30%", "30%", "fill"]}
      >
        <ChangeHistoryStatusColumn
          changeHistoryDetailsData={changeHistoryDetailsData}
        />
        <ChangeHistoryApprovalsColumn
          changeHistoryDetailsData={changeHistoryDetailsData}
          approvalReason={approvalReason}
          setApprovalReason={setApprovalReason}
        />
        <Column>
          <Row>
            {canDelete && (
              <Button
                type="secondary"
                size="small"
                onClick={changeApproveStatusHandler(false)}
                disabled={
                  changeHistoryDetailsData[STATUS_FIELD_NAME] !== PENDING_STATUS
                }
              >
                Decline Changes
              </Button>
            )}
            {canDelete && (
              <Button
                type="primary"
                size="small"
                onClick={changeApproveStatusHandler(true)}
                disabled={
                  changeHistoryDetailsData[STATUS_FIELD_NAME] !== PENDING_STATUS
                }
              >
                Approve Changes
              </Button>
            )}
          </Row>
        </Column>
      </Row>
      <ChangeHistoryProjectDetailsTable
        changeHistoryProjectId={
          changeHistoryDetailsData && changeHistoryDetailsData[PROJECT_ID]
        }
        changeHistoryDetailsData={changeHistoryDetailsData}
      />
    </Column>
  );
}
