import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@amzn/meridian/alert";
import Sheet from "@amzn/meridian/sheet";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeSmallTokens from "@amzn/meridian-tokens/base/icon/close-small";
import Heading from "@amzn/meridian/heading";
import SingleSelectDropdown from "./SingleSelectDropdown";
import { usePORContext } from "../../store/por-context";
import {
  DROPDOWN_TYPE,
  PROJECT_ID,
  PROJECT_QUICK_ACCESS_FIELDS,
  PROJECT_QUICK_ACCESS_INITIAL_STATE,
} from "../../constants";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

const MULTIPLE_PROJECTS_ALERT_MESSAGE =
  "Found multiple projects, please refine search criteria.";
const VALIDATION_FAIL_ALERT_MESSAGE =
  "Please select at least one item to search.";

export default function ProjectQuickAccessSheet({ isOpen, setIsOpen }) {
  const navigate = useNavigate();

  const [projectQuickAccessState, setProjectQuickAccessState] = useState(
    PROJECT_QUICK_ACCESS_INITIAL_STATE
  );
  // need a local state for select options to populate from Project Data
  const [fieldNameOptionsMap, setFieldNameOptionsMap] = useState<{
    [key: string]: Array<string>;
  }>({});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    MULTIPLE_PROJECTS_ALERT_MESSAGE
  );
  const { projectTableData } = usePORContext();

  // populate fieldNameOptionsMap when component mount
  useEffect(() => {
    if (projectTableData.length) {
      let fieldNameOptionsMapFromProjects = {};
      PROJECT_QUICK_ACCESS_FIELDS.forEach((fieldName) => {
        if (projectTableData[0][fieldName].type === DROPDOWN_TYPE) {
          fieldNameOptionsMapFromProjects[fieldName] =
            projectTableData[0][fieldName].options;
        }
      });
      setFieldNameOptionsMap(fieldNameOptionsMapFromProjects);
    }
  }, [projectTableData]);

  const closeProjectQuickAccessSheetHandler = () => {
    setIsOpen(false);
  };

  const projectQuickAccessFieldChangeHandler =
    (_, fieldNameFromSingleSelectDropdown) => (userInputText) => {
      const nextProjectQuickAccessState = cloneDeep(projectQuickAccessState);
      nextProjectQuickAccessState[fieldNameFromSingleSelectDropdown] =
        userInputText;
      setProjectQuickAccessState(nextProjectQuickAccessState);
    };

  const validateQuickSearch = () => {
    return Object.values(projectQuickAccessState).join("").length > 0;
  };

  const quickFilterProjects = (quickFilterCriteria, projectsList) => {
    let filteredProject = cloneDeep(projectsList);
    for (const [fieldName, fieldValue] of Object.entries(quickFilterCriteria)) {
      if (isEmpty(fieldValue)) {
        continue;
      }
      filteredProject = filteredProject.filter(
        (projectData) => projectData[fieldName].value === fieldValue
      );
    }
    return filteredProject;
  };

  const quickFilterProjectHandler = () => {
    if (!validateQuickSearch()) {
      setShowError(true);
      setErrorMessage(VALIDATION_FAIL_ALERT_MESSAGE);
      return;
    }
    const filteredProject = quickFilterProjects(
      projectQuickAccessState,
      projectTableData
    );
    if (filteredProject.length === 1) {
      setShowError(false);
      setIsOpen(false);
      navigate(
        `/active-projects/project-details/${filteredProject[0][PROJECT_ID]}`
      );
    } else {
      // found multiple projects
      setShowError(true);
      setErrorMessage(
        `Found ${filteredProject.length} projects, please refine search criteria.`
      );
    }
  };

  return (
    <Sheet
      type="overlay"
      open={isOpen}
      onClose={closeProjectQuickAccessSheetHandler}
    >
      <Column spacing="300">
        <Row alignmentHorizontal="justify">
          <Heading level={4}>Project Quick Access</Heading>
          <Button onClick={closeProjectQuickAccessSheetHandler} type="icon">
            <Icon tokens={closeSmallTokens}>Close sheet</Icon>
          </Button>
        </Row>
        {showError && (
          <Alert size="small" type="error">
            {errorMessage}
          </Alert>
        )}
        {PROJECT_QUICK_ACCESS_FIELDS.map((fieldName) => (
          <SingleSelectDropdown
            key={`${fieldName}-project-quick-access-dropdown`}
            label={fieldName}
            width="100%"
            userSelectValue={projectQuickAccessState[fieldName]}
            changeValueHandler={projectQuickAccessFieldChangeHandler}
            fieldGroupName={null}
            fieldName={fieldName}
            options={fieldNameOptionsMap[fieldName] || []}
            error={false /* no need for UI validation */}
            errorMessage={""}
          />
        ))}
        <Button onClick={quickFilterProjectHandler}>Go to Project</Button>
      </Column>
    </Sheet>
  );
}
