import React, { useEffect, useState } from "react";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import Loader from "@amzn/meridian/loader";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Select, {SelectOption} from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import Textarea from "@amzn/meridian/textarea";
import { MODAL_WIDTH, INPUT_LINK_WIDTH, MAX_LINKS_LIST_LENGTH, TEAMS, OTHER, REASONS_FOR_CHANGE} from "../../constants";
import { prepareLinks } from "./ModalUtils";

export default function ConfirmChangeModal({ open, onClose, confirmHandler }) {
  const [linksList, setLinksList] = useState(["", ""]);
  const [reason, setReason] = useState("");
  const [team, setTeam] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasReasonError, setHasReasonError] = useState(false);
  const [hasTeamError, setHasTeamError] = useState(false);
  const [reasonErrorMessage, setReasonErrorMessage] = useState("");
  const [teamErrorMessage, setTeamErrorMessage] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [otherTeam, setOtherTeam] = useState("");
  const [otherReason, setOtherReason] = useState("");


  useEffect(() => {
    setIsLoading(false);
    setReason("");
    setTeam("");
    setLinksList(["", ""]);
    setHasReasonError(false);
    setHasTeamError(false);
    setReasonErrorMessage("");
    setTeamErrorMessage("");
    setAdditionalNotes("");
    setOtherTeam("");
    setOtherReason("");
  }, [open]);

  const handleLinkChange = (value, index) => {
    const list = [...linksList];
    list[index] = value;
    setLinksList(list);
  };

  const addLinkHandler = () => {
    setLinksList([...linksList, ""]);
  }

  const removeLinkHandler = (index) => {
    const list = [...linksList];
    list.splice(index, 1);
    setLinksList(list);
  }

  const attemptSubmitSuccess = () => {
    if (!reason || (reason == OTHER && !otherReason)) {
      setHasReasonError(true);
      setReasonErrorMessage("Reason for change is required.");
    } else {
      setHasReasonError(false);
      setReasonErrorMessage("");
    }
    if (!team || (team == OTHER && !otherTeam)) {
      setHasTeamError(true);
      setTeamErrorMessage("Team field is required.");
    } else {
      setHasTeamError(false);
      setTeamErrorMessage("");
    }
    return ((team && (team != OTHER || otherTeam)) && (reason && (reason != OTHER || otherReason)))
  }

  const saveChangeHandler = () => {
    if (attemptSubmitSuccess()) {
      setIsLoading(true);
      return confirmHandler(prepareLinks(linksList), (reason == OTHER) ? otherReason : reason,
          (team == OTHER) ? otherTeam : team, additionalNotes)();
    }
  }

  return (
    <Modal
      open={open}
      title=""
      onClose={onClose}
      scrollContainer="viewport"
      width={MODAL_WIDTH}
      closeLabel="Cancel"
    >
      <Column>
        <Heading level={3}>Confirm Changes</Heading>
        <Text>
          Enter the reason why these changes were made or hit cancel to review changes.
          Clicking outside this modal will cancel save confirmation.
        </Text>
        <Row key="team" alignmentHorizontal="end">
          <Heading level={5}>Team</Heading>
          <Select
              size="small"
              placeholder="Select Team"
              onChange={setTeam}
              value={team}
              width={INPUT_LINK_WIDTH}
              error={hasTeamError}
              errorMessage={teamErrorMessage}
          >
            {TEAMS.map((team) => (
                <SelectOption
                    key={team}
                    value={team}
                    label={team}
                />
            ))}
          </Select>
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>
        {(team == OTHER) && <Row alignmentHorizontal="end">
          <Input
              size="small"
              name="other"
              type="text"
              id="other"
              width={INPUT_LINK_WIDTH}
              value={otherTeam}
              placeholder="Specify Team"
              onChange={setOtherTeam}
              error={hasTeamError}
              errorMessage={teamErrorMessage}
          />
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>}
        {linksList.map((singleLink, index) => (
          <Row key={`${index}-links`} alignmentHorizontal="end">
            {index === 0 && (
              <Heading level={5}>Approval Link</Heading>
            )}
            <Input
              size="small"
              name="links"
              type="text"
              id="links"
              value={singleLink}
              width={INPUT_LINK_WIDTH}
              placeholder="https://..."
              pattern={new RegExp("^(http|https)://", "i")}
              onChange={value => handleLinkChange(value, index)}
            />
            {linksList.length - 1 === index && linksList.length < MAX_LINKS_LIST_LENGTH && (
              <Button size="small" onClick={addLinkHandler} type="secondary">+</Button>
            )}
            {linksList.length - 1 !== index && (
              <Button size="small" onClick={() => removeLinkHandler(index)} type="secondary"><Text color="error">x</Text></Button>
            )}
          </Row>
        ))}
        <Row key="reason" alignmentHorizontal="end">
          <Heading level={5}>Change Reason</Heading>
          <Select
              size="small"
              placeholder="Select Reason"
              onChange={setReason}
              value={reason}
              width={INPUT_LINK_WIDTH}
              error={hasReasonError}
              errorMessage={reasonErrorMessage}
          >
            {REASONS_FOR_CHANGE.map((reason) => (
                <SelectOption
                    key={reason}
                    value={reason}
                    label={reason}
                />
            ))}
          </Select>
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>
        {(reason == OTHER) && <Row alignmentHorizontal="end">
          <Input
              size="small"
              name="otheReason"
              type="text"
              id="otherReason"
              width={INPUT_LINK_WIDTH}
              value={otherReason}
              placeholder="Specify Reason"
              onChange={setOtherReason}
              error={hasReasonError}
              errorMessage={reasonErrorMessage}
          />
          <Button size="small" type="secondary" disabled={true}><p style={{color: "white"}}>o</p></Button>
        </Row>}
        <Textarea
            value={additionalNotes}
            onChange={setAdditionalNotes}
            label="Enter any additional notes"
        />
      </Column>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button
            type="secondary"
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={saveChangeHandler}
            disabled={isLoading}
          >
            Save Changes
            {isLoading && (
              <Loader size="small" label="Saving..." />
            )}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}