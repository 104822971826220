import React, { createContext, useContext, useState } from "react";
import { ActiveSiteData } from "../por-api/generated-src";

interface ProjectDetailsStateProps {
  projectDetails: ActiveSiteData;
  setProjectDetails(projectDetailData): any;
  flipReloadFlag: boolean;
  toggleFlipReloadFlag(): any;
  toasterMessage: string;
  setToasterMessage(toasterMessage): any;
  showAdminView: boolean;
  setShowAdminView(showAdminView): any;
  highlightFields: Set<string>;
  addHighlightFields(fieldName): void;
  clearHighlightFields(): void;
}

const initialState: ProjectDetailsStateProps = {
  projectDetails: {},
  setProjectDetails: () => {},
  flipReloadFlag: false,
  toggleFlipReloadFlag: () => {},
  toasterMessage: "",
  setToasterMessage: () => {},
  showAdminView: false,
  setShowAdminView: () => {},
  highlightFields: new Set(),
  addHighlightFields: () => {},
  clearHighlightFields: () => {},
};

const ProjectDetailsContext = createContext(initialState);

export function ProjectDetailsContextProvider({ children }) {
  const [userProjectDetails, setUserProjectDetails] = useState<ActiveSiteData>(
    {}
  );
  const [flipReloadFlag, setFlipReloadFlag] = useState(
    initialState.flipReloadFlag
  );
  const [userToasterMessage, setUserToasterMessage] = useState(
    initialState.toasterMessage
  );
  const [userShowAdminView, setUserShowAdminView] = useState(
    initialState.showAdminView
  );
  const [userHighlightFields, setUserHighlightFields] = useState<Set<string>>(
    initialState.highlightFields
  );

  function setProjectDetails(projectDetailData) {
    setUserProjectDetails(projectDetailData);
  }

  function toggleFlipReloadFlag() {
    setFlipReloadFlag((prevIsFalse) => !prevIsFalse);
  }

  function setToasterMessage(toasterMessage) {
    setUserToasterMessage(toasterMessage);
  }

  function setShowAdminView(showAdminView) {
    setUserShowAdminView(showAdminView);
  }

  function addHighlightFields(fieldName) {
    setUserHighlightFields((prevUserHighlightFields) =>
      prevUserHighlightFields.add(fieldName)
    );
  }

  function clearHighlightFields() {
    setUserHighlightFields(new Set());
  }

  const context: ProjectDetailsStateProps = {
    projectDetails: userProjectDetails,
    setProjectDetails,
    flipReloadFlag,
    toggleFlipReloadFlag,
    toasterMessage: userToasterMessage,
    setToasterMessage,
    showAdminView: userShowAdminView,
    setShowAdminView,
    highlightFields: userHighlightFields,
    addHighlightFields,
    clearHighlightFields,
  };

  return (
    <ProjectDetailsContext.Provider value={context}>
      {children}
    </ProjectDetailsContext.Provider>
  );
}

export const useProjectDetailsContext = () => useContext(ProjectDetailsContext);

export default ProjectDetailsContext;
