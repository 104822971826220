import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import DatePicker from "@amzn/meridian/date-picker";
import Input from "@amzn/meridian/input";
import SingleSelectDropdown from "../projectDetails/SingleSelectDropdown";
import {
  DATE_TYPE,
  DROPDOWN_TYPE,
  INPUT_FIELDS_TO_COPY,
  TEXT_TYPE,
} from "../../constants";
import { usePORContext } from "../../store/por-context";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

export const REQUIRED_FIELD_MESSAGE = "Required field";

export default function CopyProjectsChangeFieldsGroup({
  copyProjectsFields,
  setCopyProjectFields,
}) {
  const { projectTableData } = usePORContext();
  const [fieldNameOptionsMap, setFieldNameOptionsMap] = useState<{
    [key: string]: Array<string>;
  }>({}); // need local state to manage new dropdown fields, do not update project data globally

  // populate fieldNameOptionsMap when component mount
  useEffect(() => {
    if (projectTableData.length) {
      let fieldNameOptionsMapFromProjects = {};
      INPUT_FIELDS_TO_COPY.forEach(({ fieldName }) => {
        if (projectTableData[0][fieldName].type === DROPDOWN_TYPE) {
          fieldNameOptionsMapFromProjects[fieldName] =
            projectTableData[0][fieldName].options;
        }
      });
      setFieldNameOptionsMap(fieldNameOptionsMapFromProjects);
    }
  }, [projectTableData]);

  const copyProjectsFieldsChangeHandler =
    (fieldName, fieldNameFromSingleSelectDropdown?, completeOptions?) =>
    (userText) => {
      const fieldNameToModify = fieldName || fieldNameFromSingleSelectDropdown;
      const nextCopyProjectsFields = cloneDeep(copyProjectsFields);
      nextCopyProjectsFields[fieldNameToModify] = userText;
      setCopyProjectFields(nextCopyProjectsFields);
      // dropdown type only - Add new field to dropdown options
      if (completeOptions) {
        const nextFieldNameOptionsMap = cloneDeep(fieldNameOptionsMap);
        nextFieldNameOptionsMap[fieldNameToModify] = completeOptions;
        setFieldNameOptionsMap(nextFieldNameOptionsMap);
      }
    };

  const isShowError = (isRequired, value) => {
    return isRequired && isEmpty(value);
  };

  return (
    <Column>
      {INPUT_FIELDS_TO_COPY.map(({ fieldName, isRequired }) => {
        const type =
          projectTableData.length && projectTableData[0][fieldName].type;
        switch (type) {
          case TEXT_TYPE:
            return (
              <Input
                key={`${fieldName}-changeField-text`}
                label={fieldName}
                value={copyProjectsFields[fieldName]}
                onChange={copyProjectsFieldsChangeHandler(fieldName)}
                error={isShowError(isRequired, copyProjectsFields[fieldName])}
                errorMessage={
                  isShowError(isRequired, copyProjectsFields[fieldName]) &&
                  REQUIRED_FIELD_MESSAGE
                }
              />
            );
          case DROPDOWN_TYPE:
            return (
              <SingleSelectDropdown
                key={`${fieldName}-changeField-dropdown`}
                label={fieldName}
                width="100%"
                userSelectValue={copyProjectsFields[fieldName]}
                changeValueHandler={copyProjectsFieldsChangeHandler}
                fieldGroupName={null}
                fieldName={fieldName}
                options={fieldNameOptionsMap[fieldName] || []}
                error={isShowError(isRequired, copyProjectsFields[fieldName])}
                errorMessage={
                  isShowError(isRequired, copyProjectsFields[fieldName]) &&
                  REQUIRED_FIELD_MESSAGE
                }
              />
            );
          case DATE_TYPE:
            return (
              <DatePicker
                key={`${fieldName}-changeField-date`}
                value={copyProjectsFields[fieldName]}
                onChange={copyProjectsFieldsChangeHandler(fieldName)}
                label={fieldName}
                locale="en-CA"
                error={isShowError(isRequired, copyProjectsFields[fieldName])}
                errorMessage={
                  isShowError(isRequired, copyProjectsFields[fieldName]) &&
                  REQUIRED_FIELD_MESSAGE
                }
              />
            );
        }
      })}
    </Column>
  );
}
