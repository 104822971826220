import {
  COUNTRY_US,
  COUNTRY_CANADA,
  COUNTRY_FIELD_TO_TITLE,
  PROGRAM_TYPE_COLUMN_NAME
} from "../../constants";

export function capitalizeFirstLetter(titleString) {
  return titleString ? titleString[0].toUpperCase() + titleString.slice(1): titleString;
}

export function formatDateMonth(dayAndMonth) {
  return dayAndMonth < 10 ? "0" + dayAndMonth : "" + dayAndMonth;
}

export const  navToProjectDetails = (navigate, stateData)=> () =>{
  navigate("/active-projects", {
    state: { "copyProjectsFieldsFromRouting": { [PROGRAM_TYPE_COLUMN_NAME]: ["Capacity"], ...stateData } },
  });
  }

export const addGrandTotaltoRow = (rowData, yearList) => {
  let count = 0;
  let totalCount = 0;
  yearList.forEach(year => {
    count = rowData.filter(data => (data.year === year)).map(cell => {
      return cell.count
    }).reduce((a, b) => a + b);
    rowData.push({
      "year": year,
      "Country": "Grand Total",
      "count": count
    });
    totalCount += count;
  });
  rowData.push({
    "year": "Total",
    "Country": "Grand Total",
    "count": totalCount
  });
}

export const addCountryTotals = (rowData) => {
  [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
    let count = 0;
    rowData.forEach(row => {
      if (row.Country === country)
        count += Number(row.count);
    });
    rowData.push({
      "year": "Total",
      "Country": country,
      "count": count
    });

  });
}

export const getYearsinData = (dashboardData) => {
  let yearsSet = new Set();
  dashboardData.forEach(element => {
    yearsSet.add(element.year)
  });
  return [...yearsSet].map(String);
}