import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Column from "@amzn/meridian/column";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Text from "@amzn/meridian/text";
import {
  MAX_CONTENT_LENGTH,
  LARGE_CELL_WIDTH,
  SMALL_CELL_WIDTH,
  DEFAULT_ITEMS_PER_PAGE,
  SITE_COLUMN_NAME,
  COMPACT_VIEW,
  CHANGE_HISTORY_TABLE_TYPE,
  CHGHISTORY_DATE_COLUMN_NAME,
} from "../../constants";
import {
  CHGHISTORY_TABLE_TITLE_MAP,
  CH_CHANGE_FIELD,
  CH_NEW_SITE,
  CH_DELETED_SITE,
  CHANGES_FIELD_NAME,
  ID_FIELD_NAME, CHANGE_FIELD_TABLE_COLUMNS,
} from "../../constants/ChangeHistoryLayout";
import { usePORContext } from "../../store/por-context";
import { useChangeHistoryContext } from "../../store/change-history-store";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import TableText from "../tableText/TableText";

export default function ChangeHistoryTable() {
  const navigate = useNavigate();
  const { isProjectsLoading, changeHistoryData, setChangeHistoryData } =
    usePORContext();
  const { columnFilters, searchChangeHistoryFilter, ChangeHistoryViewType } =
    useChangeHistoryContext();
  const [sortColumn, setSortColumn] = useState(SITE_COLUMN_NAME);
  const [sortDirection, setSortDirection] =
    useState<TableSortDirection>("ascending");

  const navToChangeHistoryDetails = (changeID) => () => {
    navigate(`/change-history/${changeID}`, {
      state: { columnFilters },
    });
  };

  /**
   * @summary get column header cell component
   * @param {string} [columnHeader] current column header after sorted
   * **/
  const getColumnHeader = (columnHeader) => {
    return <Row>{CHGHISTORY_TABLE_TITLE_MAP[columnHeader]}</Row>;
  };

  const getProjectHeaderRow = () => {
    return (
      <TableRow>
        {displayColumns.map((columnHeader) => (
          <TableCell
            key={`${columnHeader}-header`}
            alignmentHorizontal="center"
            width={
              columnHeader.length > MAX_CONTENT_LENGTH
                ? LARGE_CELL_WIDTH
                : SMALL_CELL_WIDTH
            }
            sortColumn={columnHeader != ID_FIELD_NAME ? columnHeader : ""}
          >
            {getColumnHeader(columnHeader)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  /**
   * @summary filter method for Search Project input
   * @param {object} [changeHistoryData] single change history data
   * **/
  const userFilterGlobalMethod = (changeHistoryData) => {
    if (isEmpty(searchChangeHistoryFilter)) {
      return true;
    }
    let changeHistoryValues = Object.values(changeHistoryData);

    let filteredChangeHistoryValues = changeHistoryValues.filter(
      (changeHistoryValue: any) => {
        return (
          changeHistoryValue !== null &&
          changeHistoryValue
            .toString()
            .toLowerCase()
            .includes(searchChangeHistoryFilter.toLowerCase())
        );
      }
    );
    return filteredChangeHistoryValues.length > 0;
  };

  /**
   * @summary filter method to check with single filtering constrain
   * @param {string} [filterKey] filter by field name
   * @param {object} [columnFilters] columnFilter state
   * @param {object} [changeHistoryData] single chnage history data
   * **/
  const filterByMultiSelectTags = (
    filterKey,
    columnFilters,
    changeHistoryData
  ) => {
    if (columnFilters[filterKey].length === 0) {
      return true;
    }
    return columnFilters[filterKey].some((filterValue) => {
      if (filterKey === CHGHISTORY_DATE_COLUMN_NAME)
        return changeHistoryData[filterKey].includes(filterValue);
      else if (filterKey === "fieldName" || filterKey === "previousValue" || filterKey === "newValue")
        return changeHistoryData[CHANGES_FIELD_NAME].some((change) => change[filterKey] === filterValue);
      return filterValue === changeHistoryData[filterKey];
    });
  };

  /**
   * @summary filter method for Filter by Column select dropdown
   * @param {object} [changeHistoryData] single project data
   * **/
  const userInputFilterMethod = (changeHistoryData) => {
    if (
      !Object.keys(columnFilters) ||
      Object.keys(columnFilters).length === 0
    ) {
      return true;
    }
    let filterResult = true;

    Object.keys(columnFilters) // only compare the ones are defined
      .filter(
        (filterKey) =>
          Array.isArray(columnFilters[filterKey]) &&
          columnFilters[filterKey].length > 0
      )
      .forEach((filterKey) => {
        filterResult =
          filterResult &&
          filterByMultiSelectTags(filterKey, columnFilters, changeHistoryData);
      });
    return filterResult;
  };

  const compareProjectsFn = (event) => (a, b) => {
    const aValue = a[event.sortColumn];
    const bValue = b[event.sortColumn];
    return event.sortDirection === "ascending"
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  };

  const sortProjectsHandler = (event) => {
    const nextProjectsList = cloneDeep(changeHistoryData);
    setChangeHistoryData(nextProjectsList.sort(compareProjectsFn(event)));
    setSortColumn(event.sortColumn);
    setSortDirection(event.sortDirection);
  };

  // Pagination
  const totalFilteredProjects = changeHistoryData
    .filter(userFilterGlobalMethod)
    .filter(userInputFilterMethod);

  const getDisplayColumns = () => {
    return ChangeHistoryViewType === COMPACT_VIEW
      ? Object.keys(CHGHISTORY_TABLE_TITLE_MAP).filter((element) => {
          return ["previousValue", "newValue"].indexOf(element) < 0;
        })
      : Object.keys(CHGHISTORY_TABLE_TITLE_MAP);
  };
  const displayColumns = getDisplayColumns();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const numberOfPagesForDetailedView = Math.ceil(
    totalFilteredProjects.length / itemsPerPage
  );
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;

  const getColumnValue = (changeHistoryData, currentColumn, ndx) => {
    if (currentColumn === CH_CHANGE_FIELD) {
      if (
        changeHistoryData[currentColumn] === CH_NEW_SITE ||
        changeHistoryData[currentColumn] === CH_DELETED_SITE
      ) {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {changeHistoryData[currentColumn]}
          </div>
        );
      } else {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {"Project Updates"}
          </div>
        );
      }
    } else {
      if (CHANGE_FIELD_TABLE_COLUMNS.includes(currentColumn)) {
        return (
            <div style={{ whiteSpace: "nowrap" }}>
              {changeHistoryData[CHANGES_FIELD_NAME][ndx][currentColumn]}
            </div>
        );
      } else {
        return (
            <div style={{whiteSpace: "nowrap"}}>
              {changeHistoryData[currentColumn]
                  ? changeHistoryData[currentColumn]
                  : ""}
            </div>
        );
      }
    }
  };

  const getCHTableCell = (changeHistoryData, currentColumn, ndx) => {
    return (
      (ndx > 0 && !CHANGE_FIELD_TABLE_COLUMNS.includes(currentColumn)) ?
            <TableCell showCell={false} /> :
      <TableCell
        key={`${changeHistoryData[ID_FIELD_NAME]}-${currentColumn}-data`}
        alignmentHorizontal={currentColumn === "reasonForChange" ? "start" : "center"}
        rowSpan={CHANGE_FIELD_TABLE_COLUMNS.includes(currentColumn) ? 1 : changeHistoryData[CHANGES_FIELD_NAME].length}
        alignmentVertical="center"
      >
        {getColumnValue(changeHistoryData, currentColumn, ndx)}
      </TableCell>
    );
  };

  /**
   * @summary get detailed view table row without data processing, pagination included
   * @return JSX array of table rows
   * **/
  const getDetailedViewRows = () => {
    return totalFilteredProjects
      .slice(firstVisibleIndex, lastVisibleIndex)
      .map((chData) => {
        if (chData[CHANGES_FIELD_NAME].length === 0) {
          chData[CHANGES_FIELD_NAME] = [{
            "fieldName": "",
            "previousValue": "",
            "newValue": ""
          }];
        }
        return chData;
      })
      .map((changeHistoryData) => (
        <React.Fragment>
          {changeHistoryData[CHANGES_FIELD_NAME].map((change, ndx) => {
            return <TableRow key={`${changeHistoryData[ID_FIELD_NAME]}-row-${ndx}`}>
              {displayColumns.map((currentColumn) => {
                return currentColumn == ID_FIELD_NAME && ndx === 0 ? (
                    <TableCell
                        key={`${changeHistoryData[ID_FIELD_NAME]}-${ID_FIELD_NAME}-data`}
                        alignmentHorizontal="center"
                        rowSpan={changeHistoryData[CHANGES_FIELD_NAME].length}
                        alignmentVertical="center"
                    >
                      <Link
                          data-testid="projectSiteText"
                          type="secondary"
                          onClick={navToChangeHistoryDetails(
                              changeHistoryData[ID_FIELD_NAME]
                          )}
                      >
                        View
                      </Link>
                    </TableCell>
                ) : (
                    getCHTableCell(changeHistoryData, currentColumn, ndx)
                );
              })}
            </TableRow>
          })}
        </React.Fragment>
      ));
  };

  return (
    <Column>
      <TableText
        tableData={totalFilteredProjects}
        tableType={CHANGE_HISTORY_TABLE_TYPE}
      />
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        {isProjectsLoading && <Loader type="linear" />}
        <Table
          headerRows={1}
          showDividers
          stickyHeaderColumn
          stickyHeaderRow
          headerColumns={1}
          spacing="small"
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={sortProjectsHandler}
          rowComponents={[TableRow, React.Fragment]}
        >
          {getProjectHeaderRow()}
          {getDetailedViewRows()}
        </Table>
      </div>
      <Row widths={["18%", "10%", "6%", "fill"]}>
        <Text>{`Showing ${firstVisibleIndex + 1} to ${lastVisibleIndex} of ${totalFilteredProjects.length} entries`}</Text>
        <Select value={itemsPerPage} onChange={setItemsPerPage} size="small">
          <SelectOption value={10} label="10" />
          <SelectOption
            value={DEFAULT_ITEMS_PER_PAGE}
            label={DEFAULT_ITEMS_PER_PAGE}
          />
          <SelectOption value={30} label="30" />
        </Select>
        <Text>per page</Text>
        <div />
        <Pagination
          numberOfPages={numberOfPagesForDetailedView}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Row>
    </Column>
  );
}
