import { useEffect } from "react";
import { GetInitialProjectsApi } from "../GetProjectsApi";
import { getDefaultSortedColumnHeaders, getProjectFieldsReverseMap, processActiveSitesData } from "../../components/activeProjects/ActiveProjectsUtils";
import { usePORContext } from "../../store/por-context";
import PorApiFactory from '../../por-api/PlanOfRecordApiFactory';
import { PaginatedActiveSitesData } from "../../por-api/generated-src";
import { Logger } from '@amzn/kepler-katal-logger';
import { DEFAULT_API_CALL_LIMIT } from "../../constants";
import isEmpty from "lodash/isEmpty";

const useLoadProjectsEffect = () => {
  const PorApi = PorApiFactory();

  const {
    projectTableData,
    setIsProjectsLoading,
    isProjectsLoading,
    updateProjects,
    hasLoadedProjects,
    setProjects,
  } = usePORContext();

  useEffect(() => {
    if((isEmpty(projectTableData) || !hasLoadedProjects) && !isProjectsLoading) {
      setIsProjectsLoading(true);
      (async () => {
        try {
          const firstTimeActiveSiteResponse = (await PorApi.getActiveSites(DEFAULT_API_CALL_LIMIT)).data as PaginatedActiveSitesData;
          const { nextToken, data } = firstTimeActiveSiteResponse;
          let userNextToken = nextToken;
          if (data && data.length) {
            setProjects(data);
            while (userNextToken) {
              let activeProjectsResponse = (await PorApi.getActiveSites(DEFAULT_API_CALL_LIMIT, userNextToken)).data as PaginatedActiveSitesData;
              const { nextToken, data } = activeProjectsResponse;
              userNextToken = nextToken;
              if (data && data.length) {
                updateProjects(data);
              }
            }
          }
        } catch (ex: any) {
          console.error(`Error on fetching active sites data`, ex.message);
          Logger.getInstance().logError(ex);
        } finally {
          setIsProjectsLoading(false);
        }
      })();
    }
  }, [projectTableData]);
};

export const useLoadOnePageProjectsEffect = () => {
  const {
    projectTableData,
    preloadingProjectData,
    setPreloadingProjectData,
    sortedColumns,
    setSortedColumns,
    setDefaultColumns,
    setProjectFieldsReverseMap,
  } = usePORContext();

  useEffect(() => {
    if (preloadingProjectData) return;

    (async () => {
      setPreloadingProjectData(true);
      const onePageProjectListResponse = await GetInitialProjectsApi() || [];
      const flattenedOnePageProjectList = processActiveSitesData(onePageProjectListResponse);
      const defaultColumnsFromProjectResults = getDefaultSortedColumnHeaders(flattenedOnePageProjectList[0]);
      setProjectFieldsReverseMap(getProjectFieldsReverseMap(onePageProjectListResponse[0]));  // Note not use flattenedProjectData since need original data to figure out which group each fieldName is.
      setDefaultColumns(defaultColumnsFromProjectResults);
      if (isEmpty(sortedColumns)) {
        setSortedColumns(defaultColumnsFromProjectResults);
      }
    })();
  }, [projectTableData, sortedColumns, preloadingProjectData]);
}

export default useLoadProjectsEffect;