import React, {useContext} from "react";
import Masthead, {
  MastheadTitle,
  MastheadMenuButton,
} from "@amzn/meridian/masthead";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import {POR_WIKI_LINK} from "../../constants";
import helpIconTokens from "@amzn/meridian-tokens/base/icon/help";
import PORContext from "../../store/por-context";
import logo from "../../assets/por_filled_30.png";

export default function PlanOfRecordMasthead() {
  const porContext = useContext(PORContext);
  const { toggleMenuBar } = porContext;
 
  return (
    <Masthead>
      <MastheadMenuButton onClick={toggleMenuBar} open={true} />
      <MastheadTitle href="#" data-testid="mastheadTitlePOR">
        <Row>
          <img
            src={logo}
            alt="logo"
          ></img>
          <Text>Plan of Record</Text>
        </Row>
      </MastheadTitle>
      <Link
          href={POR_WIKI_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon tokens={helpIconTokens} />
        </Link>
    </Masthead>
  )
}