import React from "react";
import Row from "@amzn/meridian/row";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import "../../index.scss"

export default function BreadCrumbs({ crumbs }) {
  if (crumbs.length <= 1) {   // do not render a single breadcrumb
    return null;
  }

  return (
    <div className="stickyToLeft">
      <Row width="100%">
        <BreadcrumbGroup>
          {crumbs.map(
            ({ name, path }, key) =>
              <Breadcrumb href={`${path}`} key={key}>{name}</Breadcrumb>
          )}
        </BreadcrumbGroup>

      </Row>
    </div>
  )
}