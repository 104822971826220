import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useMatch } from "react-router-dom";
import routes, { RouteType } from "./Routes";
import BreadCrumbs from "../breadcrumb/BreadCrumbs";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import PORContext from "../../store/por-context";
import UserPermissionsApi from "../../api/UserPermissionsApi";
import { Logger } from "@amzn/kepler-katal-logger";

export default function PlanOfRecordRouter() {
  const porContext = useContext(PORContext);
  const { user, setUser } = porContext;
  const location = useLocation();

  // Add user permission API call here cuz it's needed for all pages
  useEffect(() => {
    if (!user) {
      (async () => {
        setUser(await UserPermissionsApi());
      })();
    }
    Logger.getInstance().logPageView();
  }, [location]);

  const getRouteElementWithBreadCrumbs = (
    routes,
    path,
    ContextProvider,
    Component
  ) => {
    const match = useMatch(path) || { pattern: { path: "" } };
    const crumbs: Array<RouteType> = routes.filter(({ path }) =>
      match.pattern.path.includes(path)
    );

    return (
      <Column alignmentVertical="top" width="100%" height="100%">
        <Row spacingInset="100 400" width="400vw">
          <BreadCrumbs crumbs={crumbs} />
        </Row>
        <Row
          backgroundColor="primary"
          width="100%"
          height="100%"
          alignmentVertical="top"
        >
          {ContextProvider ? (
            <ContextProvider>
              <Component />
            </ContextProvider>
          ) : (
            <Component />
          )}
        </Row>
      </Column>
    );
  };

  return (
    <Routes>
      {routes.map(({ path, Component, ContextProvider }, key) => (
        <Route
          path={path}
          key={key}
          element={getRouteElementWithBreadCrumbs(
            routes,
            path,
            ContextProvider,
            Component
          )}
        />
      ))}
    </Routes>
  );
}
