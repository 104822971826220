import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Loader from "@amzn/meridian/loader";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import {
  PROJECT_DETAIL_TABLE_COLUMNS,
  CH_CHANGE_FIELD,
  CHANGES_FIELD_NAME,
  CH_NEW_SITE,
  CH_DELETED_SITE,
  SITE_FIELD_NAME,
  CHANGE_FIELD_TABLE_COLUMNS,
  CHD_FIELD_NAME,
  CH_ALL_SITES,
  CHD_DEFAULT_COLUMN_SPAN,
} from "../../../constants/ChangeHistoryLayout";
import { usePORContext } from "../../../store/por-context";
import PorApiFactory from "../../../por-api/PlanOfRecordApiFactory";
import { ActiveSiteData } from "../../../por-api/generated-src";
import { processActiveSitesData } from "../../activeProjects/ActiveProjectsUtils";
import { Logger } from "@amzn/kepler-katal-logger";
import isEmpty from "lodash/isEmpty";
import Text from "@amzn/meridian/text";
import { FIELD_GROUP_NAME_TO_TITLE } from "../../../constants";

export default function ChangeHistoryProjectDetailsTable({
  changeHistoryProjectId,
  changeHistoryDetailsData,
}) {
  const { projectTableData } = usePORContext();
  const [changeHistoryProjectDetails, setChangeHistoryProjectDetails] =
    useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState<Array<string>>([]);

  const PorApi = PorApiFactory();

  // load project detail or fetch it from context
  useEffect(() => {
    if (
      !isEmpty(changeHistoryProjectId) &&
      changeHistoryDetailsData[CH_CHANGE_FIELD] != CH_DELETED_SITE
    ) {
      if (projectDetailsAlreadyLoaded(projectTableData)) {
        const projectDetailFromChangeHistory = projectTableData.filter(
          ({ projectId }) => projectId === changeHistoryProjectId
        )[0];
        setChangeHistoryProjectDetails(projectDetailFromChangeHistory);
      } else {
        (async () => {
          setIsLoading(true);
          try {
            const projectDetailsResponse = (
              await PorApi.getActiveSite(changeHistoryProjectId || "")
            ).data as ActiveSiteData;
            if (!isEmpty(projectDetailsResponse)) {
              setChangeHistoryProjectDetails(
                processActiveSitesData([projectDetailsResponse])[0]
              );
            }
          } catch (ex: any) {
            console.error(ex);
            Logger.getInstance().logError(ex);
          } finally {
            setIsLoading(false);
          }
        })();
      }
    }
  }, [projectTableData, changeHistoryProjectId]);

  // Expand the row by default when there's one project
  // Todo: remove this effect after change history can tie to different projectIds
  useEffect(() => {
    updateExpandedRows(changeHistoryProjectId);
  }, [changeHistoryProjectId]);

  const projectDetailsAlreadyLoaded = (projectTableData) => {
    if (isEmpty(projectTableData)) {
      return false;
    }
    return (
      projectTableData.filter(
        ({ projectId }) => projectId === changeHistoryProjectId
      ).length > 0
    );
  };

  const updateExpandedRows = (row) => {
    const newExpandedRows = expandedRows.includes(row)
      ? expandedRows.filter((expandedRow) => expandedRow !== row)
      : [...expandedRows, row];

    setExpandedRows(newExpandedRows);
  };

  const expandCollapseHandler = (isExpandAll) => () => {
    if (isExpandAll) {
      setExpandedRows([changeHistoryProjectId]);
    } else {
      setExpandedRows([]);
    }
  };

  if (isLoading) {
    return (
      <Column>
        <Loader size="medium" />
      </Column>
    );
  }

  if (changeHistoryDetailsData[CH_CHANGE_FIELD] === CH_DELETED_SITE) {
    return (
      <Column>{`${changeHistoryDetailsData[CH_CHANGE_FIELD]}: ${changeHistoryDetailsData[SITE_FIELD_NAME]}`}</Column>
    );
  }

  if (changeHistoryDetailsData[SITE_FIELD_NAME] === CH_ALL_SITES) {
    return (
      <Column>
        <Text type="h100">
          {`New Field in ${FIELD_GROUP_NAME_TO_TITLE[changeHistoryDetailsData[CHANGES_FIELD_NAME][0].category]}`}
        </Text>
        <Text>
          {`${changeHistoryDetailsData[CHANGES_FIELD_NAME][0].fieldName}`}
        </Text>
      </Column>
    );
  }

  return (
    (changeHistoryProjectId && (
      <Column>
        <Row>
          <Button type="secondary" onClick={expandCollapseHandler(true)}>
            Expand All
          </Button>
          <Button type="secondary" onClick={expandCollapseHandler(false)}>
            Collapse All
          </Button>
        </Row>
        <Table headerRows={1} showDividers>
          <TableRow>
            <TableCell>
              {/* Empty table cell to hold the expandable Icon button */}
            </TableCell>
            {PROJECT_DETAIL_TABLE_COLUMNS.map((projectDetailTitle) => (
              <TableCell key={`${projectDetailTitle}-title`}>
                {projectDetailTitle}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell alignmentVertical="top">
              <Button
                type="icon"
                size="small"
                onClick={() => updateExpandedRows(changeHistoryProjectId)}
              >
                {expandedRows.includes(changeHistoryProjectId) ? (
                  <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                ) : (
                  <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                )}
              </Button>
            </TableCell>
            {PROJECT_DETAIL_TABLE_COLUMNS.map((projectDetailTitle) => (
              <TableCell key={`${projectDetailTitle}-value`}>
                {changeHistoryProjectDetails &&
                  changeHistoryProjectDetails[projectDetailTitle] &&
                  changeHistoryProjectDetails[projectDetailTitle].value}
              </TableCell>
            ))}
          </TableRow>
          {expandedRows.includes(changeHistoryProjectId) && (
            <TableRow>
              <TableCell>
                {/* Empty cell for room for the expandable Icon button */}
              </TableCell>
              <TableCell header columnSpan={CHD_DEFAULT_COLUMN_SPAN}>
                Changed Field
              </TableCell>
              <TableCell header columnSpan={CHD_DEFAULT_COLUMN_SPAN}>
                Previous Value
              </TableCell>
              <TableCell header columnSpan={CHD_DEFAULT_COLUMN_SPAN}>
                New Value
              </TableCell>
            </TableRow>
          )}
          {expandedRows.includes(changeHistoryProjectId) &&
          changeHistoryDetailsData[CH_CHANGE_FIELD] === CH_NEW_SITE ? (
            <TableRow>
              <TableCell>
                {/* Empty cell for room for the expandable Icon button */}
              </TableCell>
              <TableCell columnSpan={CHD_DEFAULT_COLUMN_SPAN * 3}>
                {changeHistoryDetailsData[CH_CHANGE_FIELD]}
              </TableCell>
            </TableRow>
          ) : (
            changeHistoryDetailsData[CHANGES_FIELD_NAME].map((changeDiff) => (
              <TableRow
                key={`${changeHistoryProjectId}-${changeDiff[CHD_FIELD_NAME]}-row`}
              >
                <TableCell>
                  {/* Empty cell for room for the expandable Icon button */}
                </TableCell>
                {CHANGE_FIELD_TABLE_COLUMNS.map((changeDiffFieldName) => (
                  <TableCell
                    key={`${changeHistoryProjectId}-${changeDiff[changeDiffFieldName]}-cell`}
                    columnSpan={CHD_DEFAULT_COLUMN_SPAN}
                  >
                    {changeDiff[changeDiffFieldName]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </Table>
      </Column>
    )) || <Column>{`${changeHistoryDetailsData[CH_CHANGE_FIELD]}`}</Column>
  );
}
