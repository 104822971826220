import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Loader from '@amzn/meridian/loader';
import Alert from "@amzn/meridian/alert";
import BRSDetailsColumn from "./BRSDetailsColumn";
import {
  BRS_INFO_COLUMN_NUMBER,
  BRS_SITE_FIELD_NAME,
  CAPACITY_ASK_COLUMN_NUMBER_1,
  CAPACITY_ASK_COLUMN_NUMBER_2,
  SUCCESS_RESPONSE_STATUS,
  ALERT_AUTHENTICATION_MESSAGE
} from "../../constants";
import ConfirmChangeModal from "../modal/ConfirmChangeModal";
import ToasterPORResponse from "../toaster/ToasterPORResponse";
import BRSDetailsContext from "../../store/brs-store";
import PORContext from "../../store/por-context";
import PorApiFactory from '../../por-api/PlanOfRecordApiFactory';
import cloneDeep from "lodash/cloneDeep";
import { BRSSiteRequestBodySchema, ChangeRequestDetails } from "../../por-api/generated-src";
import { Logger } from '@amzn/kepler-katal-logger';

export default function BRSDetailsPage({ siteIdProp }) {
  const { siteId } = useParams<{ siteId: string }>();
  const brsContext = useContext(BRSDetailsContext);
  const porContext = useContext(PORContext);
  const { onOpenToast, canEdit, canDelete } = porContext;
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmChangeModal, setShowConfirmChangeModal] = useState(false);
  const { BRSDetails, setBRSDetails, clearHighlightFields } = brsContext;

  const PorApi = PorApiFactory();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const brsDetailsResponse = (await PorApi.getSiteBRS(siteIdProp || siteId)).data;
        setBRSDetails(brsDetailsResponse);
      } catch (ex: any) {
        console.error(ex);
        Logger.getInstance().logError(ex);
        setShowAlert(true);
        setErrorMessage(ex.message);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setBRSDetails({});
      clearHighlightFields();
      setIsLoading(false);
    }
  }, [siteId, siteIdProp]);

  const brsSiteCodeChangeHandler = (inputValue) => {
    const copyOfBRSDetails = cloneDeep(BRSDetails);
    copyOfBRSDetails[BRS_SITE_FIELD_NAME].value = inputValue;
    setBRSDetails(copyOfBRSDetails);
  }

  const saveBRSDetailsHandler = () => {
    setShowConfirmChangeModal(true);
  }

  const onConfirmCloseChangeModal = useCallback(() => {
    setShowConfirmChangeModal(false);
  }, []);

  const submitChangeHandler = (linksList, reason, team, additionalNotes) => () => {
    let changeRequestDetails: ChangeRequestDetails = {
      reasonForChange: reason,
      approvalLinks: linksList,
      isSuperAdmin: canDelete,
      team: team,
      additionalNotes: additionalNotes
    };
    let brsRequestBody: BRSSiteRequestBodySchema = {
      changeReason: changeRequestDetails,
      changeBody: BRSDetails
    };
    (async () => {
      try {
        const submitBRSDetailsResponse = (await PorApi.updateSiteBRS(
          siteIdProp || siteId,
          brsRequestBody
        )).data as any;
        console.log(submitBRSDetailsResponse)
        if (SUCCESS_RESPONSE_STATUS === submitBRSDetailsResponse) {
          onOpenToast();
          clearHighlightFields();
        }
      } catch (ex: any) {
        console.error(ex);
        Logger.getInstance().logError(ex);
        setShowAlert(true);
        if (ex.response && ex.response.status === 403) {
          setErrorMessage(ALERT_AUTHENTICATION_MESSAGE);
          console.log("403 error");
        }
        else {
          setErrorMessage(ex.message);
        }
      } finally {
        setShowConfirmChangeModal(false);
      }
    })();
  }

  return isLoading ? <Column spacingInset="400"><Loader /></Column> :
    <Column spacingInset="400" width="100%">
      <ConfirmChangeModal
        open={showConfirmChangeModal}
        onClose={onConfirmCloseChangeModal}
        confirmHandler={submitChangeHandler}
      />
      <ToasterPORResponse message="BRS" />
      {showAlert && <Alert
        size="medium"
        type="error"
      >{errorMessage}</Alert>}
      <Row width="100%" alignmentHorizontal="justify">
        <Row>
          <Text>Site</Text>
          {canEdit ? <Input
            value={BRSDetails[BRS_SITE_FIELD_NAME] && BRSDetails[BRS_SITE_FIELD_NAME].value || ""}
            onChange={brsSiteCodeChangeHandler}
            width="22vw"
            placeholder={BRS_SITE_FIELD_NAME}
          ></Input> :
            <Text
            >{BRSDetails[BRS_SITE_FIELD_NAME] && BRSDetails[BRS_SITE_FIELD_NAME].value || ""}</Text>
          }
        </Row>
        <Row>
          {canEdit && <Button onClick={saveBRSDetailsHandler}>Save</Button>}
        </Row>
      </Row>
      <Row
        alignmentVertical="top"
        width="100%"
        widths="fill"
      >
        <BRSDetailsColumn columnNumber={BRS_INFO_COLUMN_NUMBER} />
        <BRSDetailsColumn columnNumber={CAPACITY_ASK_COLUMN_NUMBER_1} />
        <BRSDetailsColumn columnNumber={CAPACITY_ASK_COLUMN_NUMBER_2} />
      </Row>
    </Column >
}