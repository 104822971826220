import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import ProjectFilterByColumnGroup from "../activeProjects/common/ProjectFilterByColumnGroup";
import ProjectsTable from "../activeProjects/ProjectsTable";
import ConfirmChangeModal from "../modal/ConfirmChangeModal";
import ToasterPORResponse from "../toaster/ToasterPORResponse";
import { getProjectsFilterByColumns } from "../activeProjects/ActiveProjectsUtils";
import { getCopyProjectsCandidatesList } from "../copyProjects/CopyProjectsUtils";
import { constructSaveMultipleProjectsChangeBody } from "./SaveMultipleProjectsUtils";
import {
  SAVE_MULTIPLE_PROJECTS_TABLE_TYPE,
  PROJECTS_TABLE_DEFAULT_FILTER,
  ALERT_AUTHENTICATION_MESSAGE,
  SUCCESS_RESPONSE_STATUS,
  DEFAULT_TOASTER_TIMEOUT,
} from "../../constants";
import {
  ChangeRequestDetails,
  UpdateActiveSitesRequest,
} from "../../por-api/generated-src";
import { usePORContext } from "../../store/por-context";
import { useProjectsContext } from "../../store/project-store";
import { colorBlue50 } from "@amzn/meridian-tokens/base/color";
import PorApiFactory from "../../por-api/PlanOfRecordApiFactory";
import { Logger } from "@amzn/kepler-katal-logger";
import isEmpty from "lodash/isEmpty";

export default function SaveMultipleProjectsPage() {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const {
    canEdit,
    canDelete,
    projectTableData,
    defaultColumns,
    onOpenToast,
    projectFieldsReverseMap,
  } = usePORContext();
  const {
    columnFilters,
    setColumnFilters,
    selectedProjects,
    setSelectedProjects,
  } = useProjectsContext();
  const [saveMultipleProjectsCandidates, setSaveMultipleProjectsCandidates] =
    useState<Array<object>>([]);
  const [showConfirmChangeModal, setShowConfirmChangeModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [
    saveMultipleProjectsToasterMessage,
    setSaveMultipleProjectsToasterMessage,
  ] = useState("");

  const PorApi = PorApiFactory();

  useEffect(() => {
    if (locationState && locationState.siteId) {
      setSelectedProjects(new Set([locationState.siteId]));
    }
    setColumnFilters(PROJECTS_TABLE_DEFAULT_FILTER);

    // clean up
    return () => {
      setColumnFilters({});
      setSelectedProjects(new Set());
    };
  }, [locationState]);

  // only do data processing when component mounts to improve browser performance
  useEffect(() => {
    setSaveMultipleProjectsCandidates(
      getCopyProjectsCandidatesList(selectedProjects, projectTableData)
    );
  }, [selectedProjects, projectTableData]);

  const navToProjectDetailsHandler = () => {
    if (locationState && locationState.siteId) {
      navigate(`/active-projects/project-details/${locationState.siteId}`, {
        state: { columnFilters },
      });
    } else {
      navigate(`/active-projects/`);
    }
  };

  const isSaveMultipleProjectsRequestValid = () => {
    if (isEmpty(locationState) || isEmpty(locationState.changeFieldsMap)) {
      setShowAlert(true);
      setErrorMessage(`Nothing changed in projects.`);
      return false;
    }
    setShowAlert(false);
    setErrorMessage("");
    return true;
  };

  const saveProjectsHandler = () => {
    if (!isSaveMultipleProjectsRequestValid()) {
      return;
    }
    setSaveMultipleProjectsToasterMessage("Multiple Projects");
    setShowConfirmChangeModal(true);
  };

  const submitChangeHandler = (linksList, reason, team, additionalNotes) => () => {
    let changeRequestDetails: ChangeRequestDetails = {
      reasonForChange: reason,
      approvalLinks: linksList,
      isSuperAdmin: canDelete,
      team: team,
      additionalNotes: additionalNotes
    };
    let saveMultipleProjectsChangeBody: UpdateActiveSitesRequest = {
      changeReason: changeRequestDetails,
      changeBody: constructSaveMultipleProjectsChangeBody(
        saveMultipleProjectsCandidates,
        locationState.changeFieldsMap,
        projectFieldsReverseMap
      ),
    };

    (async () => {
      let saveMultipleProjectsResponse;
      try {
        saveMultipleProjectsResponse = (
          await PorApi.updateActiveSites(saveMultipleProjectsChangeBody)
        ).data;
      } catch (ex: any) {
        console.error(ex);
        Logger.getInstance().logError(ex);
        setShowAlert(true);
        if (ex.response && ex.response.status === 403) {
          setErrorMessage(ALERT_AUTHENTICATION_MESSAGE);
          console.error("403 error");
        } else {
          setErrorMessage(ex.message);
        }
      } finally {
        setShowConfirmChangeModal(false);
        if (SUCCESS_RESPONSE_STATUS === saveMultipleProjectsResponse) {
          onOpenToast();
          setTimeout(() => {
            navigate(
              `/active-projects/project-details/${locationState.siteId}`
            );
          }, DEFAULT_TOASTER_TIMEOUT);
        }
      }
    })();
  };

  const onConfirmCloseChangeModal = useCallback(() => {
    setShowConfirmChangeModal(false);
  }, []);

  return (
    <Column width="100%" spacingInset="400">
      {showAlert && (
        <Alert size="medium" type="error">
          {errorMessage}
        </Alert>
      )}
      <ConfirmChangeModal
        open={showConfirmChangeModal}
        onClose={onConfirmCloseChangeModal}
        confirmHandler={submitChangeHandler}
      />
      <ToasterPORResponse message={saveMultipleProjectsToasterMessage} />
      <Heading level={4}>Update Multiple Projects</Heading>
      <Row alignmentHorizontal="justify">
        <Text>
          Search for other projects below, select projects you wish to apply
          these changes to. Check beside the project to update it.
        </Text>
        <Row>
          <Button type="tertiary" onClick={navToProjectDetailsHandler}>
            Go Back
          </Button>
          <Button onClick={saveProjectsHandler}>Save Changes</Button>
        </Row>
      </Row>
      <ProjectFilterByColumnGroup
        filteringSourceData={projectTableData}
        filterByColumns={getProjectsFilterByColumns(defaultColumns)}
        sourceDataFilters={columnFilters}
        setSourceDataFilters={setColumnFilters}
        backgroundColor={colorBlue50}
        hasBorder={true}
      />
      <ProjectsTable
        isProjectSelectable={canEdit}
        tableType={SAVE_MULTIPLE_PROJECTS_TABLE_TYPE}
      />
    </Column>
  );
}
