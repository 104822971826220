import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { generateHomeExcelData } from "../activeProjects/ActiveProjectsUtils";
import { HOMEPAGE_TABLE_TYPE } from "../../constants";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";

function getText(dataLength, tableType) {
  if (tableType === HOMEPAGE_TABLE_TYPE) {
    return (
      <Text type="h200" color="secondary" alignment="left">
        Displaying {dataLength} results . Scroll within table to view. Table
        displays current year peak capacity based off selected filters.
      </Text>
    );
  }
  return (
    <Text type="h200" color="secondary" alignment="left">
      Displaying {dataLength} results . Scroll within table to view.
    </Text>
  );
}

export default function TableText({ tableData, tableType }) {
  return (
    <Row widths={["95%", "5%"]}>
      {getText(tableData ? tableData.length : 0, tableType)}
      {tableType === HOMEPAGE_TABLE_TYPE && (
        <div>
          <Button type="icon" onClick={() => generateHomeExcelData(tableData)}>
            <Icon tokens={downloadLargeTokens}>Download</Icon>
          </Button>
        </div>
      )}
    </Row>
  );
}
