import { useState } from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import { INPUT_WIDTH } from "../../constants";
import cloneDeep from "lodash/cloneDeep";

export default function SingleSelectDropdown({
  userSelectValue,
  changeValueHandler,
  fieldGroupName,
  fieldName,
  options,
  error,
  errorMessage,
  ...otherProps
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const originalOptions = cloneDeep(options);
  let matchedOptions = [
    ...new Set([
      ...originalOptions.filter(
        (option) => !searchQuery || option.includes(searchQuery)
      ),
      searchQuery,
    ]),
  ].sort();
  let completeOptions = [...new Set([...originalOptions, searchQuery])].sort();

  return (
    <Select
      value={userSelectValue}
      onChange={changeValueHandler(fieldGroupName, fieldName, completeOptions)}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      width={INPUT_WIDTH}
      error={error}
      errorMessage={errorMessage}
      {...otherProps}
    >
      {matchedOptions.map((optionValue) => (
        <SelectOption
          key={optionValue}
          value={optionValue}
          label={optionValue}
        />
      ))}
    </Select>
  );
}
