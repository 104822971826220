import isEmpty from "lodash/isEmpty";
import { PROJECT_ID, INPUT_FIELDS_TO_COPY } from "../../constants";
import { convertFlatProjectDataToGrouped } from "../saveMultipleProjects/SaveMultipleProjectsUtils";
import { ActiveSiteValues } from "../../por-api/generated-src";

/**
 * @summary util to get copyProjectDataLists from selectedProjectIds
 * @param {Set} [selectedProjectIds] selected projectIds from previous ProjectsPage
 * @param {Array<object>} [projectsList] full projectLists
 * @return {Array<object>} a filtered projectsList based on selectedProjectIds
 * **/
export const getCopyProjectsCandidatesList = (selectedProjectIds, projectsList) => {
  if (isEmpty(selectedProjectIds) || isEmpty(projectsList)) {
    return [];
  }
  const copyProjectDataLists = projectsList.filter((projectData) => selectedProjectIds.has(projectData[PROJECT_ID]));
  return copyProjectDataLists;
}

/**
 * @summary util to construct copy projects change body
 * @param {Array<object>} [projectsCandidatesList] projects candidates that shows in copy projects page
 * @param {object} [copyProjectsFields] copy change fields values
 * @param {object} [projectFieldsReverseMap] a reverse map to convert flattened projects to group structured
 * @param {boolean} [overrideExistingProjectsFlag] flag to indicate update or create
 * @return {Array<object>} group structured project list
 * **/
export const constructCopyProjectsChangeBody = (projectsCandidatesList, copyProjectsFields, projectFieldsReverseMap, overrideExistingProjectsFlag):  Array<ActiveSiteValues> => {
  let projectsToCopyList: Array<object> = [];
  for (let projectsCandidate of projectsCandidatesList) {
    let copyCandidate = convertFlatProjectDataToGrouped(projectsCandidate, copyProjectsFields, projectFieldsReverseMap);
    if (overrideExistingProjectsFlag) {
      copyCandidate[PROJECT_ID] = projectsCandidate[PROJECT_ID];  // only apply when override existing projects
    }
    projectsToCopyList.push(copyCandidate);
  }
  return projectsToCopyList;
}

/**
 * @summary util to validate change field form, for required fields
 * @param {object} [copyProjectsFields] change field form values
 * @return {boolean} validate result
 * **/
export const validateCopyProjectsFields = (copyProjectsFields) => {
  const requiredCopyFields = INPUT_FIELDS_TO_COPY.filter(({ isRequired }) => isRequired);
  for(let requiredCopyField of requiredCopyFields) {
    if (isEmpty(copyProjectsFields[requiredCopyField.fieldName])) {
      return false;
    }
  }
  return true;
}

/**
 * @summary util to convert copyProjectsFields passed from routing to columnFilters
 * @param {object} [copyProjectsFields] change field form values
 * @return {object} columnFilters
 * **/
export const convertCopyProjectsFieldsToColumnFilters = (copyProjectsFields) => {
  let columnFilters = {};
  Object.keys(copyProjectsFields).forEach((copyField) => {
    if (isEmpty(copyProjectsFields[copyField])) return;
    columnFilters[copyField] = [copyProjectsFields[copyField]].flat().map(String);
  });
  return columnFilters;
}