import cloneDeep from "lodash/cloneDeep";
import {POR_CODE_COLUMN_NAME, PROJECT_ID} from "../../constants";

export function convertActiveSiteDataToActiveSiteValues(projectDetails) {
  let clonedProjectDetails = cloneDeep(projectDetails);
  Object.keys(clonedProjectDetails)
      .filter(key => key != PROJECT_ID)
      .forEach((fieldGroupName) => {
    let reducedFieldGroup = clonedProjectDetails[fieldGroupName].reduce((prev, fieldData) => {
      let { fieldName, value } = fieldData;
      prev[fieldName] = value;
      return prev;
    }, {});
    clonedProjectDetails[fieldGroupName] = reducedFieldGroup;
  });
  return clonedProjectDetails;
}

export function getPORCodeFromProjectDetails(projectDetails) {
  const { siteDetails } = projectDetails;
  const porCodeField = siteDetails.filter((siteDetail) => siteDetail.fieldName === POR_CODE_COLUMN_NAME);
  return porCodeField.length ? porCodeField[0].value : "";
}

export function convertLocalStringToNumber(localString) {
  if (typeof localString === "number") {
    return localString;
  }
  return parseFloat(localString.replace(/,/g, ''));
}