import React from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {
  ID_FIELD_NAME,
  CHANGE_HISTORY_STATUS_COLUMN_LAYOUT,
  CHANGES_FIELD_NAME,
  CHANGE_HISTORY_DETAIL_TITLE_MAP,
} from "../../../constants/ChangeHistoryLayout";
import isEmpty from "lodash/isEmpty";

/*
Layout is driven by constant: CHANGE_HISTORY_STATUS_COLUMN_LAYOUT
*/
export default function ChangeHistoryStatusColumn({
  changeHistoryDetailsData,
}) {
  const getStatusTextValue = (changeHistoryStatusFieldName) => {
    if (isEmpty(changeHistoryDetailsData)) {
      return "";
    }
    if (changeHistoryStatusFieldName === CHANGES_FIELD_NAME) {
      return changeHistoryDetailsData[CHANGES_FIELD_NAME].length;
    } else {
      return changeHistoryDetailsData[changeHistoryStatusFieldName];
    }
  };

  return (
    <Column>
      {CHANGE_HISTORY_STATUS_COLUMN_LAYOUT.map(
        (changeHistoryStatusRowFields) => (
          <Row
            key={`${changeHistoryDetailsData[ID_FIELD_NAME]}-${changeHistoryStatusRowFields[0]}-row`}
            alignmentVertical="top"
          >
            {changeHistoryStatusRowFields.map(
              (changeHistoryStatusFieldName) => {
                return (
                  <Column key={`${changeHistoryStatusFieldName}-data-text`}>
                    <Text type="h100">
                      {
                        CHANGE_HISTORY_DETAIL_TITLE_MAP[
                          changeHistoryStatusFieldName
                        ]
                      }
                    </Text>
                    <Text>
                      {getStatusTextValue(changeHistoryStatusFieldName)}
                    </Text>
                  </Column>
                );
              }
            )}
          </Row>
        )
      )}
    </Column>
  );
}
