import React, { useContext, useState } from "react";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Textarea from "@amzn/meridian/textarea";
import Link from "@amzn/meridian/link";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import linkTokens from "@amzn/meridian-tokens/base/icon/link";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import {
  FIELD_GROUP_NAME_TO_TITLE,
  INPUT_WIDTH,
  DROPDOWN_TYPE,
  DATE_TYPE,
  LINK_TYPE,
  TEXT_AREA_TYPE,
  SITE_COLUMN_NAME,
  MHE_COLUMNS,
  MHE_COLUMN_NAME,
  EQUIPMENT_GROUP,
} from "../../constants";
import PROJECT_DETAILS_FORMULA_MAP from "../../constants/ProjectDetailsFormulaMap";
import DatePicker from "@amzn/meridian/date-picker";
import { colorBlue100 } from "@amzn/meridian-tokens/base/color";
import SingleSelectDropdown from "./SingleSelectDropdown";
import { formatProjectFields } from "../activeProjects/ActiveProjectsUtils";
import { formatTemplate as formulaTemplate } from "../BRS/BRSUtils";
import { convertLocalStringToNumber } from "./ProjectDetailsUtils";
import { useProjectDetailsContext } from "../../store/project-details-store";
import PORContext from "../../store/por-context";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { Parser } from "hot-formula-parser";
import {stringify} from "querystring";

export default function ProjectDetailsColumn({
  fieldGroups,
  requiredFields,
  setRequiredFields,
  changeFieldsHandler,
}) {
  const porContext = useContext(PORContext);
  const {
    projectDetails,
    setProjectDetails,
    showAdminView,
    highlightFields,
    addHighlightFields,
    clearHighlightFields,
  } = useProjectDetailsContext();
  const { canEdit, projectFieldsReverseMap } = porContext;
  const [projectDetailHasChanged, setProjectDetailHasChanged] = useState(false);
  let parser = new Parser();

  const setProjectDetailsValue = (
    fieldGroup,
    fieldName,
    options,
    inputValue
  ) => {
    const copyOfProjectDetails = cloneDeep(projectDetails);
    const groupFieldToModify = copyOfProjectDetails[fieldGroup];
    if (fieldGroup === EQUIPMENT_GROUP[0] && fieldName !== MHE_COLUMN_NAME) {
      // set MHE field once any field has changed
      for (let groupField of groupFieldToModify) {
        if (groupField.fieldName === MHE_COLUMN_NAME) {
          groupField.value = getMHEValue(groupFieldToModify);
        }
      }
    }
    for (let i = 0; i < groupFieldToModify.length; i++) {
      if (fieldName === groupFieldToModify[i].fieldName) {
        if (fieldName === SITE_COLUMN_NAME) {
          // special check to make Site field always upper case
          inputValue = inputValue.toUpperCase();
        }
        groupFieldToModify[i].value = inputValue;
        options !== null ? (groupFieldToModify[i].options = options) : null;
      }
    }
    setProjectDetails(copyOfProjectDetails);
    changeFieldsHandler(fieldName, inputValue);
  };

  const updateProjectDetailsValue = (
    fieldGroup,
    fieldName,
    options,
    inputValue
  ) => {
    // state manage for Project Details
    setProjectDetailsValue(fieldGroup, fieldName, options, inputValue);
    // state manage for required fields
    let localRequiredFields = cloneDeep(requiredFields);
    localRequiredFields[fieldName] != undefined
      ? (localRequiredFields[fieldName] = inputValue === "")
      : null;
    setRequiredFields(localRequiredFields);
    // state manage for formulas
    if (fieldName.replace(/\s+/g, " ") in PROJECT_DETAILS_FORMULA_MAP) {
      setProjectDetailHasChanged(true);
    }
  };

  const changeProjectDetailHandler =
    (fieldGroup, fieldName, options = null) =>
    (inputValue) => {
      updateProjectDetailsValue(fieldGroup, fieldName, options, inputValue);
    };

  const getMHEValue = (projectDetailsGroupData) => {
    let calculatedValue = "";
    MHE_COLUMNS.forEach((column) => {
      let tempValue = getProjectDetailsValue(projectDetailsGroupData, column);
      if (/^\+?(0|[1-9]\d*)$/.test(tempValue) && !tempValue.includes("0")) {
        calculatedValue += `(${tempValue}) ${column} + `;
      }
    });
    calculatedValue = calculatedValue.slice(0, -3);
    return calculatedValue;
  };

  const getCalculatedValue = (fieldName, fieldGroupName) => {
    let copyProjectDetails = cloneDeep(projectDetails);
    let projectDetailsGroupData = copyProjectDetails[fieldGroupName];
    let value = getProjectDetailsValue(projectDetailsGroupData, fieldName);

    if (fieldName == MHE_COLUMN_NAME) {
      let calculatedValue = getMHEValue(projectDetailsGroupData);
      if (calculatedValue !== value) value = calculatedValue;
    }
    return String(value);
  };

  const checkIsEmpty = (fieldName) => {
    return requiredFields[fieldName] === true;
  };

  const calculateFormula = (
    copyOfProjectDetails,
    template,
    params,
    fieldName
  ) => {
    const projectDetailsGroupFieldName = projectFieldsReverseMap[fieldName];
    let loadParamValues = params.map((param) =>
      convertLocalStringToNumber(
        getProjectDetailsValue(
          copyOfProjectDetails[projectDetailsGroupFieldName],
          param
        )
      )
    );
    let formattedTemplate = formulaTemplate(template, ...loadParamValues);
    return parser.parse(formattedTemplate);
  };

  const updateFormula = (fieldName) => () => {
    clearHighlightFields(); // clean up previous highlighted fields

    if (projectDetailHasChanged) {
      const sanitizedFieldName = fieldName.replace(/\s+/g, " ");
      let copyOfProjectDetails = cloneDeep(projectDetails);
      let { resultFields } = PROJECT_DETAILS_FORMULA_MAP[sanitizedFieldName];
      if (resultFields && resultFields.length) {
        resultFields.forEach((resultField) => {
          let { template, params } = PROJECT_DETAILS_FORMULA_MAP[resultField];
          let { error, result } = calculateFormula(
            copyOfProjectDetails,
            template,
            params,
            fieldName
          );
          if (error) {
            console.error(`Error calculating ${resultField} with ${template}.`);
          } else {
            addHighlightFields(resultField);
            let resultFieldGroupName = projectFieldsReverseMap[resultField];
            const groupFieldToModify =
              copyOfProjectDetails[resultFieldGroupName];
            for (let i = 0; i < groupFieldToModify.length; i++) {
              if (resultField === groupFieldToModify[i].fieldName) {
                copyOfProjectDetails[resultFieldGroupName][i].value = String(result);
              }
            }
          }
        });
      }
      setProjectDetails(copyOfProjectDetails);
    }
    setProjectDetailHasChanged(false);
  };

  const getFieldFromType = (
    fieldGroupName,
    fieldName,
    value,
    type,
    required,
    options
  ) => {
    if (!showAdminView || !canEdit)
      return (
        <Row spacingInset="300">
          <Text>{value}</Text>
        </Row>
      );
    // show admin editable form
    switch (type) {
      case TEXT_AREA_TYPE:
        return (
          <Textarea
            value={value}
            onChange={changeProjectDetailHandler(fieldGroupName, fieldName)}
            placeholder="Enter value..."
            width={INPUT_WIDTH}
          />
        );
      case DROPDOWN_TYPE:
        return (
          <SingleSelectDropdown
            userSelectValue={value}
            changeValueHandler={changeProjectDetailHandler}
            fieldGroupName={fieldGroupName}
            fieldName={fieldName}
            options={options}
            error={checkIsEmpty(fieldName)}
            errorMessage={
              checkIsEmpty(fieldName) ? "This field is required." : null
            }
          />
        );
      case LINK_TYPE:
        return (
          <Row width={INPUT_WIDTH}>
            <Input
              value={value}
              onChange={changeProjectDetailHandler(fieldGroupName, fieldName)}
              error={checkIsEmpty(fieldName)}
              errorMessage={
                checkIsEmpty(fieldName) ? "This field is required." : null
              }
            />
            <Link href={value} target="_blank" rel="noopener noreferrer">
              <Icon tokens={linkTokens}></Icon>
            </Link>
          </Row>
        );
      case DATE_TYPE:
        return (
          <Row width={INPUT_WIDTH}>
            <DatePicker
              value={!value ? value : new Date(value).toISOString()}
              locale="en-CA"
              onChange={changeProjectDetailHandler(fieldGroupName, fieldName)}
            />
          </Row>
        );
      default:
        return (
          <Input
            value={formatProjectFields(value, fieldName)}
            onChange={changeProjectDetailHandler(fieldGroupName, fieldName)}
            width={INPUT_WIDTH}
            required={required}
            onBlur={updateFormula(fieldName)}
            error={checkIsEmpty(fieldName)}
            errorMessage={
              checkIsEmpty(fieldName) ? "This field is required." : null
            }
          />
        );
    }
  };

  const getProjectDetailsValue = (projectDetailsGroupData, fieldName) => {
    if (isEmpty(projectDetailsGroupData)) return;

    const projectDetailsInGroup = projectDetailsGroupData.filter((e) => {
      return e.fieldName.replace(/\s+/g, "") === fieldName.replace(/\s+/g, "");
    });
    return projectDetailsInGroup.length ? projectDetailsInGroup[0].value : null;
  };

  return (
    <Column width="30%">
      {fieldGroups.map((fieldGroupName) => (
        <Row key={`${fieldGroupName}-Card`} spacingInset="200 200">
          <Column>
            <Heading level={4}>
              {FIELD_GROUP_NAME_TO_TITLE[fieldGroupName]}
            </Heading>
            <Card>
              {Object.keys(projectDetails).length > 0 &&
                projectDetails[fieldGroupName] &&
                projectDetails[fieldGroupName].length > 0 &&
                projectDetails[fieldGroupName].map(
                  ({ fieldName, value, type, required, options }) => (
                    <Row
                      alignmentHorizontal="justify"
                      minWidth={400}
                      key={`${fieldName}-Input`}
                      spacingInset="100 none"
                      backgroundColor={
                        highlightFields.has(fieldName)
                          ? colorBlue100
                          : "primary"
                      }
                    >
                      <Row>
                        <Text type="b300">{fieldName}</Text>
                        {required === true ? (
                          <Text color="error">{"*"}</Text>
                        ) : null}
                      </Row>
                      {fieldName == "First Possible EB CPT" && (
                        <Tooltip
                          position="top"
                          title="The time mentioned is in the local timezone"
                        >
                          <Text>
                            {" "}
                            <Icon tokens={helpKnockoutTokens} />
                          </Text>
                        </Tooltip>
                      )}
                      {getFieldFromType(
                        fieldGroupName,
                        fieldName,
                        getCalculatedValue(fieldName, fieldGroupName),
                        type,
                        required,
                        options
                      )}
                    </Row>
                  )
                )}
            </Card>
          </Column>
        </Row>
      ))}
    </Column>
  );
}
