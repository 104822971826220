import React, { createContext, useCallback, useState } from "react";
import {
  POR_SUPER_ADMIN,
  DEFAULT_TOASTER_TIMEOUT,
  POR_ADMIN,
} from "../constants";
import { ToasterToasts } from "@amzn/meridian/toaster/toaster";
import { MapDetail } from "../por-api/generated-src";
import {processActiveSitesData} from "../components/activeProjects/ActiveProjectsUtils";

interface PORStateProps {
  isSideMenuOpen: boolean;

  toggleMenuBar(): any;

  toasts: Array<ToasterToasts>;

  toastsWithoutTimeout: Array<ToasterToasts>; // Limit 1 toast without timeout across whole website

  onOpenToast(timeout?): any;

  onOpenToastWithoutTimeout(): any;

  onCloseToast(id): any;

  onCloseToastWithoutTimeout(id): any;

  user: string;

  setUser(user): any;

  canEdit: boolean;

  canDelete: boolean;

  mapDetails: Array<MapDetail>;

  setMapDetails(mapDetails: Array<MapDetail>): any;

  dashboardObject: any;

  setDashboardObject(dashboardData): any;

  capacityYearObject: any;

  setCapacityYearObject(capacity): any;

  projectsData: any;

  setProjectsData(projectData): any;

  updateProjects(projectData): any;

  clearProjects(): any;

  hasLoadedProjects: any;

  updateProject(projectData): any;

  setProjects(projectData): any;

  projectTableData: any;

  setProjectTableData(projectData): any;

  preloadingProjectData: boolean;

  setPreloadingProjectData(flag): any;

  brsTableData: any;

  setBrsTableData(brsData): any;

  changeHistoryData: any;

  setChangeHistoryData(changeHistoryData): any;

  sortedColumns: Array<string>;

  setSortedColumns(columns): any;

  defaultColumns: Array<string>;

  setDefaultColumns(columns): any;

  isProjectsLoading: boolean; // used by multiple components to indicate api loading

  setIsProjectsLoading(isProjectsLoading: boolean): void;

  projectFieldsReverseMap: object; // used by Copy Projects

  setProjectFieldsReverseMap(projectFieldsReverseMap: object): void;
}

export const initialState: PORStateProps = {
  isSideMenuOpen: true,
  toggleMenuBar: () => {},
  toasts: [],
  toastsWithoutTimeout: [],
  onOpenToast: (timeout?) => {},
  onOpenToastWithoutTimeout: () => {},
  onCloseToast: (id) => {},
  onCloseToastWithoutTimeout: (id) => {},
  user: "",
  setUser: () => {},
  canEdit: false,
  canDelete: false,
  sortedColumns: [],
  setSortedColumns: () => {},
  defaultColumns: [],
  setDefaultColumns: () => {},
  mapDetails: [],
  setMapDetails: () => {},
  dashboardObject: [],
  setDashboardObject: () => {},
  capacityYearObject: {},
  setCapacityYearObject: () => {},
  projectsData: [],
  setProjectsData: () => {},
  updateProjects: () => {},
  clearProjects: () => {},
  hasLoadedProjects: false,
  updateProject: () => {},
  setProjects: () => {},
  projectTableData: [],
  setProjectTableData: () => {},
  preloadingProjectData: false,
  setPreloadingProjectData: () => {},
  brsTableData: [],
  setBrsTableData: () => {},
  changeHistoryData: [],
  setChangeHistoryData: () => {},
  isProjectsLoading: false,
  setIsProjectsLoading: () => {},
  projectFieldsReverseMap: {},
  setProjectFieldsReverseMap: () => {},
};

const PORContext = createContext(initialState);

export function PORContextProvider({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [userToasts, setUserToasts] = useState<Array<ToasterToasts>>([]);
  const [userToastsWithoutTimeout, setUserToastsWithoutTimeout] = useState<
    Array<ToasterToasts>
  >([]);
  const [userType, setUserType] = useState(initialState.user);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [userCanDelete, setUserCanDelete] = useState(false);
  const [storeMapDetails, setStoreMapDetails] = useState<Array<MapDetail>>([]);
  const [storeDashboardData, setstoreDashboardData] = useState([]);
  const [storeCapacityTable, setStoreCapacityTable] = useState({});
  const [projectTableData, setProjectTableData] = useState([]);
  const [projectsData, setProjectsData] = useState<Array<any>>([]);
  const [hasLoadedProjects, setHasLoadedProjects] = useState(false);
  const [preloadingProjectData, setPreloadingProjectData] = useState(false);
  const [brsTableData, setBrsTableData] = useState([]);
  const [changeHistoryData, setChangeHistoryData] = useState([]);
  const [columns, setColumns] = useState<Array<string>>([]);
  const [defaultColumns, setDefaultTableColumns] = useState<Array<string>>([]);
  let dummyId = 0;
  const [userIsProjectsLoading, setUserIsProjectsLoading] = useState(
    initialState.isProjectsLoading
  );
  const [userProjectFieldsReverseMap, setUserProjectFieldsReverseMap] =
    useState(initialState.projectFieldsReverseMap);

  function setSortedColumns(columns) {
    setColumns(columns);
  }

  function setDefaultColumns(columns) {
    setDefaultTableColumns(columns);
  }

  function toggleMenuBar() {
    setIsMenuOpen((prevIsOpen) => !prevIsOpen);
  }

  const onCloseToast = useCallback(
    (id) => setUserToasts(userToasts.filter((toast) => toast.id !== id)),
    [userToasts]
  );

  const onOpenToast = useCallback(
    (timeout?) =>
      setUserToasts(
        userToasts.concat({
          id: `${++dummyId}`,
          timeout: timeout || DEFAULT_TOASTER_TIMEOUT,
        })
      ),
    [userToasts]
  );

  const onOpenToastWithoutTimeout = useCallback(
    () => setUserToastsWithoutTimeout([{ id: `${++dummyId}` }]), // Limit 1 toast without timeout across whole website
    [userToasts]
  );

  const onCloseToastWithoutTimeout = useCallback(
    (id) => setUserToastsWithoutTimeout([]), // Limit 1 toast without timeout across whole website
    [userToasts]
  );

  function setUser(user) {
    setUserType(user);
    setUserCanEdit(user === POR_SUPER_ADMIN || user === POR_ADMIN);
    setUserCanDelete(user === POR_SUPER_ADMIN);
  }

  function setProjects(data) {
    setHasLoadedProjects(true);
    setProjectsData(data);
    let tableData = processActiveSitesData(data);
    setProjectTableData(tableData as any);
  }

  function updateProjects(data) {
    setHasLoadedProjects(true);
    setProjectsData(prevData => prevData.concat(data));
    let tableData = processActiveSitesData(data);
    setProjectTableData(prevData => prevData.concat(tableData as any));
  }

  function clearProjects() {
    setProjectsData([]);
    setProjectTableData([]);
  }

  function updateProject(project) {
    let updatedProjectsData = projectsData.filter(data => data.projectId != project.projectId);
    updatedProjectsData = updatedProjectsData.concat(project);
    setProjectTableData(processActiveSitesData(updatedProjectsData) as any);
    setProjectsData(updatedProjectsData)
  }

  function setMapDetails(mapDetailData) {
    setStoreMapDetails(mapDetailData);
  }

  function setDashboardObject(upcomingProjectTable) {
    setstoreDashboardData(upcomingProjectTable);
  }

  function setCapacityYearObject(capacityTable) {
    setStoreCapacityTable(capacityTable);
  }

  function setIsProjectsLoading(isProjectsLoading) {
    setUserIsProjectsLoading(isProjectsLoading);
  }

  function setProjectFieldsReverseMap(projectFieldsReverseMap) {
    setUserProjectFieldsReverseMap(projectFieldsReverseMap);
  }

  const context: PORStateProps = {
    isSideMenuOpen: isMenuOpen,
    toggleMenuBar,
    toasts: userToasts,
    toastsWithoutTimeout: userToastsWithoutTimeout,
    onOpenToast,
    onOpenToastWithoutTimeout,
    onCloseToast,
    onCloseToastWithoutTimeout,
    user: userType,
    setUser,
    canEdit: userCanEdit,
    canDelete: userCanDelete,
    mapDetails: storeMapDetails,
    setMapDetails,
    dashboardObject: storeDashboardData,
    setDashboardObject,
    capacityYearObject: storeCapacityTable,
    setCapacityYearObject,
    projectsData: projectsData,
    setProjectsData,
    updateProjects,
    clearProjects,
    hasLoadedProjects,
    updateProject,
    setProjects,
    projectTableData: projectTableData,
    setProjectTableData,
    preloadingProjectData: preloadingProjectData,
    setPreloadingProjectData,
    brsTableData: brsTableData,
    setBrsTableData,
    changeHistoryData: changeHistoryData,
    setChangeHistoryData,
    sortedColumns: columns,
    setSortedColumns,
    defaultColumns: defaultColumns,
    setDefaultColumns,
    isProjectsLoading: userIsProjectsLoading,
    setIsProjectsLoading,
    projectFieldsReverseMap: userProjectFieldsReverseMap,
    setProjectFieldsReverseMap,
  };

  return <PORContext.Provider value={context}>{children}</PORContext.Provider>;
}

export const usePORContext = () => React.useContext(PORContext);

export default PORContext;
