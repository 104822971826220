import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import CopyProjectsTable from "./CopyProjectsTable";
import { colorBlue500 } from "@amzn/meridian-tokens/base/color";
import CopyProjectsChangeFieldsGroup from "./CopyProjectsChangeFieldsGroup";
import {
  getCopyProjectsCandidatesList,
  constructCopyProjectsChangeBody,
  validateCopyProjectsFields,
} from "./CopyProjectsUtils";
import ConfirmChangeModal from "../modal/ConfirmChangeModal";
import ToasterPORResponse from "../toaster/ToasterPORResponse";
import {
  DEFAULT_TOASTER_TIMEOUT,
  ALERT_AUTHENTICATION_MESSAGE,
  INPUT_FIELDS_TO_COPY_STATES,
  SUCCESS_RESPONSE_STATUS,
  SITE_COLUMN_NAME,
} from "../../constants";
import { usePORContext } from "../../store/por-context";
import { useProjectsContext } from "../../store/project-store";
import {
  UpdateActiveSitesRequest,
  ChangeRequestDetails,
} from "../../por-api/generated-src";
import { css } from "emotion";
import PorApiFactory from "../../por-api/PlanOfRecordApiFactory";
import { Logger } from "@amzn/kepler-katal-logger";

export const blueText = css({
  color: colorBlue500,
});

export default function CopyProjectsPage() {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const {
    canEdit,
    canDelete,
    projectTableData,
    projectFieldsReverseMap,
    setProjectTableData,
    onOpenToast,
  } = usePORContext();
  const { selectedProjects, setSelectedProjects } = useProjectsContext();
  const [overrideExistingProjectsFlag, setOverrideExistingProjectsFlag] =
    useState(false);
  const [projectsCandidatesList, setProjectsCandidatesList] = useState<
    Array<object>
  >([]);
  const [copyProjectsFields, setCopyProjectFields] = useState<object>(
    INPUT_FIELDS_TO_COPY_STATES
  ); // { [changeFieldName]: value }
  const [showConfirmChangeModal, setShowConfirmChangeModal] = useState(false);
  const [copyProjectsToasterMessage, setCopyProjectsToasterMessage] =
    useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const PorApi = PorApiFactory();

  // only do data processing when component mounts to improve browser performance
  useEffect(() => {
    setProjectsCandidatesList(
      getCopyProjectsCandidatesList(selectedProjects, projectTableData)
    );
  }, [selectedProjects, projectTableData]);

  useEffect(() => {
    if (locationState && locationState.selectedProjectFromRouting) {
      setSelectedProjects(new Set(locationState.selectedProjectFromRouting));
    }
  }, [locationState]);

  const copyProjectsHandler = () => {
    setCopyProjectsToasterMessage("Copy Projects. Redirecting");
    if (
      projectsCandidatesList.length &&
      validateCopyProjectsFields(copyProjectsFields)
    ) {
      setShowConfirmChangeModal(true);
    }
  };

  const cancelAndGoBackHandler = () => {
    navigate(`/active-projects/`);
  };

  const onConfirmCloseChangeModal = useCallback(() => {
    setShowConfirmChangeModal(false);
  }, []);

  const submitChangeHandler = (linksList, reason, team, additionalNotes) => () => {
    let changeRequestDetails: ChangeRequestDetails = {
      reasonForChange: reason,
      approvalLinks: linksList,
      isSuperAdmin: canDelete,
      team: team,
      additionalNotes: additionalNotes
    };
    let copyProjectsChangeBody: UpdateActiveSitesRequest = {
      changeReason: changeRequestDetails,
      changeBody: constructCopyProjectsChangeBody(
        projectsCandidatesList,
        copyProjectsFields,
        projectFieldsReverseMap,
        overrideExistingProjectsFlag
      ),
    };

    (async () => {
      let copyProjectsResponse;
      try {
        copyProjectsResponse = overrideExistingProjectsFlag
          ? (await PorApi.updateActiveSites(copyProjectsChangeBody)).data
          : (await PorApi.createActiveSites(copyProjectsChangeBody)).data;
      } catch (ex: any) {
        console.error(ex);
        Logger.getInstance().logError(ex);
        setShowAlert(true);
        if (ex.response && ex.response.status === 403) {
          setErrorMessage(ALERT_AUTHENTICATION_MESSAGE);
          console.error("403 error");
        } else {
          setErrorMessage(ex.message);
        }
      } finally {
        setShowConfirmChangeModal(false);
        if (SUCCESS_RESPONSE_STATUS === copyProjectsResponse) {
          onOpenToast();
          setTimeout(() => {
            setProjectTableData([]); // clean up projectsTableData to trigger reload
            const filtersToDisplay = // add siteId as filter for single copy
              projectsCandidatesList.length === 1
                ? {
                    ...copyProjectsFields,
                    [SITE_COLUMN_NAME]:
                      projectsCandidatesList[0][SITE_COLUMN_NAME].value,
                  }
                : copyProjectsFields;
            navigate(`/active-projects`, {
              state: { copyProjectsFieldsFromRouting: filtersToDisplay },
            });
          }, DEFAULT_TOASTER_TIMEOUT);
        }
      }
    })();
  };

  return (
    <Column width="100%" spacingInset="400">
      {showAlert && (
        <Alert size="medium" type="error">
          {errorMessage}
        </Alert>
      )}
      <ConfirmChangeModal
        open={showConfirmChangeModal}
        onClose={onConfirmCloseChangeModal}
        confirmHandler={submitChangeHandler}
      />
      <ToasterPORResponse message={copyProjectsToasterMessage} />
      <Row alignmentHorizontal="end">
        <Button type="secondary" onClick={cancelAndGoBackHandler}>
          Cancel and Go Back
        </Button>
        {canEdit && (
          <Button type="primary" onClick={copyProjectsHandler}>
            Copy Projects
          </Button>
        )}
      </Row>
      <Row widths={["70%", "20%"]} spacing="500" alignmentVertical="top">
        <Column>
          <Heading level={5}>Copying Selected Projects</Heading>
          <Text className={blueText}>
            {`${selectedProjects.size} Projects Selected`}
          </Text>
          <CopyProjectsTable
            projectsCandidatesList={projectsCandidatesList}
            setProjectsCandidatesList={setProjectsCandidatesList}
          />
        </Column>
        <Column>
          <Heading level={5}>Change Fields</Heading>
          <Checkbox
            checked={overrideExistingProjectsFlag}
            onChange={setOverrideExistingProjectsFlag}
          >
            {" "}
            Override Existing Projects{" "}
          </Checkbox>
          <CopyProjectsChangeFieldsGroup
            copyProjectsFields={copyProjectsFields}
            setCopyProjectFields={setCopyProjectFields}
          />
        </Column>
      </Row>
    </Column>
  );
}
