import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Checkbox from "@amzn/meridian/checkbox";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import minusTokens from "@amzn/meridian-tokens/base/icon/minus";
import checkTokens from "@amzn/meridian-tokens/base/icon/check-large";
import { colorBlue100 } from "@amzn/meridian-tokens/base/color";
import TableCellWithLongValue from "./common/TableCellWithLongValue";
import {
  PROJECTS_COLUMN_HEADER_TOOLTIP_MAP,
  MAX_CONTENT_LENGTH,
  LARGE_CELL_WIDTH,
  SMALL_CELL_WIDTH,
  PROJECT_ID,
  DEFAULT_ITEMS_PER_PAGE,
  SITE_COLUMN_NAME,
  COMPACT_VIEW,
  FIXED_TABLE_COLUMNS,
  HOMEPAGE_TABLE_COLUMNS,
  HOMEPAGE_TABLE_TYPE,
  CAPACITY_YEAR_COLUMN_NAME,
  TIMELINE_COLUMN_NAME,
  PROGRAM_TYPE_COLUMN_NAME,
  SAVE_MULTIPLE_PROJECTS_TABLE_TYPE,
} from "../../constants";
import useLoadProjectsEffect, {
  useLoadOnePageProjectsEffect,
} from "../../api/hooks/useLoadProjectsEffect";
import {
  formatProjectFields,
  getCalculatedValue,
  compareProjectsFn,
  getCompactViewProjectMapBySite,
  filterProjectsByDefault,
  compareByCapacityYear,
  userFilterGlobalMethod,
  userInputFilterMethod,
} from "./ActiveProjectsUtils";
import { usePORContext } from "../../store/por-context";
import { useProjectsContext } from "../../store/project-store";
import has from "lodash/has";
import cloneDeep from "lodash/cloneDeep";
import "./ActiveProjects.scss";
import TableText from "../tableText/TableText";

/**
 * @props {boolean} [isProjectSelectable] - flag to determine display checkboxes on siteId
 * @props {const string} [tableType] - one of [HOMEPAGE_TABLE_TYPE, PROJECTPAGE_TABLE_TYPE, SAVE_MULTIPLE_PROJECTS_TABLE_TYPE]
 * **/
export default function ProjectsTable({ isProjectSelectable, tableType }) {
  const navigate = useNavigate();
  const {
    projectTableData,
    setProjectTableData,
    sortedColumns,
    isProjectsLoading,
  } = usePORContext();
  const {
    columnFilters,
    selectedProjects,
    setSelectedProjects,
    searchProjectsFilter,
    projectsViewType,
  } = useProjectsContext();
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] =
    useState<TableSortDirection>("ascending");
  const [expandedRows, setExpandedRows] = useState<Array<string>>([]);

  useLoadOnePageProjectsEffect();
  useLoadProjectsEffect();

  const toggleExpandedRows = (siteCode) => {
    const newExpandedRows = expandedRows.includes(siteCode) // check already expanded or not
      ? expandedRows.filter((expandedRow) => expandedRow !== siteCode) // remove siteCode from expansion
      : [...expandedRows, siteCode]; // expand this siteCode

    setExpandedRows(newExpandedRows);
  };

  const checkProjectHandler = (projectId) => (isProjectChecked) => {
    const nextSelectedProjects = cloneDeep(selectedProjects);
    if (isProjectChecked) {
      nextSelectedProjects.add(projectId);
    } else {
      nextSelectedProjects.delete(projectId);
    }
    setSelectedProjects(nextSelectedProjects);
  };

  const isAllProjectsSelected = () => {
    return selectedProjects.size === totalFilteredProjects.length;
  };

  const navToProjectDetails = (siteId) => () => {
    navigate(`/active-projects/project-details/${siteId}`, {
      state: { columnFilters },
    });
  };

  const toggleCheckAllProjectsHandler = () => {
    const allProjectIds: Array<string> = totalFilteredProjects.map(
      ({ projectId }) => projectId
    );
    const nextSelectedProjects = isAllProjectsSelected()
      ? new Set<string>()
      : new Set(allProjectIds);
    setSelectedProjects(nextSelectedProjects);
  };

  /**
   * @summary get column header cell component
   * @param {string} [columnHeader] current column header after sorted
   * **/
  const getColumnHeader = (columnHeader) => {
    if (has(PROJECTS_COLUMN_HEADER_TOOLTIP_MAP, columnHeader)) {
      return (
        <Row>
          {columnHeader}
          <Tooltip
            position="top"
            title={PROJECTS_COLUMN_HEADER_TOOLTIP_MAP[columnHeader]}
          >
            <Text>
              <Icon tokens={helpKnockoutTokens} />
            </Text>
          </Tooltip>
        </Row>
      );
    }
    return <Row>{columnHeader}</Row>;
  };

  const getProjectHeaderRow = () => {
    return (
      <TableRow>
        {isProjectSelectable && getCheckAllBox()}
        {displayColumns.map((columnHeader) => (
          <TableCell
            key={`${columnHeader}-header`}
            width={
              columnHeader.length > MAX_CONTENT_LENGTH
                ? LARGE_CELL_WIDTH
                : SMALL_CELL_WIDTH
            }
            sortColumn={columnHeader}
          >
            {getColumnHeader(columnHeader)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  /**
   * @summary get table cell component
   * @param {object} [projectData] project detail data
   * @param {string} [currentColumnField] the data field name of this table cell
   * **/
  const getTableCellWithData = (projectData, currentColumnField) => {
    if (SITE_COLUMN_NAME === currentColumnField) {
      // first column
      return (
        <TableCell
          key={`${projectData[PROJECT_ID]}-${currentColumnField}-data`}
        >
          <Row>
            {tableType === SAVE_MULTIPLE_PROJECTS_TABLE_TYPE ? (
              <Text>{projectData[currentColumnField].value}</Text>
            ) : (
              <Link
                data-testid="projectSiteText"
                type="secondary"
                onClick={navToProjectDetails(projectData[PROJECT_ID])}
              >
                {projectData[currentColumnField].value}
              </Link>
            )}
          </Row>
        </TableCell>
      );
    } else {
      const calculatedValue: any = getCalculatedValue(
        projectData,
        currentColumnField
      );
      const contentLength =
        typeof calculatedValue === "string"
          ? calculatedValue.length
          : MAX_CONTENT_LENGTH - 1;
      const contentDiv =
        contentLength >= MAX_CONTENT_LENGTH ? (
          <TableCellWithLongValue
            fullContent={formatProjectFields(
              calculatedValue,
              currentColumnField
            )}
          />
        ) : (
          <div style={{ whiteSpace: "nowrap" }}>
            {formatProjectFields(calculatedValue, currentColumnField)}
          </div>
        );
      return (
        <TableCell
          key={`${projectData[PROJECT_ID]}-${currentColumnField}-data`}
        >
          {contentDiv}
        </TableCell>
      );
    }
  };

  /**
   * @summary filter method for for home page
   * @param {object} [projectData] single project data
   * **/

  const homePageFilter = (projectData) => {
    if (tableType != HOMEPAGE_TABLE_TYPE) return true;
    return (
      projectData[CAPACITY_YEAR_COLUMN_NAME].value ==
        new Date().getFullYear() &&
      projectData[TIMELINE_COLUMN_NAME].value == "PEAK" &&
      projectData[PROGRAM_TYPE_COLUMN_NAME].value == "Capacity"
    );
  };

  const sortProjectsHandler = (event) => {
    const nextProjectsList = cloneDeep(projectTableData);
    setProjectTableData(nextProjectsList.sort(compareProjectsFn(event)));
    setSortColumn(event.sortColumn);
    setSortDirection(event.sortDirection);
  };

  const getCheckTokens = () => {
    return (
      <Icon tokens={isAllProjectsSelected() ? checkTokens : minusTokens} />
    );
  };

  const getCheckAllBox = () => {
    return (
      <TableCell width="3%">
        <Button
          type="icon"
          size="small"
          className={
            isAllProjectsSelected()
              ? `projects-toggle-all-checked-button`
              : `projects-toggle-no-checked-button`
          }
          onClick={toggleCheckAllProjectsHandler}
        >
          {getCheckTokens()}
        </Button>
      </TableCell>
    );
  };

  const getCheckboxForOneRow = (projectId) => {
    return (
      <TableCell key={`${projectId}-checkbox`} width="3%">
        <Checkbox
          checked={selectedProjects.has(projectId)}
          onChange={checkProjectHandler(projectId)}
        />
      </TableCell>
    );
  };

  // Pagination
  const totalFilteredProjects = projectTableData
    .filter(userFilterGlobalMethod(searchProjectsFilter))
    .filter(userInputFilterMethod(columnFilters))
    .filter(homePageFilter);

  const getDisplayColumns = () => {
    if (tableType === HOMEPAGE_TABLE_TYPE)
      return HOMEPAGE_TABLE_COLUMNS.filter((value) => {
        return value != PROJECT_ID;
      });
    return projectsViewType === COMPACT_VIEW
      ? FIXED_TABLE_COLUMNS
      : sortedColumns;
  };

  const compactViewProjectMapBySite: object = getCompactViewProjectMapBySite(
    totalFilteredProjects
  );
  const compactViewProjectSiteList: Array<string> = Object.keys(
    compactViewProjectMapBySite
  );
  const displayColumns = getDisplayColumns();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const numberOfPagesForDetailedView = Math.ceil(
    totalFilteredProjects.length / itemsPerPage
  );
  const numberOfPagesForCompactView = Math.ceil(
    compactViewProjectSiteList.length / itemsPerPage
  );
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;

  /**
   * @summary get detailed view table row without data processing, pagination included
   * @return JSX array of table rows
   * **/
  const getDetailedViewRows = () => {
    return totalFilteredProjects
      .slice(firstVisibleIndex, lastVisibleIndex)
      .map((projectData) => (
        <TableRow key={`${projectData[PROJECT_ID]}-detailed-row`}>
          {isProjectSelectable && getCheckboxForOneRow(projectData[PROJECT_ID])}
          {displayColumns.map((currentColumn) =>
            getTableCellWithData(projectData, currentColumn)
          )}
        </TableRow>
      ));
  };

  /**
   * @summary get compact view table row with data processing, pagination included
   * @return JSX array of table rows
   * **/
  const getCompactViewRows = () => {
    const compactViewProjectMapBySite: object = totalFilteredProjects.reduce(
      (prevProjectMapBySite, currentProjectData) => {
        const siteCode = currentProjectData[SITE_COLUMN_NAME].value;
        if (prevProjectMapBySite.hasOwnProperty(siteCode)) {
          prevProjectMapBySite[siteCode].push(currentProjectData);
        } else {
          prevProjectMapBySite[siteCode] = [currentProjectData];
        }
        return prevProjectMapBySite;
      },
      {}
    );

    const getExpanderRowCells = (currentColumn, projectsFilteredByDefault) => {
      if (!projectsFilteredByDefault.length) {
        return <TableCell key={`${currentColumn}-expander-placeholder-cell`} />; // place holder for empty row that does not have projects applies to default filter
      }

      return getTableCellWithData(projectsFilteredByDefault[0], currentColumn);
    };

    return compactViewProjectSiteList
      .slice(firstVisibleIndex, lastVisibleIndex)
      .map((siteCode) => {
        const projectListPerSite = compactViewProjectMapBySite[siteCode].sort(
          compareByCapacityYear
        );
        const projectsFilteredByDefault =
          filterProjectsByDefault(projectListPerSite);
        const siteCodeExpanderDividerRow = [
          <TableRow
            key={`${siteCode}-expander-row`}
            backgroundColor={
              expandedRows.includes(siteCode) ? colorBlue100 : "primary"
            }
          >
            <TableCell key={`${siteCode}-expander-button-cell`}>
              <Button
                type="icon"
                size="small"
                onClick={() => toggleExpandedRows(siteCode)}
              >
                {expandedRows.includes(siteCode) ? (
                  <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                ) : (
                  <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                )}
              </Button>
            </TableCell>
            <TableCell key={`${siteCode}-text-cell`}>{siteCode}</TableCell>
            {
              displayColumns
                .filter((e) => e !== SITE_COLUMN_NAME)
                .map((currentColumn) =>
                  getExpanderRowCells(currentColumn, projectsFilteredByDefault)
                ) // place holder cells
            }
          </TableRow>,
        ];
        const siteCodeExpanderRows = projectListPerSite.map(
          (projectData) =>
            expandedRows.includes(siteCode) && (
              <TableRow key={`${projectData[PROJECT_ID]}-expander-row`}>
                {isProjectSelectable &&
                  getCheckboxForOneRow(projectData[PROJECT_ID])}
                {displayColumns.map((currentColumn) =>
                  getTableCellWithData(projectData, currentColumn)
                )}
              </TableRow>
            )
        );
        return siteCodeExpanderDividerRow.concat(siteCodeExpanderRows);
      });
  };

  return (
    <Column>
      <TableText tableData={totalFilteredProjects} tableType={tableType} />
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        {isProjectsLoading && <Loader type="linear" />}
        <Table
          headerRows={1}
          showDividers
          stickyHeaderColumn
          stickyHeaderRow
          headerColumns={tableType === HOMEPAGE_TABLE_TYPE ? 1 : 2}
          spacing="small"
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={sortProjectsHandler}
          className="sticky-left-0"
        >
          {getProjectHeaderRow()}
          {projectsViewType === COMPACT_VIEW
            ? getCompactViewRows()
            : getDetailedViewRows()}
        </Table>
      </div>
      <Row widths={["18%", "10%", "6%", "fill"]}>
        <Text>{`Showing ${firstVisibleIndex + 1} to ${lastVisibleIndex} of ${
          totalFilteredProjects.length
        } entries`}</Text>
        <Select value={itemsPerPage} onChange={setItemsPerPage} size="small">
          <SelectOption value={10} label="10" />
          <SelectOption
            value={DEFAULT_ITEMS_PER_PAGE}
            label={DEFAULT_ITEMS_PER_PAGE}
          />
          <SelectOption value={30} label="30" />
        </Select>
        <Text>per page</Text>
        <div />
        <Pagination
          numberOfPages={
            projectsViewType === COMPACT_VIEW
              ? numberOfPagesForCompactView
              : numberOfPagesForDetailedView
          }
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Row>
    </Column>
  );
}
