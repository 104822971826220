import React, { useEffect, useState } from "react";
import Sheet from "@amzn/meridian/sheet";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Icon from "@amzn/meridian/icon";
import closeSmallTokens from "@amzn/meridian-tokens/base/icon/close-small";
import checkTokens from "@amzn/meridian-tokens/base/icon/check-large";
import minusTokens from "@amzn/meridian-tokens/base/icon/minus";
import ControlledExpander from "./ControlledExpander";
import { usePORContext } from "../../../store/por-context";
import {
  FIELD_GROUP_NAME_TO_TITLE,
  FIXED_TABLE_COLUMNS,
} from "../../../constants";
import cloneDeep from "lodash/cloneDeep";

export default function CustomProjectColumnsSheet({ isOpen, setIsOpen }) {
  const {
    projectFieldsReverseMap,
    sortedColumns,
    setSortedColumns,
    defaultColumns,
  } = usePORContext();
  const [userSelectedColumnsInSheet, setUserSelectedColumnsInSheet] = useState<
    Set<string>
  >(new Set([])); // user Set instead of Array to improve performance

  // only update the local selected columns in sheet when component mounts to improve browser performance
  useEffect(() => {
    setUserSelectedColumnsInSheet(new Set(sortedColumns));
  }, [sortedColumns]);

  const handleCloseSideMenu = () => {
    setIsOpen(false);
  };

  const toggleCheckColumnsHandler = (userClickedColumnName) => () => {
    const nextUserSelectedColumnsInSheet = cloneDeep(
      userSelectedColumnsInSheet
    );
    if (userSelectedColumnsInSheet.has(userClickedColumnName)) {
      nextUserSelectedColumnsInSheet.delete(userClickedColumnName);
    } else {
      nextUserSelectedColumnsInSheet.add(userClickedColumnName);
    }
    setUserSelectedColumnsInSheet(nextUserSelectedColumnsInSheet);
  };

  const resetColumns = () => {
    setUserSelectedColumnsInSheet(new Set(defaultColumns));
  };

  const saveColumns = () => {
    // Todo: Phase 3 make userMetaData post request to save user configuration
    setSortedColumns(Array.from(userSelectedColumnsInSheet));
  };

  const getAllColumnsFromGroup = (fieldGroupName) => {
    return defaultColumns.filter(
      (columnName) => projectFieldsReverseMap[columnName] === fieldGroupName
    );
  };

  const isColumnsInGroupAllSelected = (fieldGroupName) => {
    for (let oneColumn of getAllColumnsFromGroup(fieldGroupName)) {
      if (!userSelectedColumnsInSheet.has(oneColumn)) {
        return false;
      }
    }
    return true;
  };

  const getCheckTokens = (fieldGroupName) => {
    return (
      <Icon
        tokens={
          isColumnsInGroupAllSelected(fieldGroupName)
            ? checkTokens
            : minusTokens
        }
      />
    );
  };

  const checkAllColumnsInGroupHandler = (fieldGroupName) => () => {
    const isAllColumnsSelected = isColumnsInGroupAllSelected(fieldGroupName);
    const allColumnsInGroup = getAllColumnsFromGroup(fieldGroupName);
    const nextUserSelectedColumnsInSheet = cloneDeep(
      userSelectedColumnsInSheet
    );
    for (let oneColumn of allColumnsInGroup) {
      if (isAllColumnsSelected && !FIXED_TABLE_COLUMNS.includes(oneColumn)) {
        nextUserSelectedColumnsInSheet.delete(oneColumn);
      } else {
        nextUserSelectedColumnsInSheet.add(oneColumn);
      }
    }
    setUserSelectedColumnsInSheet(nextUserSelectedColumnsInSheet);
  };

  return (
    <Sheet open={isOpen} onClose={handleCloseSideMenu} type="overlay">
      <Column>
        <Row>
          <Text type="h200">Select Columns to Display in Table</Text>
          <Button onClick={handleCloseSideMenu} type="icon">
            <Icon tokens={closeSmallTokens}>Close sheet</Icon>
          </Button>
        </Row>
        <Row>
          <Button type="secondary" onClick={resetColumns}>
            Reset to Default
          </Button>
          <Button minWidth="40%" onClick={saveColumns}>
            Save
          </Button>
        </Row>
        <Column spacingInset="200">
          {Object.keys(FIELD_GROUP_NAME_TO_TITLE).map((fieldGroupName) => (
            <ControlledExpander
              key={`${fieldGroupName}-expander`}
              title={
                <Row>
                  <Button
                    type="icon"
                    className={
                      isColumnsInGroupAllSelected(fieldGroupName)
                        ? `projects-toggle-all-checked-button`
                        : `projects-toggle-no-checked-button`
                    }
                    onClick={checkAllColumnsInGroupHandler(fieldGroupName)}
                  >
                    {getCheckTokens(fieldGroupName)}
                  </Button>
                  <Text>{FIELD_GROUP_NAME_TO_TITLE[fieldGroupName]}</Text>
                </Row>
              }
              type="list"
            >
              {Object.keys(projectFieldsReverseMap)
                .filter(
                  (projectFieldName) =>
                    projectFieldsReverseMap[projectFieldName] === fieldGroupName
                )
                .map((fieldInGroupToDisplay) => (
                  <Checkbox
                    key={`${fieldInGroupToDisplay}-checkbox`}
                    disabled={FIXED_TABLE_COLUMNS.includes(
                      fieldInGroupToDisplay
                    )}
                    checked={userSelectedColumnsInSheet.has(
                      fieldInGroupToDisplay
                    )}
                    onChange={toggleCheckColumnsHandler(fieldInGroupToDisplay)}
                  >
                    {fieldInGroupToDisplay}
                  </Checkbox>
                ))}
            </ControlledExpander>
          ))}
        </Column>
      </Column>
    </Sheet>
  );
}
