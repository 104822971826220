import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import ProjectDetailsColumn from "./ProjectDetailsColumn";
import ConfirmChangeModal from "../modal/ConfirmChangeModal";
import ConfirmDeleteModal from "../modal/ConfirmDeleteModal";
import AdditionalAttributesModal from "./AdditionalAttributesModal";
import { convertActiveSiteDataToActiveSiteValues } from "./ProjectDetailsUtils";
import ToasterPORResponse from "../toaster/ToasterPORResponse";
import {
  SITE_DETAILS_GROUP,
  EQUIPMENT_GROUP,
  ATTRIBUTES_GROUP,
  SUCCESS_RESPONSE_STATUS,
  DEFAULT_TOASTER_TIMEOUT,
  ALERT_AUTHENTICATION_MESSAGE,
  NEW_SITE_ID,
} from "../../constants";
import ProjectDetailsContext from "../../store/project-details-store";
import PORContext from "../../store/por-context";
import PorApiFactory from "../../por-api/PlanOfRecordApiFactory";
import {
  ActiveSiteRequestSchema,
  AddProjectsFieldRequestSchema,
  ChangeRequestDetails,
  NewFieldMetadata,
} from "../../por-api/generated-src";
import { Logger } from "@amzn/kepler-katal-logger";
import cloneDeep from "lodash/cloneDeep";

export const navigateBackToProjectsPage = (navigate, stateData) => () => {
  navigate("/active-projects", {
    state: { copyProjectsFieldsFromRouting: stateData },
  });
};

export default function ProjectDetailsTab({ siteId }) {
  const navigate = useNavigate();
  const location: any = useLocation();
  let columnFilters = null;
  if (location.state) {
    location.state.columnFilters
      ? (columnFilters = location.state.columnFilters)
      : null;
  }
  const projectDetailsContext = useContext(ProjectDetailsContext);
  const porContext = useContext(PORContext);
  const {
    projectDetails,
    toggleFlipReloadFlag,
    toasterMessage,
    setToasterMessage,
    showAdminView,
  } = projectDetailsContext;
  const { onOpenToast, canEdit, canDelete, updateProject } = porContext;
  const [showConfirmChangeModal, setShowConfirmChangeModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showAdditionalAttributesModal, setShowAdditionalAttributesModal] =
    useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [changeFieldsMap, setChangeFieldsMap] = useState({});
  const PorApi = PorApiFactory();

  const setInitRequiredFields = () => {
    let localRequiredFields = {};
    [...SITE_DETAILS_GROUP, ...EQUIPMENT_GROUP, ...ATTRIBUTES_GROUP].map(
      (fieldGroupName) =>
        Object.keys(projectDetails).length > 0 &&
        projectDetails[fieldGroupName] &&
        projectDetails[fieldGroupName].length > 0 &&
        projectDetails[fieldGroupName].map(
          ({ fieldName, value, type, required, options }) =>
            required === true ? (localRequiredFields[fieldName] = false) : null
        )
    );
    return localRequiredFields;
  };
  const [requiredFields, setRequiredFields] = useState(() => {
    return setInitRequiredFields();
  });

  const saveProjectDetailsHandler = () => {
    setToasterMessage(
      `Project Details!`
    );
    let isEmpty = false;
    let localRequiredFields = cloneDeep(requiredFields);
    Object.keys(localRequiredFields).map((field) => {
      [...SITE_DETAILS_GROUP, ...EQUIPMENT_GROUP, ...ATTRIBUTES_GROUP].forEach(
        (fieldGroupName) => {
          projectDetails[fieldGroupName].forEach((entry) => {
            if (entry.fieldName === field && entry.value === "") {
              localRequiredFields[field] = true;
              isEmpty = true;
            }
          });
        }
      );
    });
    if (!isEmpty) setShowConfirmChangeModal(true);
    else setRequiredFields(localRequiredFields);
  };

  const handleRequiredFieldsUpdate = (update) => {
    setRequiredFields((prevState) => {
      return { ...prevState, ...update };
    });
  };

  const onConfirmCloseChangeModal = useCallback(() => {
    setShowConfirmChangeModal(false);
  }, []);

  const submitChangeHandler =
    (linksList, reason, team, additionalNotes) => () => {
      let changeRequestDetails: ChangeRequestDetails = {
        reasonForChange: reason,
        approvalLinks: linksList,
        isSuperAdmin: canDelete,
        team: team,
        additionalNotes: additionalNotes,
      };
      let projectChangeBody: ActiveSiteRequestSchema = {
        changeReason: changeRequestDetails,
        changeBody: convertActiveSiteDataToActiveSiteValues(projectDetails),
      };

      (async () => {
        let submitProjectDetailsResponse;
        try {
          submitProjectDetailsResponse =
            siteId === NEW_SITE_ID
              ? (await PorApi.createActiveSite(siteId, projectChangeBody)).data
              : (await PorApi.updateActiveSite(siteId, projectChangeBody)).data;
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
          setShowAlert(true);
          if (ex.response && ex.response.status === 403) {
            setErrorMessage(ALERT_AUTHENTICATION_MESSAGE);
            console.error("403 error");
          } else {
            setErrorMessage(ex.message);
          }
        } finally {
          setShowConfirmChangeModal(false);
          if (SUCCESS_RESPONSE_STATUS === submitProjectDetailsResponse) {
            onOpenToast();
            updateProject(projectDetails);
          }
        }
      })();
    };

  const copyProjectHandler = () => {
    navigate(`/active-projects/copy-projects/`, {
      state: { selectedProjectFromRouting: [siteId] },
    });
  };

  const navigateToSaveMultipleProjectsPage = () => {
    navigate(`/active-projects/save-multiple-projects/`, {
      state: { changeFieldsMap, siteId },
    });
  };

  const deleteProjectHandler = () => {
    setToasterMessage(
      `Deletion. Redirecting to Projects page in ${
        DEFAULT_TOASTER_TIMEOUT / 1000
      } seconds`
    );
    setShowConfirmDeleteModal(true);
  };

  const onCloseConfirmDeleteModal = useCallback(() => {
    setShowConfirmDeleteModal(false);
  }, []);

  const submitDeleteHandler = (linksList, reason, team, additionalNotes) => () => {
    let changeRequestDetails: ChangeRequestDetails = {
      reasonForChange: reason,
      approvalLinks: linksList,
      isSuperAdmin: canDelete,
      team: team,
      additionalNotes: additionalNotes,
    };
    let projectDeleteBody: ActiveSiteRequestSchema = {
      changeReason: changeRequestDetails,
      changeBody: convertActiveSiteDataToActiveSiteValues(projectDetails),
    };

    (async () => {
      let submitDeleteResponse;
      try {
        submitDeleteResponse = (
          await PorApi.deleteActiveSite(siteId, projectDeleteBody)
        ).data as any;
      } catch (ex: any) {
        console.error(ex);
        Logger.getInstance().logError(ex);
        setShowAlert(true);
        if (ex.response && ex.response.status === 403) {
          setErrorMessage(ALERT_AUTHENTICATION_MESSAGE);
          console.error("403 error");
        } else {
          setErrorMessage(ex.message);
        }
      } finally {
        if (SUCCESS_RESPONSE_STATUS === submitDeleteResponse) {
          onOpenToast();
          setTimeout(() => {
            navigate("/active-projects");
          }, DEFAULT_TOASTER_TIMEOUT);
        }
        setShowConfirmDeleteModal(false);
      }
    })();
  };

  const AddFieldHandler = () => {
    setToasterMessage("Add Field");
    setShowAdditionalAttributesModal(true);
  };

  const onCloseAdditionalAttributesModal = useCallback(() => {
    setShowAdditionalAttributesModal(false);
  }, []);

  const submitAddAttributesHandler = (
    attributeName,
    defaultValue,
    attributeType,
    categoryValue,
    optionsList,
    linksList,
    reason,
    team,
    additionalNotes
  ) => {
    let submitAddFieldResponse;
    let changeRequestDetails: ChangeRequestDetails = {
      reasonForChange: reason,
      approvalLinks: linksList,
      isSuperAdmin: canDelete,
      team: team,
      additionalNotes: additionalNotes
    };
    let addFieldMetadata: NewFieldMetadata = {
      fieldName: attributeName,
      defaultValue: defaultValue,
      type: attributeType,
      category: categoryValue,
      options: optionsList,
      approvalLinks: linksList,
    };
    let addFieldBody: AddProjectsFieldRequestSchema = {
      changeReason: changeRequestDetails,
      changeBody: addFieldMetadata,
    };
    (async () => {
      try {
        submitAddFieldResponse = (await PorApi.addProjectsField(addFieldBody))
          .data as any;
      } catch (ex: any) {
        console.error(ex);
        Logger.getInstance().logError(ex);
        setShowAlert(true);
        if (ex.response && ex.response.status === 403) {
          setErrorMessage(ALERT_AUTHENTICATION_MESSAGE);
          console.error("403 error");
        } else {
          setErrorMessage(ex.message);
        }
      } finally {
        setShowAdditionalAttributesModal(false);
        if (SUCCESS_RESPONSE_STATUS === submitAddFieldResponse) {
          onOpenToast();
          toggleFlipReloadFlag();
        }
      }
    })();
  };

  const changeFieldsHandler = (fieldName, inputValue) => {
    const nextChangeFieldsMap = cloneDeep(changeFieldsMap);
    nextChangeFieldsMap[fieldName] = inputValue;
    setChangeFieldsMap(nextChangeFieldsMap);
  };

  return (
    <Column>
      {showAlert && (
        <Alert size="medium" type="error">
          {errorMessage}
        </Alert>
      )}
      <ConfirmChangeModal
        open={showConfirmChangeModal}
        onClose={onConfirmCloseChangeModal}
        confirmHandler={submitChangeHandler}
      />
      <ConfirmDeleteModal
        open={showConfirmDeleteModal}
        onClose={onCloseConfirmDeleteModal}
        confirmHandler={submitDeleteHandler}
      />
      <AdditionalAttributesModal
        open={showAdditionalAttributesModal}
        onClose={onCloseAdditionalAttributesModal}
        addAttributeHandler={submitAddAttributesHandler}
      />
      <ToasterPORResponse message={toasterMessage} />
      <Row alignmentHorizontal="justify">
        <Row>
          {canDelete && showAdminView && (
            <Button type="secondary" onClick={copyProjectHandler}>
              Copy Project
            </Button>
          )}
          {canDelete && showAdminView && (
            <Button type="secondary" onClick={deleteProjectHandler}>
              Delete Project
            </Button>
          )}
          {canEdit && showAdminView && (
            <Button type="secondary" onClick={AddFieldHandler}>
              Add Fields
            </Button>
          )}
        </Row>
        <Row>
          {canEdit && showAdminView && (
            <Button onClick={saveProjectDetailsHandler}>Save Project</Button>
          )}
          {canEdit && showAdminView && (
            <Button
              type="tertiary"
              onClick={navigateToSaveMultipleProjectsPage}
            >
              Save to Multiple Projects
            </Button>
          )}
          <Button
            type="tertiary"
            onClick={navigateBackToProjectsPage(navigate, columnFilters)}
          >
            Return to Projects
          </Button>
        </Row>
      </Row>
      <Row alignmentHorizontal="justify" alignmentVertical="top" width="100%">
        <ProjectDetailsColumn
          fieldGroups={SITE_DETAILS_GROUP}
          requiredFields={requiredFields}
          setRequiredFields={handleRequiredFieldsUpdate}
          changeFieldsHandler={changeFieldsHandler}
        />
        <ProjectDetailsColumn
          fieldGroups={EQUIPMENT_GROUP}
          requiredFields={requiredFields}
          setRequiredFields={handleRequiredFieldsUpdate}
          changeFieldsHandler={changeFieldsHandler}
        />
        <ProjectDetailsColumn
          fieldGroups={ATTRIBUTES_GROUP}
          requiredFields={requiredFields}
          setRequiredFields={handleRequiredFieldsUpdate}
          changeFieldsHandler={changeFieldsHandler}
        />
      </Row>
    </Column>
  );
}
