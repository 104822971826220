import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Alert from '@amzn/meridian/alert';
import Button from "@amzn/meridian/button";
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Table, { TableRow, TableCell, TableActionBar } from "@amzn/meridian/table";
import Pagination from "@amzn/meridian/pagination";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select"
import searchTokens from "@amzn/meridian-tokens/base/icon/search";
import DownloadIcon from '../download/DownloadIcon';
import {
  DEFAULT_BRS_ITEMS_PER_PAGE,
  BRS_TABLE_TITLE_MAP,
  ITEMS_PER_PAGE_LIST,
  DOWNLOAD_BRS_FEATURE_NAME
} from "../../constants";
import PorApiFactory from '../../por-api/PlanOfRecordApiFactory';
import PORContext from "../../store/por-context";
import { Logger } from '@amzn/kepler-katal-logger';

export default function BRS() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const porContext = useContext(PORContext);
  const { brsTableData: brsList, setBrsTableData: setBRSList } = porContext;
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [brsNameFilter, setBrsNameFilter] = useState("");

  const PorApi = PorApiFactory();

  useEffect(() => {
    if (brsList.length === 0) {
      (async () => {
        setIsLoading(true);
        try {
          const brsTableResponse = (await PorApi.getAllBRS()).data;
          setBRSList(brsTableResponse);
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
          setShowAlert(true);
          setErrorMessage(ex.message);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, []);

  const navToBRSDetails = (site) => () => {
    navigate(`/BRS/${site}`);
  }

  const filterBRSNameMethod = (brsData) => {
    if (!brsNameFilter) {
      return true;
    }
    let brsValues = Object.values(brsData);
    let filteredBrsValues = brsValues.filter((brsValue: any) => brsValue && brsValue.toLowerCase().includes(brsNameFilter.toLowerCase()));
    return filteredBrsValues.length > 0;
  }

  // Pagination
  const totalFilteredBRS = brsList.filter(filterBRSNameMethod);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_BRS_ITEMS_PER_PAGE);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  const numberOfPages = Math.ceil(totalFilteredBRS.length / itemsPerPage);

  return (
    isLoading ? <Column spacingInset="400"><Loader /></Column> :
      <Column spacingInset="none 400" width="99%">
        {showAlert && <Alert
          size="medium"
          type="error"
        >{errorMessage}</Alert>}
        <Table
          headerRows={1}
          showDividers={true}
          layout="fixed"
        >
          <TableActionBar alignmentHorizontal="justify" widths={["16vw", "3vw"]}>
            <Input
              type="text"
              placeholder="Search..."
              value={brsNameFilter}
              onChange={setBrsNameFilter}
              prefixIconTokens={searchTokens}
            />
            <DownloadIcon featureName={DOWNLOAD_BRS_FEATURE_NAME} />
          </TableActionBar>
          <TableRow>
            {Object.keys(BRS_TABLE_TITLE_MAP).map(titleKey => <TableCell key={`${titleKey}-title`}>{BRS_TABLE_TITLE_MAP[titleKey]}</TableCell>)}
          </TableRow>
          {brsList
            .filter(filterBRSNameMethod)
            .slice(firstVisibleIndex, lastVisibleIndex)
            .map(({ site }, index) => (
              <TableRow key={`${site}-${index}-row`}>
                <TableCell key={`${site}-cell`}>
                  <Row>
                    <Button type="secondary" onClick={navToBRSDetails(site)}>View</Button>
                    <Text>{site}</Text>
                  </Row>
                </TableCell>
              </TableRow>
            ))}
        </Table>
        <Row
          width="100%"
          spacingInset="none 400"
          alignmentHorizontal="justify"
        >
          <Row>
            <Text>{`Showing ${firstVisibleIndex} to ${lastVisibleIndex} of ${totalFilteredBRS.length} entries`}</Text>
            <Select
              value={itemsPerPage}
              onChange={setItemsPerPage}
              size="small"
            >
              {ITEMS_PER_PAGE_LIST.map((perPage) => <SelectOption key={`${perPage}-selectOption`} value={perPage} label={perPage} />)}
            </Select>
            <Text>per page</Text>
          </Row>
          <Pagination
            numberOfPages={numberOfPages}
            onChange={setCurrentPage}
            currentPage={currentPage}
          />
        </Row>
      </Column>
  );
}