import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import DatePicker from "@amzn/meridian/date-picker";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import Link from "@amzn/meridian/link";
import XLSX from "xlsx";
import {
  DEFAULT_END_YEAR_LAP,
  EXPANSION,
  RETROFIT,
  LAUNCH,
  LAUNCH_LTL,
  BUILDING_CONSTRUCTION,
  LAUNCH_TYPES_LIST,
  LAUNCH_MAPPING,
  COUNTRY_US,
  COUNTRY_CANADA,
  COUNTRY_FIELD_TO_TITLE,
  REGION_LIST,
  LAUNCH_TYPES_LIST_FILTER,
  CAPACITY_YEAR_COLUMN_NAME,
  COUNTRY_COLUMN_NAME,
  LAUNCHES_COLUMN_NAME,
  REGION_COLUMN_NAME,
} from "../../constants";

import { capitalizeFirstLetter, formatDateMonth, navToProjectDetails, addGrandTotaltoRow, addCountryTotals, getYearsinData } from "./DashboardUtils";
import PorApiFactory from '../../por-api/PlanOfRecordApiFactory';
import Alert from "@amzn/meridian/alert";
import PORContext from "../../store/por-context";
import { Logger } from '@amzn/kepler-katal-logger';

export default function UpcomingProjectsRegionTable() {
  // Default date range is currentYear to currentYear + 3
  const today = new Date();
  const table_headers = [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA], "Grand Total"]
  const navigate = useNavigate();
  const endDateYear = today.getFullYear() - 1 + DEFAULT_END_YEAR_LAP;
  const startDateYear = today.getFullYear();
  const dayOfToday = today.getDate();
  const monthOfToday = today.getMonth() + 1;
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(`${startDateYear}-${formatDateMonth(monthOfToday)}-${formatDateMonth(dayOfToday)}`);
  const [endDate, setEndDate] = useState(`${endDateYear}-${formatDateMonth(monthOfToday)}-${formatDateMonth(dayOfToday)}`);
  const [shouldFetchDashboardData, setShouldFetchDashboardData] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const porContext = useContext(PORContext);
  const { dashboardObject, setDashboardObject } = porContext;

  let usYearList = new Array();
  dashboardObject.forEach(element => {
    usYearList.push(element['year'])
  });
  let usYearLap = usYearList.sort().length + 1;

  let canadaYearList = usYearList;
  let canadaYearLap = canadaYearList.length + 1;

  const PorApi = PorApiFactory();

  useEffect(() => {
    if (shouldFetchDashboardData || Object.keys(dashboardObject).length == 0) {
      (async () => {
        setIsLoading(true);
        try {
          const upcomingProjectsTableResponse = (await PorApi.getAllProjectsByYear(startDate, endDate)).data;
          setDashboardObject(upcomingProjectsTableResponse);
        } catch (ex: any) {
          console.error(ex);
          Logger.getInstance().logError(ex);
          setHasError(true);
          setErrorMessage(ex.message);
        } finally {
          setIsLoading(false);
          setShouldFetchDashboardData(false);
        }
      })();
    }
  });

  const fetchUpcomingDashboardDataFromDate = () => {
    setShouldFetchDashboardData(true);
  }

  const addGrandTotalRow = () => {
    let rowData = new Array();
    dashboardObject.forEach(project => {
      [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
        usYearList.forEach(year => {
          if (project.year === year) {
            let count = 0;
            LAUNCH_TYPES_LIST.forEach(launchType => {
              project[launchType].forEach(countryData => {
                if (countryData.Country === country && REGION_LIST.includes(countryData.REGION)) {
                  count += 1;
                }
              });
            });
            rowData.push({
              "year": project.year,
              "Country": country,
              "count": count
            })
          }
        })
      });
    });
    addCountryTotals(rowData);
    addGrandTotaltoRow(rowData, usYearList);
    const cells = table_headers.map(country => {
      return [...usYearList, "Total"].map(year => {
        return rowData.filter(data => (data.year === year && data.Country == country)).map(cell => {
          return (
            <TableCell
              key={`${country}-${year}-count`}
              backgroundColor={year === "Total" ? "secondary" : "primary"}
            >
              <Link onClick={navToProjectDetails(navigate, { [CAPACITY_YEAR_COLUMN_NAME]: cell.year === "Total" ? getYearsinData(dashboardObject) : [cell.year], [COUNTRY_COLUMN_NAME]: cell.Country === "Grand Total"? ["US", "Canada"] : cell.Country, [REGION_COLUMN_NAME]: REGION_LIST, [LAUNCHES_COLUMN_NAME]: LAUNCH_TYPES_LIST_FILTER })}>{Number(cell.count) || ""}</Link>
            </TableCell>
          )
        });
      })
    });
    return (
      <TableRow key={`grand-total-row`} backgroundColor="#DAF0FF">
        <TableCell key={`grand-total-row`}>{capitalizeFirstLetter("grand Total")}</TableCell>
        {cells}
      </TableRow>
    )

  }



  const generateRowsByRegion = (launchType, region) => {
    let rowData = new Array();
    dashboardObject.forEach(project => {
      [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
        rowData.push({
          "year": project.year,
          "Country": country,
          "count": getCountByCountry(country, launchType, project, region)
        });
      })
    });
    addCountryTotals(rowData);
    addGrandTotaltoRow(rowData, usYearList);

    const cells = table_headers.map(country => {
      return [...usYearList, "Total"].map(year => {
        return rowData.filter(data => (data.year === year && data.Country == country)).map(cell => {
          return (
            <TableCell
              key={`${launchType}-${region}-${country}-${year}-count`}
              backgroundColor={year === "Total" ? "secondary" : "primary"}
            >
              <Link onClick={navToProjectDetails(navigate, { [CAPACITY_YEAR_COLUMN_NAME]: cell.year === "Total" ? getYearsinData(dashboardObject) : [cell.year], [COUNTRY_COLUMN_NAME]: cell.Country === "Grand Total"? ["US", "Canada"] : cell.Country, [LAUNCHES_COLUMN_NAME]: [capitalizeFirstLetter(launchType)], [REGION_COLUMN_NAME]: [region] })}>{Number(cell.count) || ""}</Link>
            </TableCell>
          )
        });
      })
    });

    return (
      <TableRow key={`${launchType}-${region}-row`}>
        <TableCell key={`${launchType}-${region}-row`}>{region}</TableCell>
        {cells}
      </TableRow>
    )
  }

  const generateRowsByLaunchType = (launchType) => {
    let rowData = new Array();
    dashboardObject.forEach(project => {
      [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
        rowData.push({
          "year": project.year,
          "Country": country,
          "count": getCountByCountry(country, launchType, project)
        });
      })
    });
    addCountryTotals(rowData);
    addGrandTotaltoRow(rowData, usYearList);

    const cells = table_headers.map(country => {
      return [...usYearList, "Total"].map(year => {
        return rowData.filter(data => (data.year === year && data.Country == country)).map(cell => {
          return (
            <TableCell
              key={`${launchType}-${country}-${year}-count`}
              backgroundColor="#DAF0FF"
            >
              <Link onClick={navToProjectDetails(navigate, { [CAPACITY_YEAR_COLUMN_NAME]: cell.year === "Total" ? getYearsinData(dashboardObject) : [cell.year], [COUNTRY_COLUMN_NAME]: cell.Country === "Grand Total"? ["US", "Canada"] : cell.Country, [LAUNCHES_COLUMN_NAME]: [capitalizeFirstLetter(launchType)], [REGION_COLUMN_NAME]: REGION_LIST })}>{Number(cell.count) || ""}</Link>
            </TableCell>

          )
        });
      })
    });
    let regionRows = new Array();
    REGION_LIST.forEach(region => regionRows.push(generateRowsByRegion(launchType, region)))
    return [
      <TableRow key={`${launchType}-row`}>
        <TableCell key={`${launchType}-row`} backgroundColor="#DAF0FF">{capitalizeFirstLetter(launchType)}</TableCell>
        {cells}
      </TableRow>,
      ...regionRows

    ]
  }


  const getCountByCountry = (country, launchType, project, region = undefined) => {
    let count = 0;
    project[LAUNCH_MAPPING[launchType]].forEach(launchTypeData => {
      if (launchTypeData.Country === country && ((!region && REGION_LIST.includes(launchTypeData.REGION)) || launchTypeData.REGION === region))
        count++;
    });
    return count;
  }

  const generateExcelData = () => {
    let data = {
      "US": [],
      "Canada": []
    };
    [COUNTRY_FIELD_TO_TITLE[COUNTRY_US], COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]].forEach(country => {
      LAUNCH_TYPES_LIST.forEach(launchType => {
        let launchData = {};
        let regionData = {};
        REGION_LIST.forEach(region => {
          usYearList.forEach(year => {
            dashboardObject.forEach(project => {
              if (project.year === year) {
                let project_count = project[launchType].filter(countryData => {
                  return countryData.Country === country && REGION_LIST.includes(countryData.REGION);
                }).length;
                let project_region_count = project[launchType].filter(countryData => {
                  return countryData.Country === country && countryData.REGION === region;
                }).length;
                launchData["Launch Type/Region"] = launchType;
                launchData[year] = project_count;
                regionData["Launch Type/Region"] = region;
                regionData[year] = project_region_count;
              }
            });
          });
          data[country].push(regionData);
          regionData = {};
        });
        data[country].push({});
        data[country].push(launchData);
        data[country].push({});
      });
    });

    const workbook = XLSX.utils.book_new();
    Object.keys(data).forEach(key => {
      let worksheet = XLSX.utils.json_to_sheet(data[key], { header: ["Launch Type/Region", ...usYearList.map(String)] });
      XLSX.utils.book_append_sheet(workbook, worksheet, key);
    })
    XLSX.writeFile(workbook, `upcoming_launch_expansions_${Date.now()}.xlsx`);
  }

  return isLoading ? <Column spacingInset="400"><Loader /></Column> :
    <Column
      spacingInset="none 400"
      width="70%"
      backgroundColor="primary"
    >
      <Text
        type="h500"
      >Upcoming Launch/Expansion Projects by Region</Text>
      {hasError && <Alert size="medium" type="error">{errorMessage}</Alert>}
      <Table
        headerRows={2}
        showDividers
      >
        <TableActionBar widths={["10vw", "10vw", "fill"]}>
          <DatePicker value={startDate} locale="en-CA" onChange={setStartDate} />
          <DatePicker value={endDate} locale="en-CA" onChange={setEndDate} />
          <div />
          <Button type="icon" onClick={generateExcelData}>
            <Icon tokens={downloadLargeTokens}>Download</Icon>
          </Button>
          <Button onClick={fetchUpcomingDashboardDataFromDate} >
            Submit
          </Button>
        </TableActionBar>
        <TableRow>
          <TableCell></TableCell>
          <TableCell columnSpan={usYearLap}>{COUNTRY_FIELD_TO_TITLE[COUNTRY_US]}</TableCell>
          <TableCell columnSpan={canadaYearLap}>{COUNTRY_FIELD_TO_TITLE[COUNTRY_CANADA]}</TableCell>
          <TableCell columnSpan={canadaYearLap}>{"Grand Total"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          {[...usYearList, "Total"].map(usYear => <TableCell key={`${COUNTRY_US}-${usYear}-header`} backgroundColor={usYear === "Total" ? "secondary" : "primary"}>{usYear}</TableCell>)}
          {[...canadaYearList, "Total"].map((canadaYear) => <TableCell key={`${COUNTRY_CANADA}-${canadaYear}-header`} backgroundColor={canadaYear === "Total" ? "secondary" : "primary"}>{canadaYear}</TableCell>)}
          {[...canadaYearList, "Total"].map((canadaYear) => <TableCell key={`grand-total-${canadaYear}-header`} backgroundColor={canadaYear === "Total" ? "secondary" : "primary"}>{canadaYear}</TableCell>)}
        </TableRow>
        {generateRowsByLaunchType(LAUNCH)}
        {generateRowsByLaunchType(EXPANSION)}
        {generateRowsByLaunchType(RETROFIT)}
        {generateRowsByLaunchType(BUILDING_CONSTRUCTION)}
        {generateRowsByLaunchType(LAUNCH_LTL)}
        {addGrandTotalRow()}
      </Table>
    </Column>
}