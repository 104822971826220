import React, { useContext } from "react";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import PORContext from "../../store/por-context";
import Loader from "@amzn/meridian/loader";

export default function InfoToaster({ message, isToasterInfoLoading }) {
  const porContext = useContext(PORContext);
  const { toastsWithoutTimeout, onCloseToastWithoutTimeout } = porContext;

  return (
    <React.Fragment>
      <Toaster
        toasts={toastsWithoutTimeout}
        onCloseToast={onCloseToastWithoutTimeout}
      >
        {(toast) => (
          <Alert toast={true} onClose={toast.onClose} type="informational">
            {isToasterInfoLoading ? <Loader size="small" /> : `${message}`}
          </Alert>
        )}
      </Toaster>
    </React.Fragment>
  );
}
