/*
* Below data structure is helper to calculate Project Details formulas, it's based on BRS formula data structure.
* The problem to solve is chained formula, which is tricky, the solution below assumes:
* - Remove chaining from formulas, make it flat. i.e. "Daily Ops Rated Capacity" is a fields that need formula, and it's also a param for "Total Ops Rated Bldng Flow"
* - The sequence of resultsField array matters, the fields that serves in the middle has to come before chained result. i.e. for resultFields for "Ops Rated Hourly Flow", "Daily Ops Rated Capacity" need to be before "Total Ops Rated Bldng Flow"
*/
const DAILY_XD_OPS_RATED_CAPACITY = "Daily XD  Ops rated Capacity";  // Todo: may remove this extra space after source data is purged.

const PROJECT_DETAILS_FORMULA_MAP = {
  // Yard Total Flow
  "Yard Packages per Trailer": {
    "template": "",
    "resultFields": [
      "Yard Total Flow"
    ],
    "params": [],
  },
  "Yard Max IB Trailers": {
    "template": "",
    "resultFields": [
      "Yard Total Flow"
    ],
    "params": [],
  },
  "Yard Total Flow": {
    "template": "%s * %s",
    "resultFields": [],
    "params": ["Yard Packages per Trailer", "Yard Max IB Trailers"],
  },
  // Daily MHE Rated Capacity
  "MHE Rated Hourly Flow": {
    "template": "",
    "resultFields": [
      "Daily MHE Rated Capacity",
      "Total Max Bldng Flow",
    ],
    "params": [],
  },
  "LTP Sort Length": {
    "template": "",
    "resultFields": [
      "Daily MHE Rated Capacity",
      "Daily Non-Con Capacity",
      "Total Max Bldng Flow",
    ],
    "params": [],
  },
  "Daily MHE Rated Capacity": {
    "template": "%s * %s",
    "resultFields": ["Total Max Bldng Flow"],
    "params": ["MHE Rated Hourly Flow", "LTP Sort Length"],
  },
  // Daily Ops Rated Capacity
  "Ops Rated Hourly Flow": {
    "template": "",
    "resultFields": [
      "Daily Ops Rated Capacity",
      "Total Ops Rated Bldng Flow",
    ],
    "params": [],
  },
  "Ops Sort Length": {
    "template": "",
    "resultFields": [
      "Daily Ops Rated Capacity",
      "Total Ops Rated Bldng Flow",
    ],
    "params": [],
  },
  "Daily Ops Rated Capacity": {
    "template": "%s * %s",
    "resultFields": ["Total Ops Rated Bldng Flow"],
    "params": ["Ops Rated Hourly Flow", "Ops Sort Length"],
  },
  // Daily Non-Con Capacity
  "Non-con Hourly Flow": {
    "template": "",
    "resultFields": [
      "Daily Non-Con Capacity"
    ],
    "params": [],
  },
  "Daily Non-Con Capacity": {
    "template": "%s * %s",
    "resultFields": [],
    "params": ["Non-con Hourly Flow", "LTP Sort Length"],
  },
  // Total Max Bldng Flow
  "XD MHE Rated Capacity": {
    "template": "",
    "resultFields": [
      "Total Max Bldng Flow"
    ],
    "params": [],
  },
  "Total Max Bldng Flow": {
    "template": "%s + %s",
    "resultFields": [],
    "params": ["Daily MHE Rated Capacity", "XD MHE Rated Capacity"],
  },
  // Daily XD Ops rated Capacity
  "XD Ops Rated Hourly Flow": {
    "template": "",
    "resultFields": [
      DAILY_XD_OPS_RATED_CAPACITY,
      "Total Ops Rated Bldng Flow",
    ],
    "params": [],
  },
  "Available XD VA Hours": {
    "template": "",
    "resultFields": [
      DAILY_XD_OPS_RATED_CAPACITY,
      "Total Ops Rated Bldng Flow",
    ],
    "params": [],
  },
  [DAILY_XD_OPS_RATED_CAPACITY]: {
    "template": "%s * %s",
    "resultFields": ["Total Ops Rated Bldng Flow"],
    "params": ["XD Ops Rated Hourly Flow", "Available XD VA Hours"],
  },
  // Total Ops Rated Bldng Flow
  "Total Ops Rated Bldng Flow": {
    "template": "%s + %s",
    "resultFields": [],
    "params": ["Daily Ops Rated Capacity", DAILY_XD_OPS_RATED_CAPACITY],
  },
}

export default PROJECT_DETAILS_FORMULA_MAP;